import React, { useEffect } from 'react';
import { Flex, Button, VStack, HStack, Heading, Text } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import {
  api,
  AuthUserType,
  IProfile,
  ILoginRequest,
  ILoginResponse,
  useLoginMutation,
  useRegisterProfileMutation,
} from 'app/services/api';
import type { AppDispatch } from 'app/store';
import { toaster } from 'components/ui/toaster';
import { flattenObject } from 'utils/objectUtils';
import { useAppSelector } from 'hooks/useAppSelector';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import FormInput from 'components/forms/FormInput';
import RegisterConsentMessage from 'features/auth/RegisterConsentMessage';
import { useActions } from 'hooks/useActions';
import { useDispatch } from 'react-redux';
import InstructionsTooltip from 'features/instructions/InstructionsTooltip';
import useStudentId from 'hooks/useStudentId';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'; // Import reCAPTCHA hook

/** create interface for register form */
const RegisterSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Must be at least 3 characters')
    .max(255, 'Must be at most 255 characters'),
  email: Yup.string()
    .required('Email is required')
    .email('Please enter a valid email')
    .max(255, 'Must be at most 255 characters'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Must be at least 8 characters')
    .max(255, 'Must be at most 255 characters'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
  pin: Yup.string()
    .required('PIN is required')
    .matches(/^\d{4}$/, 'PIN must be a 4-digit number'),
});

/**
 * Primary registration view
 */
const Register: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const { registerFailure, clearFailures, loginRequest, addNotification } =
    useActions();
  const dispatch = useDispatch<AppDispatch>();
  const [registerProfile] = useRegisterProfileMutation();
  const [login] = useLoginMutation();
  const studentId = useStudentId();
  const { executeRecaptcha } = useGoogleReCaptcha(); // Use the reCAPTCHA hook
  const defaultFormValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    pin: '',
  };

  useEffect(() => {
    clearFailures();
  }, []);

  useEffect(() => {
    if (auth.errors) {
      auth.errors.map((error: string) => {
        toaster.create({
          title: 'Registration Error',
          description: error,
          type: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    }
  }, [auth.errors]);

  return (
    <>
      <Helmet>
        <title>Register - Valearnis</title>
      </Helmet>

      <VStack maxW={'38rem'} m={'1rem auto'} textAlign={'center'}>
        <HStack>
          <Heading as={'h1'} fontSize={24}>
            Create your Parent/Guardian Account
          </Heading>
          <InstructionsTooltip
            defaultIsOpen={true}
            content={
              'If you are a parent or guardian, please enter your details here. Students will have a separate account, which will be created in a later step.'
            }
            align={'bottom'}
          />
        </HStack>
        <Text>
          This account will manage student accounts and payments. Student
          accounts will be created in a later step.
        </Text>
      </VStack>
      <Formik
        initialValues={defaultFormValues}
        validationSchema={RegisterSchema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          try {
            if (!executeRecaptcha) {
              throw new Error('Recaptcha not initialized');
            }
            const recaptchaToken = await executeRecaptcha('register');

            const newGuardianUser: IProfile = {
              email: values.email.toLowerCase(),
              pin: values.pin, // Use the user-provided PIN
              app_user: {
                username: values.name,
                password: values.password,
                type: AuthUserType.Guardian,
              },
              recaptcha_token: recaptchaToken,
            };

            const result = await registerProfile(newGuardianUser).unwrap();
            const loginRecaptchaToken = await executeRecaptcha('login');

            const newLoginDetails: ILoginRequest = {
              username: values.email.toLowerCase(),
              password: values.password,
              recaptcha_token: loginRecaptchaToken,
            };
            clearFailures();
            if (result.status === 200) {
              loginRequest(values.name.toLowerCase());
              const result: ILoginResponse =
                await login(newLoginDetails).unwrap();
              if (!result || result.status !== 200) {
                throw new Error(result.message);
              } else if (result.user_type !== AuthUserType.Guardian) {
                dispatch(api.util.prefetch('getMenuItems', { studentId }, {}));
                dispatch(
                  api.util.prefetch('getUserProfile', { studentId }, {}),
                );
              }
              addNotification(
                'Registration successful! Successfully Logged in!',
              );
            }
            // TODO: Improve type checking
          } catch (err: any) {
            const errors = flattenObject(err.data);

            registerFailure(Object.values(errors));
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => (
          <Flex
            as={Form}
            alignItems={'flex-start'}
            justifyContent={'center'}
            flexFlow={'row wrap'}
            w={'100%'}
            maxW={'20.25rem'}
            m={'0 auto'}
            p={'0 2rem'}
            textAlign={'center'}
            gap={3}
          >
            <FormFieldContainer gap={7}>
              <FormInput
                label='Name'
                name='name'
                value={props.values.name}
                helperText={props.errors.name}
                required={true}
              />
              <FormInput
                label='Email'
                name='email'
                type='email'
                value={props.values.email}
                helperText={props.errors.email}
                required={true}
              />
              <FormInput
                label='Password'
                name='password'
                type='password'
                value={props.values.password}
                data-private
                helperText={props.errors.password}
                required={true}
                password_visibility={true}
              />
              <FormInput
                label='Confirm Password'
                name='confirmPassword'
                type='password'
                data-private
                value={props.values.confirmPassword}
                helperText={props.errors.confirmPassword}
                required={true}
                password_visibility={true}
              />
              <FormInput
                label='PIN'
                name='pin'
                type='password'
                value={props.values.pin}
                maxLength={4}
                helperText={props.errors.pin}
                required={true}
                password_visibility={true}
              />
            </FormFieldContainer>

            <RegisterConsentMessage />
            {/* {auth.errors &&
              auth.errors.map((error: string, index: number) => (
                <Box
                  width='100%'
                  key={`${error}-${index}`}
                  background='#b00'
                  color='white'
                  borderRadius='md'
                  p={2}
                >
                  {error}
                </Box>
              ))} */}
            <Button
              id='register-button'
              type='submit'
              disabled={
                props.values.password !== props.values.confirmPassword ||
                props.values.password === '' ||
                props.values.pin.length !== 4
              }
              _disabled={{
                opacity: '0.4',
                color: 'button.500',
                cursor: 'not-allowed',
              }}
            >
              Sign Up
            </Button>
          </Flex>
        )}
      </Formik>
    </>
  );
};

export default Register;
