import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import LogRocket from 'logrocket';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from 'app/store';
import AppRouter from 'app/AppRouter';
import { Provider } from 'components/ui/provider';
import './index.css';
import { Toaster } from 'components/ui/toaster';

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root container missing in index.html');
}
const root = ReactDOM.createRoot(container);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6b5bde52cd9e477fac3611cccbae6454@o1399136.ingest.sentry.io/6726326',
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  LogRocket.init('ushvkn/valearnis-app-prod');
}

const LoadingFallback = () => {
  return <div>Loading...</div>;
};

// Issue with loading fallback having any chakra components
const render = () => {
  root.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingFallback />} persistor={persistor}>
          {/* Change to custom theme (import) */}
          <Provider>
            <HelmetProvider>
              {process.env.NODE_ENV === 'production' &&
                process.env.REACT_APP_GA_TRACKING_ID && (
                  <Helmet>
                    {/* Google tag (gtag.js) */}
                    <script
                      async
                      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`}
                    ></script>
                    <script>
                      {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', '${process.env.REACT_APP_GA_TRACKING_ID}');
                      `}
                    </script>
                  </Helmet>
                )}
              <Toaster />
              <AppRouter />
            </HelmetProvider>
          </Provider>
        </PersistGate>
      </ReduxProvider>
    </React.StrictMode>,
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
