import { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import { ChatInput, SidebarCollapseButton } from './index';
import { ChatMessage } from './types';
import { motion, AnimatePresence } from 'framer-motion';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useLazyAskAiTeacherQuery } from 'app/services/api';
import { v4 as uuidv4 } from 'uuid';
import {
  TutorBubble,
  UserBubble,
  NewSessionIntro,
  ResponseBubble,
} from './Bubbles';
const MotionBox = motion.create(Box);

const ChatWindow = () => {
  const {
    currentSessionId,
    currentSessionMessages,
    isNewSession,
    conversation_type,
    latestTutorMessage,
  } = useAppSelector((state) => state.ai);
  const { studentId: student_id } = useAppSelector((state) => state.user);

  const [userQuestion, setUserQuestion] = useState<string>('');
  const [aiResponse, setAiResponse] = useState<string>('');
  const [tutorMessageId, setTutorMessageId] = useState<string>(uuidv4());

  useEffect(() => {
    if (latestTutorMessage) {
      setAiResponse(latestTutorMessage.message);
    } else {
      setAiResponse('');
    }
  }, [latestTutorMessage]);

  const [askAiTeacher, { isFetching }] = useLazyAskAiTeacherQuery();
  const askTeacher = async (student_message: ChatMessage) => {
    console.log('Asking teacher');
    const tutor_message = {
      type: 'tutor' as const,
      message: '',
      id: tutorMessageId,
      date: new Date().toISOString(),
    };

    const payload = {
      new_chat: isNewSession,
      session_id: currentSessionId,
      question: userQuestion,
      history_type: 'short',
      conversation_type: conversation_type,
    };

    const processStream = (chunk: string) => {
      if (chunk) {
        setAiResponse((prev) => prev + chunk);
      }
    };

    setAiResponse('');
    askAiTeacher({
      student_id: student_id,
      payload: payload,
      student_message: student_message,
      tutor_message: tutor_message,
      onChunk: (chunk: string) => processStream(chunk),
    })
      .then((res) => {
        console.log('Response:', res);
        // setAiResponse('');
        setTutorMessageId(uuidv4());
      })
      .catch((err) => {
        setTutorMessageId(uuidv4());
        console.log('Error:', err);
      });
  };

  const bottomMsgRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [showGoDown, setShowGoDown] = useState(false);

  const scrollToBottom = () => {
    if (bottomMsgRef.current) {
      bottomMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [currentSessionMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [aiResponse]);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const scrollTop = ref.current.scrollTop;
        const scrollHeight = ref.current.scrollHeight;
        const clientHeight = ref.current.clientHeight;
        if (scrollTop >= scrollHeight - clientHeight - scrollHeight * 0.3) {
          setShowGoDown(false);
        } else {
          setShowGoDown(true);
        }
      }
    };
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Box
      className='chat-window'
      w='min-content'
      position={'relative'}
      bg='#163285'
      p={'16px'}
      borderRadius={10}
      height='100%'
      display='flex'
      flexDir='column'
      flexGrow={1}
      overflow='auto'
      alignItems='center'
    >
      <SidebarCollapseButton />

      <Box
        m='auto'
        width={['90%', '90%', '90%', '90%', '70%']}
        textAlign='left'
        className='chat-messages'
        px={'6px'}
        height='100%'
        overflow='auto'
        fontSize={'14px'}
        display='flex'
        flexDir='column'
        alignItems={'center'}
        scrollbarWidth='none'
        ref={ref}
        mb={'1rem'}
      >
        {isNewSession && <NewSessionIntro />}
        {currentSessionMessages.map((message: ChatMessage) => {
          if (message.type === 'tutor') {
            return <TutorBubble key={message.id} text={message.message} />;
          } else {
            return <UserBubble key={message.id} text={message.message} />;
          }
        })}
        {(isFetching || aiResponse !== '') && (
          <ResponseBubble key={tutorMessageId} text={aiResponse} />
        )}
        <div ref={bottomMsgRef} />
      </Box>

      <AnimatePresence>
        {showGoDown && (
          <MotionBox
            position='absolute'
            bottom='90px'
            cursor='pointer'
            onClick={() => scrollToBottom()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            background='brand.100'
            color='brand.500'
            borderRadius='50%'
            _hover={{
              backdropBlur: '10px',
              background: 'brand.200',
              borderRadius: '50%',
              color: 'brand.700',
              transition: 'all 0.3s ease',
            }}
          >
            <MdOutlineKeyboardArrowDown size={30} />
          </MotionBox>
        )}
      </AnimatePresence>

      <ChatInput
        askTeacher={askTeacher}
        userQuestion={userQuestion}
        setUserQuestion={setUserQuestion}
      />
    </Box>
  );
};

export default ChatWindow;
