import React from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router';
import FactionQuizPopup from 'features/houseFaction/FactionQuizPopup';
import HouseFactionInfo from 'features/houseFaction/HouseFactionInfo';
import { useAppSelector } from 'hooks/useAppSelector';

const houses = new Set(['Heartleaf', 'Moonspark', 'Flamespire']);

const HouseFactionPage: React.FC = () => {
  const { house } = useAppSelector((state) => state.faction);
  // console.log(house);

  return (
    <Box
      bgSize={'cover'}
      display='flex'
      alignItems='center'
      justifyContent='center'
      px={['1rem', null, '4rem', '2rem', '6rem']}
    >
      {house !== null && house !== '' && houses.has(house) && (
        <HouseFactionInfo />
      )}
      {(house === '' || house === null || !houses.has(house)) && (
        <FactionQuizPopup />
      )}
      <Outlet />
    </Box>
  );
};

export default HouseFactionPage;
