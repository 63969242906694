import { Flex, Heading, GridItem } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import Portrait from './Portrait';
import SelectionButton from './SelectionButton';

interface Props {
  isLoading: boolean;
}

const PortraitSide: React.FC<Props> = () => {
  const { tempCharacterChoice } = useAppSelector((state) => state.user);

  return (
    <>
      <GridItem
        rowSpan={[1, 1, 2, 2]}
        colSpan={1}
        width={'100%'}
        height={['460px', '460px', '460px', '700px']}
        justifyContent={'center'}
        flexDir={['column', 'initial']}
      >
        <Portrait />
      </GridItem>

      <GridItem
        colSpan={1}
        flexDir={'column'}
        textAlign={['center', 'left']}
        alignItems={'baseline'}
      >
        <Heading
          fontSize={['0.9rem', '1.1rem']}
          fontWeight={'bold'}
          letterSpacing={'0.1rem'}
          m={'20px 0'}
        >
          {tempCharacterChoice.name}
        </Heading>
        <Flex
          mb={'30px'}
          h={'100%'}
          flexDir={'column'}
          textAlign={'left'}
          alignItems={'baseline'}
          pr={'10px'}
          overflowY={'auto'}
        >
          {tempCharacterChoice.description}
          <SelectionButton />
        </Flex>
      </GridItem>
    </>
  );
};

export default PortraitSide;
