import React from 'react';
import { Button } from '@chakra-ui/react';
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';

const CreditExhaustedDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <DialogRoot
      closeOnInteractOutside={true}
      open={open}
      placement={'bottom'}
      motionPreset='slide-in-bottom'
      onInteractOutside={() => setOpen(false)}
    >
      <DialogContent bgColor={'brand.500'}>
        <DialogHeader pt={4} pb={2}>
          <DialogTitle>Credits Exhausted</DialogTitle>
        </DialogHeader>
        <DialogBody py={2}>
          <p>
            You have used all of today&apos;s credits. Upgrade to get more
            credits or wait until tomorrow.
          </p>
        </DialogBody>
        <DialogFooter>
          <Button
            margin={0}
            padding={0}
            height={'40px'}
            variant='subtle'
            bgColor={'brand.700'}
            _hover={{ bg: 'brand.800' }}
          >
            UPGRADE
          </Button>
          <Button
            margin={0}
            padding={0}
            height={'40px'}
            variant='outline'
            onClick={() => setOpen(false)}
          >
            CLOSE
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default CreditExhaustedDialog;
