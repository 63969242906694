import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, Flex, Heading, Text, Box, Alert } from '@chakra-ui/react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';

import {
  AuthUserType,
  IStudent,
  useRegisterStudentMutation,
} from 'app/services/api';
import { useDispatch } from 'react-redux';
import { firstStudentRegister } from 'features/auth/authSlice';
import 'utils/yupMethods';
import PaddedContainer from 'components/containers/PaddedContainer';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import FormInput from 'components/forms/FormInput';
import FormSelect from 'components/forms/FormSelect';
import { useActions } from 'hooks/useActions';
import InstructionsTooltip from 'features/instructions/InstructionsTooltip';

/** Yup interface for RegisterHomeStudent form */
const RegisterHomeStudentSchema = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .max(150, 'Must be at most 150 characters')
        .required('Name is required'),
      username: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .max(150, 'Must be at most 150 characters')
        .required('Username is required')
        .matches(/^[a-zA-Z0-9]*$/, 'Use only letters and numbers'),
      password: Yup.string()
        .min(8, 'Must be at least 8 characters')
        .required('Password is required'),
      yearLevel: Yup.string().required('Yeal level is required'),
    }),
  )
  .uniqueProperty('username', 'Username must be unique');

interface Props {
  addStudent?: boolean;
}

interface IFormValues {
  name: string;
  username: string;
  password: string;
  yearLevel: number;
}

const SetupStudentAccount: React.FC<Props> = ({ addStudent = false }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const initialFormValues: IFormValues[] = [];
  const [registerStudent] = useRegisterStudentMutation();
  const { addNotification } = useActions();
  const navigate = useNavigate();
  const numOfStudents = Number(searchParams.get('students'));
  const populateInitialFormValues = (numOfStudents: number) => {
    for (let i = 0; i < numOfStudents; i++) {
      initialFormValues.push({
        name: '',
        username: '',
        password: '',
        yearLevel: 7,
      });
    }
  };

  if (numOfStudents && numOfStudents > 0) {
    populateInitialFormValues(numOfStudents);
  } else {
    populateInitialFormValues(1);
  }

  return (
    <PaddedContainer
      minH={'auto'}
      padding={['2rem 1.875rem', '5rem 3.625rem']}
      transition={'padding 0.5s ease-in-out'}
      text-align={'center'}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={RegisterHomeStudentSchema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          const studentsToRegister: IStudent[] = [];
          try {
            values.forEach(async (userValues) => {
              const newStudentUser: IStudent = {
                app_user: {
                  username: userValues.username,
                  name: userValues.name,
                  password: userValues.password,
                  type: AuthUserType.Student,
                },
                year: Number(userValues.yearLevel),
              };

              studentsToRegister.push(newStudentUser);
            });
            const result = await registerStudent(studentsToRegister).unwrap();

            // Check that all student registrations succeed, else throw error

            // TODO: Check all usernames are available first
            if (result.status !== 200) {
              throw new Error('Something went wrong');
            }

            dispatch(
              firstStudentRegister(result.data.is_first_student ? true : false),
            );

            navigate('/account', {
              replace: true,
              state: {
                message: `Student registration${
                  studentsToRegister.length > 1 && 's'
                } successful!`,
              },
            });
            // TODO: Improve type checking
          } catch (err: any) {
            console.log(err);
            addNotification(
              'Usernames must be between 6 and 16 characters and cannot contain symbols.',
            );
          }

          actions.setSubmitting(false);
        }}
      >
        {(props) => (
          <Flex as={Form} flexDir={'column'} justify={'center'}>
            <Heading as={'h1'} mb={10} fontSize={'1.25rem'}>
              Set up student account{numOfStudents > 1 && 's'}
            </Heading>
            <Box
              display={'flex'}
              flexDir={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Text textAlign={'left'}>
                Please enter the details below
                {numOfStudents > 1 ? ' for each ' : ' for your '}
                student account:
              </Text>
              <Flex justifyContent={'right'} maxW={'auto'} textAlign={'right'}>
                <InstructionsTooltip
                  defaultIsOpen={true}
                  content={
                    'The final step of the setup process is creating the separate accounts for each student or child that you would like to use Valearnis.'
                  }
                  footer={
                    "The username is what they use to log in to their personal Valearnis account - it can be any single-word combination of letters and numbers as long as nobody else has already used it e.g. 'CleverKate08' or 'goodstudent'. You can either fill this section in yourself, or have your student/child fill it out instead."
                  }
                  align={'bottom'}
                />
              </Flex>
            </Box>

            <FieldArray
              name='students'
              render={() => (
                <>
                  {props.values.map((student: any, index: number) => (
                    <Flex
                      key={`student.${index}`}
                      flexFlow={['column', null, 'row wrap']}
                      justifyContent={'space-between'}
                      mt={4}
                    >
                      {!addStudent && (
                        <Text
                          flex={'1 1 100%'}
                          textAlign={'left'}
                          fontWeight={600}
                        >
                          Student {index + 1}
                        </Text>
                      )}
                      <FormFieldContainer
                        display={'grid'}
                        gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
                        width={'100%'}
                        gap={7}
                        pt={2}
                      >
                        <FormInput
                          name={`${index}.name`}
                          label={'Name'}
                          value={student.name}
                          helperText={props.errors[index]?.name}
                        />
                        <FormInput
                          name={`${index}.username`}
                          label={'Username'}
                          value={student.username}
                          helperText={props.errors[index]?.username}
                        />
                        <FormInput
                          type={'password'}
                          name={`${index}.password`}
                          label={'Password'}
                          value={student.password}
                          helperText={props.errors[index]?.password}
                        />
                        <FormSelect
                          name={`${index}.yearLevel`}
                          label={'Year Level'}
                          value={props.values[index].yearLevel}
                          options={[7, 8, 9]}
                          width={'100%'}
                        />
                      </FormFieldContainer>
                    </Flex>
                  ))}
                </>
              )}
            />
            {addStudent && (
              <Alert.Root
                status='warning'
                mt={8}
                mb={4}
                textAlign='center'
                color='#333'
              >
                <Alert.Indicator />
                <Alert.Content>
                  <Text m='0'>
                    Your subscription/billing will be automatically updated.
                    {/* TODO: add subscription details link */}{' '}
                    <Button
                      asChild
                      // as={Link}
                      // variant={'link'}
                      // href='#'
                      // isExternal
                      color='#333'
                    >
                      <a href='#'>View billing info</a>
                    </Button>
                  </Text>
                </Alert.Content>
              </Alert.Root>
            )}
            <Button type='submit' mt={4} bgColor={'button.100'}>
              {addStudent ? 'Add Student' : 'Done'}
            </Button>
          </Flex>
        )}
      </Formik>
    </PaddedContainer>
  );
};

export default SetupStudentAccount;
