import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { getColorForValue } from '../utils';
import { useAppSelector } from 'hooks/useAppSelector';

interface RectangularProgressProps {
  chatCreditsUsed: number;
  maxChatCredits: number;
  width?: number;
  height?: number;
  strokeWidth?: number;
  cornerRadius?: number;
  backgroundColor?: string;
  progressColor?: string;
}

const RectangularProgress: React.FC<RectangularProgressProps> = ({
  chatCreditsUsed,
  maxChatCredits,
  width = 60,
  height = 32,
  strokeWidth = 4,
  cornerRadius = 15,
  backgroundColor = '#e6e6e6',
}) => {
  const progress = chatCreditsUsed / maxChatCredits;
  // Calculate the perimeter of the rectangle
  const perimeter = Math.round(
    2 * width + 2 * height - (8 - 2 * 3.141592) * cornerRadius,
  );
  // strokeDashoffset is determined by how much progress is left (i.e. invisible)
  const dashOffset = Math.round(perimeter * (1 - progress));

  return (
    <svg width={width + strokeWidth} height={height + strokeWidth}>
      {/* Background rectangle (full border) */}
      <rect
        x={strokeWidth / 2}
        y={strokeWidth / 2}
        width={width}
        height={height}
        rx={cornerRadius}
        ry={cornerRadius}
        fill='none'
        stroke={backgroundColor}
        strokeWidth={strokeWidth}
      />
      {/* Progress rectangle */}
      <rect
        x={strokeWidth / 2}
        y={strokeWidth / 2}
        width={width}
        height={height}
        rx={cornerRadius}
        ry={cornerRadius}
        fill='none'
        stroke={getColorForValue(chatCreditsUsed, maxChatCredits)}
        strokeWidth={strokeWidth}
        strokeDasharray={perimeter}
        strokeDashoffset={dashOffset}
        style={{ transition: 'stroke-dashoffset 0.5s ease' }}
      />
      <text
        x={(width + strokeWidth) / 2}
        y={(height + strokeWidth) / 2 + 1}
        textAnchor='middle'
        dominantBaseline='middle'
        fill={'#fff'}
        style={{ fontSize: '12px' }}
      >
        {chatCreditsUsed}/{maxChatCredits}
      </text>
    </svg>
  );
};

const SidebarFooter = () => {
  const { maxChatCredits, chatCreditsUsed } = useAppSelector(
    (state) => state.ai,
  );
  const {
    user: { subscriptionActive },
  } = useAppSelector((state) => state.auth);

  return (
    <Box
      display={'flex'}
      justifyContent={subscriptionActive ? 'flex-end' : 'space-between'}
      alignItems={'center'}
      mx={'16px'}
      pt={'16px'}
      borderTop={'1px solid'}
      borderColor={'brand.600'}
    >
      {!subscriptionActive && (
        <Tooltip
          openDelay={100}
          closeDelay={100}
          showArrow
          positioning={{ placement: 'top' }}
          content={'Upgrade to get more credits'}
        >
          <Button
            m={0}
            p={0}
            height={'36px'}
            bg={'brand.600'}
            color={'white'}
            _hover={{ bg: 'brand.700' }}
          >
            Upgrade
          </Button>
        </Tooltip>
      )}
      <Box display={'flex'} position={'relative'} alignItems={'center'}>
        <Tooltip
          openDelay={100}
          closeDelay={100}
          showArrow
          positioning={{ placement: 'right-end' }}
          content={
            chatCreditsUsed === maxChatCredits
              ? "You have used all of today's credits. Upgrade to get more credits or wait until tomorrow."
              : `You have used ${chatCreditsUsed} out of ${maxChatCredits} of today's credits`
          }
        >
          <Box
            position={'absolute'}
            zIndex={10}
            width={'48px'}
            height={'32px'}
            left={'14%'}
          />
        </Tooltip>

        <RectangularProgress
          chatCreditsUsed={chatCreditsUsed}
          maxChatCredits={maxChatCredits}
        />
      </Box>
    </Box>
  );
};

export default SidebarFooter;
