import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
  MenuItemCommand,
} from 'components/ui/menu';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaPen, FaBoxArchive } from 'react-icons/fa6';
import { RiDeleteBin6Fill } from 'react-icons/ri';

interface SessionMenuProps {
  setMenuSelectedFor: React.Dispatch<string | null>;
  session_id: string;
}

const SessionMenu = ({ setMenuSelectedFor, session_id }: SessionMenuProps) => {
  const handleOptionsSelect = (e: { open: boolean }) => {
    if (e.open === false) {
      setMenuSelectedFor(null);
    } else {
      setMenuSelectedFor(session_id);
    }
  };

  // const handleChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //   e.stopPropagation();
  //   setMenuSelectedFor(session_id);
  // };

  const handleNameChange = (session_id: string) => {
    // Add appropriate logic here.
    // console.log('Rename', session_id);
  };

  const handleArchive = (session_id: string) => {
    // Add appropriate logic here.
    // console.log('Archive', session_id);
  };

  const handleDelete = (session_id: string) => {
    // Add appropriate logic here.
    // console.log('Delete', session_id);
  };

  return (
    <MenuRoot
      positioning={{ placement: 'right-start' }}
      lazyMount={true}
      onOpenChange={(e: { open: boolean }) => handleOptionsSelect(e)}
    >
      <MenuTrigger asChild>
        <Box
          backgroundColor='transparent'
          className='icon'
          // onClick={(e) => handleChange(e)}
          aria-label='options'
          cursor='pointer'
          margin='0px'
          px='0px'
          height='22px'
          minW='22px'
          color='white'
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <BsThreeDotsVertical size={18} />
        </Box>
      </MenuTrigger>
      <MenuContent bg='brand.600'>
        <MenuItem
          value='rename'
          cursor='pointer'
          onClick={() => handleNameChange(session_id)}
        >
          <Box flex='1'>Rename</Box>
          <MenuItemCommand>
            <FaPen size={14} />
          </MenuItemCommand>
        </MenuItem>
        <MenuItem
          value='archive'
          cursor='pointer'
          onClick={() => handleArchive(session_id)}
        >
          <Box flex='1'>Archive</Box>
          <MenuItemCommand>
            <FaBoxArchive size={14} />
          </MenuItemCommand>
        </MenuItem>
        <MenuItem
          value='delete'
          cursor='pointer'
          _hover={{ bg: 'bg.error', color: 'fg.error' }}
          onClick={() => handleDelete(session_id)}
        >
          <Box flex='1'>Delete</Box>
          <MenuItemCommand>
            <RiDeleteBin6Fill size={14} />
          </MenuItemCommand>
        </MenuItem>
      </MenuContent>
    </MenuRoot>
  );
};

export default SessionMenu;
