import React from 'react';
import { Box, Button, Heading, Stack, useMediaQuery } from '@chakra-ui/react';
import { Radio, RadioGroup } from 'components/ui/radio';
import { motion } from 'framer-motion';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import {
  setQuizProgress,
  setSelectedAnswer,
  submitSelectedAnswer,
} from './FactionQuizSlice';

const HouseFactionQuiz: React.FC = () => {
  const [isShortScreen] = useMediaQuery(['(max-height: 903px)'], {
    ssr: false,
  });
  const { questions, currentQuestionIndex, selectedAnswer } = useAppSelector(
    (state) => state.faction,
  );
  const currentQuestion = questions[currentQuestionIndex];
  const options = currentQuestion.answers.map((answer: any) => answer.answer);
  const dispatch = useDispatch();

  const handleAnswerChange = (e: any) => {
    dispatch(setSelectedAnswer(e.value));
  };

  const handleAnswerSubmit = () => {
    dispatch(submitSelectedAnswer());
    if (currentQuestionIndex >= questions.length - 1) {
      dispatch(setQuizProgress(false));
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, translateY: '-20px' }}
      animate={{ opacity: 1, translateY: '0' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <Box
        position={'relative'}
        display={'flex'}
        flexDir={'column'}
        // bgColor={'brand.600'}
        // bgGradient={'linear(#5A99F7, #4560C3, #243D9D)'}
        justifyContent={'space-evenly'}
        w={['100%', '100%', '750px', '800px', '950px']}
        minH={['60vh', null, '60vh']}
        m={'3rem auto'}
        p={isShortScreen ? '0' : '1.5rem 0'}
        paddingTop={isShortScreen ? '8rem' : 'initial'}
        borderRadius={'30px'}
        alignItems={'flex-start'}
      >
        <Box m={'0 auto'} w={'85%'} textAlign={'start'}>
          <Heading
            as='h1'
            fontSize={['1.1rem', null, '1.4rem']}
            mb={'1rem'}
            fontWeight={'medium'}
          >
            Question {currentQuestionIndex + 1}/{questions.length}
          </Heading>

          <Heading
            as='h2'
            fontSize={['1rem', '1.1rem', null, '1.2rem', '1.4rem']}
            pb={'1rem'}
            borderBottom={'1px solid white'}
          >
            {currentQuestion.question}
          </Heading>
        </Box>
        <RadioGroup
          m={'0rem'}
          p={['0rem 1rem', '0rem 2rem', '0rem 3.5rem']}
          size={'md'}
          variant='outline'
          value={selectedAnswer}
          onValueChange={(e: any) => handleAnswerChange(e)}
        >
          <Stack gap={[6, 6, 8]}>
            {options.map((option: string) => {
              return (
                <Radio key={option} value={option}>
                  <Box
                    cursor={'pointer'}
                    display='flex'
                    textAlign={'left'}
                    ml={['0.5rem', '0.5rem', '1rem']}
                    fontSize={['0.9rem', '1rem', '1rem', '1.1rem']}
                  >
                    {option}
                  </Box>
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>

        <Button
          width={['100px', '120px', '150px']}
          disabled={!selectedAnswer}
          onClick={handleAnswerSubmit}
          color='brand.700'
          padding={0}
          css={{
            backgroundColor: selectedAnswer
              ? 'whiteAlpha.800'
              : 'whiteAlpha.400',
          }}
          _hover={{
            backgroundColor: selectedAnswer ? '#fff' : 'whiteAlpha.400',
          }}
        >
          Submit Answer
        </Button>
      </Box>
    </motion.div>
  );
};

export default HouseFactionQuiz;
