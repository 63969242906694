import { Box } from '@chakra-ui/react';

interface SidebarItemProps {
  session_id: string;
  currentSessionId: string;
  menuSelectedFor: string | null;
  children: React.ReactNode;
}

const SidebarItem = ({
  session_id,
  currentSessionId,
  menuSelectedFor,
  children,
}: SidebarItemProps) => {
  return (
    <Box w='100%' className='sidebar-item'>
      <Box
        px='8px'
        py='4px'
        className='sidebar-subject-header'
        height={'35px'}
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        borderRadius='10px'
        transition='all 0.15s ease-in-out'
        backgroundColor={
          currentSessionId === session_id || menuSelectedFor === session_id
            ? '#163285'
            : 'transparent'
        }
        _hover={{
          cursor: 'pointer',
          backgroundColor: '#163285',
          borderRadius: '10px',
          '& .icon': {
            opacity: 1,
            transition: 'all 0.15s ease-in-out',
          },
        }}
        css={{
          '& .icon': {
            opacity:
              currentSessionId === session_id || menuSelectedFor === session_id
                ? 1
                : 0,
            transition: 'all 0.15s ease-in-out',
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SidebarItem;
