import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import ChooseFaction from './ChooseFaction';
import HouseFactionQuiz from './HouseFactionQuiz';
import StartQuiz from './StartQuiz';
const FactionQuizPopup: React.FC = () => {
  const { quizInProgress, recommendedFaction } = useAppSelector(
    (state) => state.faction,
  );

  return (
    <React.Fragment>
      <Box
        w={['400px', '440px', '700px', '1000px', '1100px']}
        minH={'586px'}
        background='--blue100'
        borderRadius='10px'
        margin={['1rem', '1rem', '2rem']}
        display='flex'
        flexDirection='column'
        alignItems='center'
        boxShadow='5px 5px 20px black'
        paddingBottom='2rem'
        justifyContent='center'
      >
        {/* Not attempted quiz */}
        {!quizInProgress && !recommendedFaction && <StartQuiz />}

        {/* Started Quiz */}
        {quizInProgress && <HouseFactionQuiz />}

        {/* Finished quiz but not joined faction */}
        {!quizInProgress && recommendedFaction && <ChooseFaction />}
      </Box>
    </React.Fragment>
  );
};

export default FactionQuizPopup;
