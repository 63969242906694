import React, { useEffect } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { Checkbox } from 'components/ui/checkbox';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { renderMaths } from 'utils/katexUtils';

interface Props {
  options: {
    id: number | string;
    content: string;
  }[];
}

const MultiAnswerImageOptions: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();

  useEffect(() => {
    renderMaths(document.getElementsByTagName('div'));
  }, [options]);

  const handleClick = (event: any) => {
    event.preventDefault();

    const answer = Object.assign([], userAnswer);
    const newEntry = event.currentTarget.value;

    if (answer !== undefined) {
      if (answer.includes(newEntry)) {
        answer.splice(answer.indexOf(newEntry), 1);
      } else {
        answer.push(newEntry);
      }
    }

    selectAnswer(answer);
  };

  const isChecked = (id: number | string) => {
    return userAnswer !== undefined && userAnswer.includes(id);
  };

  return (
    <SimpleGrid
      columns={[1, null, 2]}
      columnGap={[null, null, '2.5rem']}
      rowGap={['1.5rem', '2.5rem', null, null, null, '4rem']}
    >
      {options.map((option) => {
        return (
          <Checkbox
            key={option.id}
            value={option.id}
            onChange={handleClick}
            isChecked={isChecked(option.id)}
            sx={{
              '.chakra-checkbox__control': { display: 'none' },
              '.chakra-checkbox__label': { margin: 0 },
            }}
            display={'block'}
            maxWidth={['100%', '70%', '100%', null, null, '80%']}
            margin={'0 auto'}
          >
            <Box
              position={'relative'}
              padding={'8px'}
              zIndex={0}
              _before={{
                content: '""',
                position: 'absolute',
                zIndex: -1,
                inset: 0,
                padding: '8px',
                borderRadius: '1.5rem',
                background: isChecked(option.id) ? '#1de877' : '#fff',
                WebkitMask:
                  ' linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                mask: `
                  linear-gradient(#fff 0 0) content-box, 
                  linear-gradient(#fff 0 0)`,
                WebkitMaskComposite: 'xor',
                maskComposite: 'exclude',
              }}
            >
              <Box
                _before={{
                  content: '"✓"',
                  position: 'absolute',
                  display: 'block',
                  top: '15px',
                  right: '15px',
                  width: '24px',
                  height: '24px',
                  fontSize: '1.25rem',
                  textAlign: 'center',
                  lineHeight: '24px',
                  borderRadius: '1.5rem',
                  color: isChecked(option.id) ? '#fff' : 'lightgrey',
                  backgroundColor: isChecked(option.id) ? '#1de877' : '#fff',
                }}
                css={{
                  '& img': { borderRadius: '0.85rem' },
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(option.content),
                }}
              />
            </Box>
          </Checkbox>
        );
      })}
    </SimpleGrid>
  );
};

export default MultiAnswerImageOptions;
