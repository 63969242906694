import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ConvoType } from './types';
import { IoIosCheckmark } from 'react-icons/io';
import { ConversationLogo } from './index';
import { useSidebar } from './SidebarContext';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';
import { useDivHeight } from 'hooks/useNavHeight';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
const MotionBox = motion.create(Box);

import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

// Precompiled regex: matches \(\), \[\]
const mathDelimiterRegex = /\\\(|\\\)|\\\[|\\\]/g;

/**
 * Processes a chat bubble string by replacing math delimiters:
 *  - Replaces "\\(" with "$$(" and "\\)" with ")$$"
 *  - Replaces "\\[" and "\\]" with "$$"
 *
 * @param {string} text - The input string to process.
 * @returns {string} - The processed string with math delimiters replaced.
 */
function processChatBubble(text: string): string {
  try {
    return text.replace(mathDelimiterRegex, (match) => {
      if (match === '\\[' || match === '\\]') {
        return '$$';
      }
      return match === '\\(' ? '$$(' : ')$$';
    });
  } catch (e) {
    console.log(e);
    return text;
  }
}

const ResponseLoader = () => {
  return (
    <Box position={'relative'} left={'18px'} top={'-2px'} className='loader'>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'end'}
        gap={1}
        height={'16px'}
        css={{
          '& .dot:nth-of-type(2)': {
            animationDelay: '0.2s',
          },
          '& .dot:nth-of-type(3)': {
            animationDelay: '0.3s',
          },
        }}
      >
        <Box
          className='dot'
          width={'6px'}
          height={'6px'}
          bg={'#29bacf'}
          borderRadius={'50%'}
          animation={'loader-wave ease-in-out 1s infinite'}
        ></Box>
        <Box
          className='dot'
          width={'6px'}
          height={'6px'}
          bg={'#29bacf'}
          borderRadius={'50%'}
          animation={'loader-wave ease-in-out 1s infinite'}
        ></Box>
        <Box
          className='dot'
          width={'6px'}
          height={'6px'}
          bg={'#29bacf'}
          borderRadius={'50%'}
          animation={'loader-wave ease-in-out 1s infinite'}
        ></Box>
      </Box>
    </Box>
  );
};

const ResponseBubble = ({ text }: { text: string }) => {
  const { TutorIcon } = useSidebar();
  const bubbleHeight = useDivHeight('tutor-response-bubble');
  const processedText = useMemo(() => processChatBubble(text), [text]);

  return (
    <MotionBox
      className='tutor-response-bubble'
      w={'100%'}
      position={'relative'}
      style={{ height: bubbleHeight }}
      animate={{ height: bubbleHeight }}
      transition={{ duration: 0.07 }}
    >
      <Box
        id='tutor-response-bubble'
        className='tutor-response-content'
        display={'flex'}
        justifyContent={'flex-start'}
        textAlign={'flex-start'}
        width={'fit-content'}
        maxWidth={'80%'}
        bg={'brand.500'}
        margin={'8px'}
        padding={'8px'}
        borderRadius={'10px'}
        maxH={'auto'}
      >
        <Box className='response-markdown'>
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
          >
            {processedText}
          </ReactMarkdown>
        </Box>
      </Box>
      <Box position={'absolute'} bottom={'-18px'}>
        {text === '' && <ResponseLoader />}
        <TutorIcon />
      </Box>
    </MotionBox>
  );
};

const TutorBubble = ({ text }: { text: string }) => {
  const { TutorIcon } = useSidebar();
  const processedText = useMemo(() => processChatBubble(text), [text]);
  return (
    <MotionBox
      className='tutor-bubble'
      w={'100%'}
      position={'relative'}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        className='tutor-content'
        display={'flex'}
        justifyContent={'flex-start'}
        textAlign={'flex-start'}
        width={'fit-content'}
        maxWidth={['90%', '80%']}
        bg={'brand.500'}
        margin={'8px'}
        padding={'8px'}
        borderRadius={'10px'}
      >
        <Box className='response-markdown'>
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
          >
            {processedText}
          </ReactMarkdown>
        </Box>
      </Box>
      <Box position={'absolute'} bottom={'-6px'}>
        <TutorIcon />
      </Box>
    </MotionBox>
  );
};

const UserBubble = ({ text }: { text: string }) => {
  const { UserIcon } = useSidebar();
  return (
    <MotionBox
      className='user-bubble'
      w={'100%'}
      display={'flex'}
      justifyContent={'flex-end'}
      position={'relative'}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        className='user-content'
        display={'flex'}
        justifyContent={'flex-end'}
        textAlign={'flex-end'}
        maxWidth={'70%'}
        bg={'brand.500'}
        margin={'8px'}
        padding={'8px'}
        borderRadius={'10px'}
      >
        <Text margin={0} fontSize={'16px'}>
          {text}
        </Text>
      </Box>
      <Box position={'absolute'} right={'-6px'} bottom={'-10px'}>
        <UserIcon />
      </Box>
    </MotionBox>
  );
};

const NewSessionIntro = () => {
  const { setSelectedConvoType } = useActions();
  const { conversation_type } = useAppSelector((state) => state.ai);
  return (
    <MotionBox
      className='new-session-bubble'
      w={'100%'}
      position={'relative'}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        className='new-session-content'
        display={'flex'}
        flexDirection={'column'}
        // justifyContent={'flex-start'}
        textAlign={'flex-start'}
        width={'fit-content'}
        maxWidth={'80%'}
        bg={'brand.500'}
        margin={'8px'}
        padding={'8px'}
        borderRadius={'10px'}
      >
        <Text fontSize={'14px'} my={'4px'}>
          {`Hello👋! I'm your AI teacher, ready to assist you on your learning
          journey. Select a subject and/or feel free to ask any questions, and let's explore the world of knowledge together.\n
          `}
        </Text>
        <Box
          display={'grid'}
          gridTemplateColumns={['repeat(4 1fr)', 'repeat(2, 2fr)']}
          gridGap={'4px'}
        >
          {['Math', 'Physics', 'English', 'Humanities'].map((subject) => {
            return (
              <Box
                key={subject}
                my={'4px'}
                cursor={'pointer'}
                bg={'#163285'}
                p={'4px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                borderRadius={'5px'}
                transition={'all 0.3s ease'}
                _hover={{
                  shadow: 'sm',
                  transition: 'all 0.3s ease',
                }}
                onClick={() =>
                  setSelectedConvoType(subject.toLowerCase() as ConvoType)
                }
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                >
                  <Text fontSize={'14px'} m={0}>
                    {subject}
                  </Text>
                  {conversation_type === subject.toLowerCase() && (
                    <IoIosCheckmark color={'#00FF00'} />
                  )}
                </Box>
                <ConversationLogo
                  convo_type={subject.toLowerCase() as ConvoType}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box position={'absolute'} bottom={'-18px'} left={'-5px'}>
        <ConversationLogo convo_type='general' width={'40px'} height={'40px'} />
      </Box>
    </MotionBox>
  );
};

export { TutorBubble, UserBubble, NewSessionIntro, ResponseBubble };
