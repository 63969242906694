import React, { useState, useCallback } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import { getSeparatorPositions, SeperatorPositions } from '../utils';
import {
  ConversationLogo,
  SessionMenu,
  SidebarItem,
  AnimatedText,
} from './index';
import { ChatSession, ConvoType } from './types';
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from 'framer-motion';
import { useGetHistoryQuery } from 'app/services/api';

const MotionBox = motion.create(Box);

const Seperator = ({ title }: { title: string }) => {
  return (
    <Box
      w='100%'
      className='sidebar-convo-separator'
      key={title}
      pt={'8px'}
      pb={'2px'}
    >
      <Text
        color='gray.400'
        fontSize='12px'
        fontWeight='bold'
        letterSpacing='0.8px'
        margin='0px'
        pl={'8px'}
        textAlign={'left'}
      >
        {title}
      </Text>
    </Box>
  );
};

const Loader = () => {
  return (
    <Box width='100%' display='flex' justifyContent='center' py={4}>
      <Box
        width='20px'
        height='20px'
        border='2px dashed #fff' /* Change color and thickness as needed */
        borderRadius='50%'
        animation='spin 3s linear infinite'
      />
    </Box>
  );
};

const SidebarContent = () => {
  const { studentId: student_id } = useAppSelector((state) => state.user);
  const { isLoading } = useGetHistoryQuery(
    { student_id },
    { refetchOnMountOrArgChange: false },
  );
  const { currentSessionId, sessions: session_history } = useAppSelector(
    (state) => state.ai,
  );

  const [menuSelectedFor, setMenuSelectedFor] = useState<string | null>(null);
  const [separatorPositions, setSeparatorPositions] = React.useState<
    SeperatorPositions[]
  >([]);

  React.useEffect(() => {
    setSeparatorPositions(getSeparatorPositions(session_history));
  }, [session_history]);

  // console.log('separatorPositions', separatorPositions);

  const [isLg] = useMediaQuery(['(max-width: 1023px)'], {
    ssr: false,
  });

  const { setSelectedSessionId, toggleSidebarState } = useActions();
  const handleSetSelectedSessionId = useCallback(
    (session_id: string) => {
      if (currentSessionId !== session_id) {
        setSelectedSessionId(session_id);
        if (isLg) {
          toggleSidebarState();
        }
      }
    },
    [currentSessionId, setSelectedSessionId, isLg, toggleSidebarState],
  );
  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll({ container: sidebarRef, layoutEffect: false });
  // Map the scroll value to a scaleX value.
  // When scrollY is 0, scaleX is 0 (the line is hidden).
  // When scrollY reaches 100 (or more), scaleX is 0.6 (the line is 60% width).
  return (
    <>
      <AnimatePresence>
        {isLoading && (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Loader />
          </MotionBox>
        )}
      </AnimatePresence>
      <MotionBox
        height='1px'
        bg='blue.500'
        transformOrigin='center' // Ensure the line expands from the center
        style={{ scaleX: useTransform(scrollY, [0, 80], [0, 0.9]) }} // Apply the animated scaleX
      />
      <AnimatePresence>
        {!isLoading && (
          <MotionBox
            ref={sidebarRef}
            className='sidebar-content-main'
            overflowY='auto'
            overflowX='hidden'
            p='0px 8px'
            my='12px'
            alignItems='start'
            display='flex'
            flex='1'
            flexDirection='column'
            gridGap='6px'
            position='relative'
          >
            {session_history.length === 0 && (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='start'
                width='100%'
              >
                <Text
                  color='gray.50'
                  fontSize='12px'
                  letterSpacing='0.8px'
                  margin='0px'
                  textAlign='center'
                  style={{ fontStyle: 'italic' }}
                >
                  No conversations yet
                </Text>
              </Box>
            )}
            {session_history.map((history: ChatSession, index: number) => {
              const separatorData = separatorPositions.find(
                (elm: SeperatorPositions) => {
                  return elm.index === index;
                },
              );
              return (
                <Box
                  className='sidebar-content'
                  key={history.session_id}
                  width='100%'
                >
                  {separatorData && <Seperator title={separatorData.title} />}
                  <SidebarItem
                    session_id={history.session_id}
                    currentSessionId={currentSessionId}
                    menuSelectedFor={menuSelectedFor}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      gap='6px'
                      alignItems={'center'}
                      onClick={() =>
                        handleSetSelectedSessionId(history.session_id)
                      }
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        width={'22px'}
                        height={'22px'}
                      >
                        <ConversationLogo
                          convo_type={history.convo_type as ConvoType}
                        />
                      </Box>
                      <AnimatedText session_name={history.session_name} />
                    </Box>
                    <SessionMenu
                      setMenuSelectedFor={setMenuSelectedFor}
                      session_id={history.session_id}
                    />
                  </SidebarItem>
                </Box>
              );
            })}
          </MotionBox>
        )}
      </AnimatePresence>
    </>
  );
};

export default SidebarContent;
