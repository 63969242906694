import { useAppSelector } from 'hooks/useAppSelector';

export const useCurrentAnswer = () => {
  const { currentQuestionId, answers } = useAppSelector(
    (state) => state.questions,
  );
  return answers.question.find(
    ({ id }: { id: string }) => id === currentQuestionId,
  )?.answered;
};
