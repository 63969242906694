import { Box, Button, Text, Stack } from '@chakra-ui/react';
import { Avatar } from 'components/ui/avatar';
import Main from 'layout/Main';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';
import { useAppSelector } from 'hooks/useAppSelector';
import PinEnterInputGroup from 'components/auth/PinEnterInputGroup';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  setStudentId,
  setStudentName,
  setStudentYear,
} from 'features/userProfile/userSlice';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from 'app/store';
import { useLocation } from 'react-router-dom';
import { api, AuthUserType } from 'app/services/api';
import { useActions } from 'hooks/useActions';

interface LocationState {
  userName: string;
  mode?: string;
  year?: number;
}

export default function ChooseUserPinEnter(): JSX.Element {
  const { studentId } = useParams<{ studentId: string }>();
  const isStudent = studentId !== 'guardian' && studentId !== undefined;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const { setSkinChoice, setUserType } = useActions();

  const user = useAppSelector((state) => state.user);
  const { userName, mode, year } = state || {};

  // console.log('current mode:', mode);

  const handleSetStudentId = () => {
    if (mode === 'select') {
      // console.log('dashboard');
      if (isStudent) {
        setUserType(AuthUserType.Student);
        dispatch(setStudentId(studentId));
        dispatch(setStudentName(userName));
        dispatch(setStudentYear(year?.toString() || ''));

        dispatch(api.util.prefetch('getMenuItems', { studentId }, {}));
        dispatch(
          api.util.prefetch(
            'getUserProfile',
            { studentId, includeDetails: true }, // includeDetails used here to fetch the users character data if any
            { force: true },
          ),
        );
        dispatch(api.util.prefetch('getPopulations', undefined, {}));
        dispatch(api.util.prefetch('getLevelMedians', undefined, {}));
        navigate('/dashboard', { replace: true });
      } else {
        setUserType(AuthUserType.Guardian);
        dispatch(setStudentId(''));
        dispatch(setStudentName(''));
        dispatch(setStudentYear(''));
        navigate('/account', { replace: true });
      }
    } else if (mode === 'edit') {
      dispatch(setStudentName(isStudent ? userName : ''));
      // console.log('edit to edit-profile');
      navigate('/account/edit-profile/' + studentId, { replace: true });
    }
  };

  function redirectToChooseUser() {
    setSkinChoice(null);
    navigate('/account/choose-user/', { replace: true });
  }

  function handleForgotPin() {
    dispatch(setStudentName(isStudent ? userName : ''));
    navigate('/account/forgot-pin/' + studentId);
  }

  return (
    <>
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
      ></Box>
      <Button
        position='fixed'
        top='0'
        right='0'
        m='16px'
        onClick={() => redirectToChooseUser()}
      >
        CANCEL
      </Button>
      <Main
        w='100vw'
        minH='100vh'
        display='flex'
        flexFlow='column'
        alignItems='center'
        justifyContent='center'
        pt='0'
        style={{ gap: '2rem' }}
      >
        <Avatar
          width='128px'
          height='128px'
          src={
            user.urls.thumbnail +
            getThumbnailFilename(
              user.skinChoice.filename.split('/').pop() as string,
            )
          }
        />

        <Stack gap={2} alignItems='center' justifyContent='center'>
          <Text fontSize='xl' fontWeight='bold' m={0}>
            {userName}
          </Text>
          <Text
            fontSize='md'
            fontWeight='bold'
            color='rgba(249, 249, 249, 0.6)'
          >
            {isStudent ? 'STUDENT' : 'GUARDIAN'}
          </Text>
        </Stack>

        <Text fontSize='3xl' as='b'>
          Enter your Profile PIN
        </Text>

        <PinEnterInputGroup
          onSuccess={() => handleSetStudentId()}
          student_id={studentId || ''}
        />

        <Text
          fontSize='sm'
          mt='10rem'
          cursor='pointer'
          _hover={{ opacity: '0.8', textDecoration: 'underline' }}
          onClick={() => {
            handleForgotPin();
          }}
        >
          Forgot your PIN?{' '}
        </Text>
      </Main>
    </>
  );
}
