const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '64em',
  xl: '80em',
  '2xl': '96em',
  '3xl': '120em',
};

export default breakpoints;
