import { Box, Heading } from '@chakra-ui/react';
import React from 'react';

import { Styles } from 'utils/customTypes';
import BlankQuestion from 'features/lessons/templates/fillInBlanks/BlankQuestion';
import DOMPurify from 'dompurify';
import { TransformedOption } from 'features/questions/questionsSlice';

interface Props {
  instruction: string;
  question: string;
  answers: TransformedOption[];
}

const seperatorStyles: Styles = {
  w: '100%',
  m: '1.5rem 0',
  borderTop: '1px solid',
  borderRadius: '10px',
  bgColor: 'white',
};

const FillBlankQuestion: React.FC<Props> = ({
  instruction,
  question,
  answers,
}) => {
  return (
    <Box>
      <Box display={'flex'} flexDirection={'row'}>
        <Heading fontSize='20px' fontWeight={'500'}>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(instruction),
            }}
          />
        </Heading>
      </Box>
      <Box {...seperatorStyles}></Box>
      <BlankQuestion question={question} options={answers} />
    </Box>
  );
};

export default FillBlankQuestion;
