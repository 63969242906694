import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  HStack,
  Image,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import BackButton from '../buttons/BackButton';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDivHeight } from 'hooks/useNavHeight';
import MobileNav from 'components/nav/MobileNav';
import DesktopNav from 'components/nav/DesktopNav';
import logo from 'app/logo.svg';
import logoSolid from 'app/logoSolid.svg';
import FixPositionBackButton from 'components/buttons/FixPositionBackButton';
export interface INavItem {
  href: string;
  label: string;
  topDivider?: boolean;
}

export interface QuestItem {
  id: string;
  label: string;
  is_complete?: boolean;
}

const useOutsideClick = (desktopOpen: boolean, desktopToggle: () => void) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const main = document.getElementById('main');
      if (main && desktopOpen) {
        // console.log('main ', main.contains(e.target as Node));
        if (main.contains(e.target as Node)) {
          desktopToggle();
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [desktopOpen, desktopToggle]);
};

interface HeaderProps {
  backgroundStyle?: React.CSSProperties;
}

const GUARDIAN_NAV_ITEMS: INavItem[] = [
  {
    href: '/',
    label: 'Choose User',
  },
  {
    href: '/account',
    label: 'My Account',
    topDivider: true,
  },
  {
    href: '/settings',
    label: 'Settings',
  },
  {
    href: 'https://valearnis.com/contact-us',
    label: 'Help and feedback',
  },
  {
    href: 'https://cdn.valearnis.com/docs/privacypolicy.pdf',
    label: 'Privacy Policy',
  },
  {
    href: '/logout',
    label: 'Log out',
  },
];

const STUDENT_NAV_ITEMS: INavItem[] = [
  {
    href: '/',
    label: 'Choose User',
  },
  {
    href: '/dashboard',
    label: 'Dashboard',
  },
  {
    href: '/curriculum',
    label: 'Lessons',
    topDivider: true,
  },
  {
    href: '/profile',
    label: 'Profile',
  },
  {
    href: '/house-faction',
    label: 'House Faction',
  },
  {
    href: '/reports',
    label: 'Reports',
  },
  {
    href: '/ai-teacher',
    label: 'AI-Teacher',
  },
  {
    href: '/settings',
    label: 'Settings',
    topDivider: true,
  },
  {
    href: 'https://valearnis.com/contact-us',
    label: 'Help and feedback',
  },
  {
    href: 'https://cdn.valearnis.com/docs/privacypolicy.pdf',
    label: 'Privacy',
  },
  {
    href: '/logout',
    label: 'Log out',
  },
];

const Header: React.FC<HeaderProps> = (props) => {
  const { open: desktopOpen, onToggle: desktopOnToggle } = useDisclosure();
  const { open: mobileOpen, onToggle: mobileOnToggle } = useDisclosure();
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);
  const baseUrl = window.location.origin;
  const height = useDivHeight('header');
  const [isSm] = useMediaQuery(['(max-width: 480px)'], {
    ssr: false,
  });

  useOutsideClick(desktopOpen, desktopOnToggle);

  const location = useLocation();
  useEffect(() => {
    const curLocation = sessionStorage.getItem('curLocation');
    if (curLocation !== null) {
      sessionStorage.setItem('prevLocation', curLocation);
    }
    if (location.pathname !== null) {
      sessionStorage.setItem('curLocation', location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    const header = document.querySelector('#header');
    if (header) {
      const handleScroll = () => {
        if (window.scrollY > height) {
          header.classList.add('expand');
          header.classList.remove('shrink');
        } else {
          header.classList.add('shrink');
          header.classList.remove('expand');
        }
      };
      window.addEventListener('scroll', handleScroll);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (isSm && desktopOpen) {
      desktopOnToggle();
    } else if (!isSm && mobileOpen) {
      mobileOnToggle();
    }
  }, [isSm]);

  return (
    <>
      <Box
        width='100%'
        height={`${height}px`}
        overflow='hidden'
        pos='fixed'
        zIndex={10}
      >
        <Box
          pos='relative'
          width='100vw'
          height='100vh'
          bgPos={'bottom'}
          bgSize={'cover'}
          bgRepeat={'no-repeat'}
          bgColor={'background.800'}
          bgGradient='to-b'
          gradientFrom={'background.100'}
          gradientTo={'background.800'}
          style={props.backgroundStyle}
        ></Box>
      </Box>
      <Box
        as={'header'}
        id='header'
        pos={'fixed'}
        zIndex={11}
        w={'100%'}
        px={auth.isAuth ? ['2rem', null, '4rem', '6rem', '8rem'] : '1rem'}
        py={'2rem'}
        color={'#fff'}
        role={'header'}
        _after={{
          content: '""',
          position: 'absolute',
          bottom: '10px',
          left: '50%',
          width: '0%',
          height: '4px',
          borderRadius: '4px',
          transition: 'none',
          backgroundColor: 'brand.500',
        }}
      >
        <Flex
          h={16}
          alignItems={'center'}
          justifyContent={auth.isAuth ? 'space-between' : 'center'}
        >
          <HStack gap={8} alignItems={'center'} zIndex={10}>
            {auth.isAuth ? (
              auth.user.type === 'GUARDIAN' ? (
                <RouterLink to='/account'>
                  <LogoImage />
                </RouterLink>
              ) : (
                <RouterLink to='/dashboard'>
                  <LogoImage />
                </RouterLink>
              )
            ) : (
              <RouterLink to='/'>
                <LogoImage />
              </RouterLink>
            )}
          </HStack>
          {auth.isAuth && (
            <>
              <Box className='dk-nav' display={['block', 'none']}>
                <MobileNav
                  navItems={
                    user.studentId !== ''
                      ? STUDENT_NAV_ITEMS
                      : GUARDIAN_NAV_ITEMS
                  }
                  userDetails={user}
                  isOpen={mobileOpen}
                  onToggle={mobileOnToggle}
                />
              </Box>
              <Box className='m-nav' display={['none', 'block']}>
                <DesktopNav
                  navItems={
                    user.studentId !== ''
                      ? STUDENT_NAV_ITEMS
                      : GUARDIAN_NAV_ITEMS
                  }
                  userDetails={user}
                  isOpen={desktopOpen}
                  onToggle={desktopOnToggle}
                />
              </Box>
            </>
          )}
        </Flex>
        {window.location.href !== baseUrl + '/dashboard' &&
          window.location.href !== baseUrl + '/account' &&
          auth.isAuth && <BackButton />}
        {!auth.isAuth && <FixPositionBackButton />}
      </Box>
    </>
  );
};

const LogoImage: React.FC = () => {
  const container = document.getElementById('paddedContainer');
  const logoSrc = container ? logo : logoSolid;
  return (
    <Image
      src={logoSrc}
      width={['172px', null, '190px', '210px']}
      htmlWidth={'172px'}
      alt='Valearnis logo'
    />
  );
};

export default Header;
