import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import PictureChoice from './PictureChoice';
import PictureChoiceInner from './PictureChoiceInner';
import { TransformedOption } from 'features/questions/questionsSlice';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';

interface Props {
  images: TransformedOption[];
}

const PictureChoiceOptions: React.FC<Props> = ({ images }) => {
  const { selectAnswer } = useActions();

  const userAnswer = useCurrentAnswer() as string[];

  const handleSelectAnswer = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option_id: number | string,
  ) => {
    console.log(option_id);
    selectAnswer([option_id]);
  };

  const optionChars = ['A', 'B', 'C', 'D'];

  return (
    <Box px={10}>
      <Grid
        templateColumns={[
          '1fr',
          '1fr',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
        ]}
        gap={6}
      >
        {optionChars.map((value, index) => {
          const isSelected =
            userAnswer && userAnswer.length > 0
              ? userAnswer[0] === images[index].id
              : false;
          const imgPath = images[index].content;
          return (
            <PictureChoice key={value} choice={value} isSelected={isSelected}>
              <PictureChoiceInner
                option={value}
                imgPath={imgPath}
                option_id={images[index].id}
                handleSelectAnswer={handleSelectAnswer}
              />
            </PictureChoice>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PictureChoiceOptions;
