// Definition: Button component theme
// variant: solid | outline | plain
// plain is a link style button.
// size: md | lg
// Not able to define custom variant names. Error with typescript
// Thats why link styles are named as plain

export default {
  base: {
    display: 'block',
    margin: '1rem auto',
    padding: '0.75rem',
    fontWeight: '700',
    letterSpacing: '.5px',
    borderRadius: '10px',
    cursor: 'pointer',
    minHeight: 'fit-content',
  },
  variants: {
    variant: {
      plain: {
        display: 'inline-block',
        verticalAlign: 'baseline',
        height: 'auto',
        minW: 'none',
        m: 0,
        px: 0,
        py: 0,
        color: '#fff',
        lineHeight: 'normal',
        _hover: {
          textDecoration: 'underline',
          _disabled: {
            textDecoration: 'none',
          },
        },
      },
      solid: {
        color: '#fff',
        bg: 'button.200',
        transition: 'background-color 0.3s',
        _hover: { bg: 'button.300', transition: 'background-color 0.3s' },
        _active: { bg: 'button.300' },
      },
      outline: {
        border: '1px solid #fff',
        bg: 'transparent',
        _hover: { color: 'brand.800', bg: '#fff' },
        _active: { color: 'brand.800', bg: '#fff' },
      },
    },
    size: {
      md: { minWidth: '6rem', padding: 3, fontSize: '1rem' },
      lg: { minWidth: '10rem', fontSize: '1rem' },
    },
  },
  defaultVariants: {
    variant: 'solid' as const,
    size: 'lg' as const,
  },
};
