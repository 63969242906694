import React from 'react';
import { Flex } from '@chakra-ui/react';
import ButtonCurriculum from 'features/curriculum/ButtonCurriculum';
import { useEffect } from 'react';
import { useUrlParts } from 'hooks/useUrlParts';
import { useButtonData } from 'hooks/useButtonData';
import { useActions } from 'hooks/useActions';
// import { Outlet } from 'react-router-dom';
// import { useLazyGetSubjectQuery, useLazyGetTopicQuery } from 'app/services/api';

const Curriculum: React.FC = () => {
  const { resetSelections } = useActions();
  const buttonData = useButtonData();
  const urlLength = useUrlParts().length;

  useEffect(() => {
    if (urlLength === 1) {
      resetSelections();
    }
  }, []);

  const isOdd = buttonData.length % 2 !== 0;
  return (
    <Flex
      display={'grid'}
      gridTemplateColumns={['1fr', null, '1fr 1fr']}
      maxW={[null, '22rem', '720px']}
      m={'0 auto'}
      px={['2rem', '0']}
      pt={['3vh', '4vh', '10vh']}
      gap={[4, 4, 8]}
      css={
        isOdd
          ? {
              '& > :last-child': {
                gridColumn: '1 / -1',
                justifySelf: 'center',
                '@media (max-width: 768px)': {
                  justifySelf: 'auto', // Change this to your desired value for larger screens
                },
              },
            }
          : {}
      }
    >
      {buttonData &&
        buttonData.map((item: any) => {
          const name = item.name.replace(/&amp;/i, '&');
          return (
            <ButtonCurriculum
              key={item.id}
              to={name}
              icon={name}
              label={name}
            />
          );
        })}
    </Flex>
  );
};

export default Curriculum;
