import { Box, Flex, Text } from '@chakra-ui/react';
// import { ArtAsset } from 'app/services/api';
import { FaChevronLeft } from 'react-icons/fa';
import { useActions } from 'hooks/useActions';
// import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
// import CharacterChoice from './CharacterChoice';

const ThemeChoices: React.FC = () => {
  // const { avatar, tempAvatar, characters } = useAppSelector(
  //   (state) => state.user,
  // );
  const { toggleSettingTheme } = useActions();
  // const targetAvatar = tempAvatar === '' ? avatar : tempAvatar;
  // const getAltThemes = () => {
  //   const themes: ArtAsset[] = [];
  //   const fileName = targetAvatar.split('/');
  //   const fileNameParts = fileName[fileName.length - 1].split('-');
  //   fileNameParts.pop();
  //   const targetStr = fileNameParts.join('-');
  //   characters.forEach((character) => {
  //     if (character.filename.includes(targetStr)) {
  //       themes.push(character);
  //     }
  //   });
  //   return themes;
  // };
  return (
    <Flex
      h={'100%'}
      flexDir={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
    >
      <Box
        position={'absolute'}
        top={'-55px'}
        left={['5px', '-15px']}
        _hover={{ cursor: 'pointer' }}
        onClick={() => toggleSettingTheme()}
      >
        <FaChevronLeft />
      </Box>
      <Flex
        w={'90%'}
        flexDir={['row']}
        justifyContent={'center'}
        flexWrap={'wrap'}
        overflowY={'auto'}
        transform={'translateX(5px)'}
      >
        {/* {getAltThemes().map((theme) => {
          return <CharacterChoice key={theme.id} filename={theme.filename} />;
        })} */}
      </Flex>
      <Flex h={'60%'} justifyContent={'center'} m={['20px 0', '0']}>
        <Text>Character Description will go here!</Text>
      </Flex>
    </Flex>
  );
};

export default ThemeChoices;
