import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Text, Box, Flex, Link } from '@chakra-ui/react';
import { Avatar } from 'components/ui/avatar';
import { useNavLogic } from 'hooks/useNavLogic';
import { UserProfile } from 'features/userProfile/userSlice';
import { INavItem } from 'components/nav/Header';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';
import { useAppSelector } from 'hooks/useAppSelector';
import { FaExclamation } from 'react-icons/fa';
import { useActions } from 'hooks/useActions';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  navItems: INavItem[];
  userDetails: UserProfile;
  isOpen: boolean;
  onToggle: () => void;
}
const MotionBox = motion.create(Box);

const DesktopNav: React.FC<Props> = ({
  navItems,
  userDetails,
  isOpen,
  onToggle,
}) => {
  const { urls, skinChoice } = useAppSelector((state) => state.user);
  const { checkIsComplete, handleClick } = useNavLogic();
  const navigate = useNavigate();
  const { setSkinChoice } = useActions();

  const handleToggle = (label: string) => {
    onToggle();
    handleClick(label);
  };

  const handleNavi = (label: string) => {
    handleClick(label);
    if (label === 'Choose User' || label === 'Log out') {
      setSkinChoice(null);
      if (label === 'Choose User') {
        navigate('/account/choose-user', { replace: true });
      }
    }
  };

  return (
    <Box>
      <Box
        onClick={() => handleToggle('profile_pic')}
        px={0}
        rounded={'full'}
        cursor={'pointer'}
        aria-label={'Toggle Menu'}
        position={'relative'}
      >
        <>
          {!checkIsComplete('profile_pic') && <FaExclamation color='gold' />}
          <Avatar
            size={'lg'}
            width={'64px'}
            height={'64px'}
            src={
              urls.thumbnail +
              getThumbnailFilename(
                skinChoice.filename.split('/').pop() as string,
              )
            }
            border={'2px solid #fff'}
          />
        </>
      </Box>

      <AnimatePresence>
        {isOpen && (
          <MotionBox
            initial={{
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: 'top right',
            }}
            animate={{
              opacity: 1,
              transform: 'scale(1)',
              transformOrigin: 'top right',
            }}
            exit={{
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: 'top right',
            }}
            transition={{
              type: 'spring',
              mass: 0.3,
              bounce: 0.25,
              duration: 0.15,
            }}
            position={'absolute'}
            right={'0'}
            zIndex={10}
            mt={'10px'}
            mr={['2rem', null, '4rem', '6rem', '8rem']}
            borderRadius={'1rem'}
            shadow={'md'}
          >
            <Box
              w={'90vw'}
              maxW={'18rem'}
              p={'2.25rem 2.25rem 1.25rem 2.25rem'}
              color={'button.200'}
              fontWeight={600}
              css={{ background: 'white' }}
              border={'none'}
              borderRadius={'1rem'}
              zIndex={20}
              shadow={'none'}
              className='desktop-navbar'
            >
              <Flex alignItems={'center'} pb={'0.625rem'}>
                <Avatar
                  size={'lg'}
                  src={
                    urls.thumbnail +
                    getThumbnailFilename(
                      skinChoice.filename.split('/').pop() as string,
                    )
                  }
                />
                <Box ml={5}>
                  <Text fontWeight={700} m={0} wordBreak={'break-all'}>
                    {userDetails.studentName === ''
                      ? userDetails.userName
                      : userDetails.studentName}
                  </Text>
                  {userDetails.name && (
                    <Text fontWeight={400} m={0}>
                      {userDetails.name}
                    </Text>
                  )}
                </Box>
              </Flex>
              {navItems.map((navItem) => {
                const isExternalUrl =
                  navItem.href.toString().indexOf('http') !== -1;
                return (
                  <Box
                    key={navItem.label}
                    color={'button.200'}
                    padding={'4px 0px'}
                    fontSize={'1rem'}
                    _hover={{ color: 'button.100', bgColor: '#fff' }}
                    _focus={{ color: 'button.100', bgColor: '#fff' }}
                    _active={{ color: 'button.100', bgColor: '#fff' }}
                    display={'flex'}
                    alignItems={'start'}
                    flexDirection={'column'}
                  >
                    {navItem.topDivider && (
                      <Box
                        my={2}
                        height={'0.5px'}
                        width={'100%'}
                        bg={'button.200'}
                      />
                    )}
                    <Flex
                      width={'100%'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      onClick={() => handleNavi(navItem.label)}
                    >
                      {isExternalUrl ? (
                        <Link
                          color={'button.200'}
                          href={navItem.href.toString()}
                          target='_blank'
                          rel='noreferrer'
                          style={{ textDecoration: 'none' }}
                          css={{
                            _focusVisible: {
                              outline: 'none',
                            },
                          }}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            p={'3px 0'}
                            _hover={{ color: 'button.100', bgColor: '#fff' }}
                          >
                            {navItem.label}
                          </Box>
                        </Link>
                      ) : (
                        <RouterLink to={navItem.href}>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            p={'3px 0'}
                          >
                            {navItem.label}
                          </Box>
                        </RouterLink>
                      )}
                      {!checkIsComplete(navItem.label) && (
                        <FaExclamation color='gold' />
                      )}
                    </Flex>
                  </Box>
                );
              })}
            </Box>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default DesktopNav;
