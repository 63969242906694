import React from 'react';
import { Box, Flex, Separator } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useGetCharactersQuery } from 'app/services/api';
import DashboardLayout from 'layout/DashboardLayout';
import AttributesSection from 'features/userProfile/AttributesSection';
import AvatarMenu from 'features/userProfile/characterSelection/AvatarMenu';
import ClassInfoBox from 'features/userProfile/ClassInfoBox';
import ProfilePicture from 'features/userProfile/ProfilePicture';
import { isDesktop } from 'utils/customHooks/windowDimensionsUtil';
import useStudentId from 'hooks/useStudentId';

const ProfileSummary: React.FC = () => {
  const { selectingAvatar } = useAppSelector((state) => state.user);
  const studentId = useStudentId();
  const { isLoading } = useGetCharactersQuery({ studentId });
  const isOnDesktop = isDesktop();
  return (
    <DashboardLayout>
      {selectingAvatar ? (
        <AvatarMenu isLoading={isLoading} />
      ) : (
        <Flex
          align={'center'}
          justify={'center'}
          pos={'relative'}
          flexDir={'column'}
          mt={['2rem', '2rem', '6rem', '8rem']}
        >
          <Flex flexDir={['column', null, null, 'row']} justify={'center'}>
            <Box width={['400px', null, null, '400px']} m={'0 auto'}>
              <ProfilePicture />
            </Box>
            {isOnDesktop && (
              <Separator
                borderColor={'white'}
                mx={'12px'}
                orientation={'vertical'}
              />
            )}
            <Flex
              w={['400px', null, null, '400px']}
              justify={['center', null, null, 'flex-start']}
              align={'center'}
              m={'0 auto'}
              flexDir={'column'}
            >
              <ClassInfoBox />
              {!isOnDesktop && (
                <Separator
                  borderColor={'white'}
                  my={'12px'}
                  width={'100%'}
                  orientation={'horizontal'}
                />
              )}
              <AttributesSection />
            </Flex>
          </Flex>
        </Flex>
      )}
    </DashboardLayout>
  );
};

export default ProfileSummary;
