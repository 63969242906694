import React from 'react';
import { Box, Flex, Heading, Grid, GridItem } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';
import AttributeBox from 'features/userProfile/AttributeBox';

interface RandomAttribute {
  attribute: string;
  value: string;
}

const AttributesSection: React.FC = () => {
  const { attributes } = useAppSelector((state) => state.user);

  const getRandomAttributes = () => {
    const randomisedAttributes: RandomAttribute[] = [];
    while (randomisedAttributes.length < 4) {
      const attribute =
        attributes[Math.floor(Math.random() * attributes.length)];
      if (!randomisedAttributes.includes(attribute)) {
        randomisedAttributes.push(attribute);
      }
    }
    return randomisedAttributes;
  };

  return (
    <Box
      // mt={[null, null, null, '30px']}
      textAlign={['left', null, null, 'center']}
      w={'100%'}
    >
      <Heading
        m={['0', null, null, '17px 0 10px']}
        p={'0'}
        fontSize={['24px', null, null, '20px']}
      >
        Attributes
      </Heading>
      <Flex
        wrap={'wrap'}
        alignItems={'center'}
        justify={'center'}
        mt={['20px', null, null, '0']}
        direction={'row'}
      >
        <Grid templateColumns='repeat(2, 1fr)' gap={2}>
          {getRandomAttributes().map((randomAttr) => {
            const { attribute, value } = randomAttr;
            return (
              <GridItem key={attribute} colSpan={1} w={'100%'}>
                <AttributeBox attribute={attribute} value={value} />
              </GridItem>
            );
          })}
        </Grid>
      </Flex>
    </Box>
  );
};

export default AttributesSection;
