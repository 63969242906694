export default {
  brand: {
    50: { value: '#d3d8eb' },
    100: { value: '#a7b1d8' },
    200: { value: '#7c8bc4' },
    300: { value: '#5064b1' },
    400: { value: '#243d9d' },
    500: { value: '#253E9E' },
    600: { value: '#1d317e' },
    700: { value: '#16255e' },
    800: { value: '#0e183f' },
    900: { value: '#070c1f' },
    950: { value: '#04060F' },
  },
  main: {
    100: { value: '#282C34' },
  },
  background: {
    100: { value: '#22B7A3' },
    800: { value: '#253E9E' },
  },
  button: {
    100: { value: '#2871D1' },
    200: { value: '#243D9D' },
    300: { value: '#0E288C' },
    blue: { value: '#0E5BC8' },
    'dark-blue': { value: '#243D9D' },
    gold: { value: '#FFD85C' },
    mustard: { value: '#F9BF13' },
    green: { value: '#008073' },
    purple: { value: '#5F30D9' },
    red: { value: '#B32C12' },
    transparent: { value: '' },
  },
};
