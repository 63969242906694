import { Heading, Flex } from '@chakra-ui/react';
import ProfileAvatar from 'components/auth/ProfileAvatar';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';
import { useAppSelector } from 'hooks/useAppSelector';
import Main from 'layout/Main';
import { useNavigate } from 'react-router';
import { Box, Button } from '@chakra-ui/react';
import ProfileCreateForm from 'components/auth/ProfileCreateForm';

export default function CreateProfile(): JSX.Element {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const redirectToChooseUser = () => {
    navigate('/account/choose-user/', { replace: true });
  };

  return (
    <>
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
      ></Box>
      <Button
        position='fixed'
        top='0'
        right='0'
        m='16px'
        onClick={() => redirectToChooseUser()}
      >
        CANCEL
      </Button>

      <Main textAlign={'center'}>
        <Box w={'100%'} mb={'0'} mt={'2rem'}>
          <Heading fontSize={'2rem'} fontWeight='semibold'>
            Create Profile
          </Heading>
          <Flex
            display='flex'
            flexDirection='column'
            justifyContent='center'
            marginTop='2rem'
          >
            <Box>
              <ProfileAvatar
                src={
                  user.urls.thumbnail +
                  getThumbnailFilename(
                    user.skinChoice.filename.split('/').pop() as string,
                  )
                }
              />
            </Box>
            <Box>
              <ProfileCreateForm />
            </Box>
          </Flex>
        </Box>
      </Main>
    </>
  );
}
