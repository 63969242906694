import React from 'react';
import BackButton from './BackButton';
const FixPositionBackButton: React.FC = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '35px',
        left: '50px',
      }}
    >
      <BackButton />
    </div>
  );
};

export default FixPositionBackButton;
