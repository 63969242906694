import React from 'react';
import chemistry from '../assets/gifs/Chemistry.gif';
import english from '../assets/gifs/English-.gif';
import geography from '../assets/gifs/Geography.gif';
import physics from '../assets/gifs/Physics.gif';
import Maths from '../assets/gifs/Geometry.gif';
import Language from '../assets/gifs/Languages.gif';
import { ProgressCircle, Box } from '@chakra-ui/react';
interface ImageWithLoaderProps {
  module: string;
}

const ImageWithLoader: React.FC<ImageWithLoaderProps> = ({ module }) => {
  // Define a mapping of module names to GIF file paths
  const gifMap: { [key: string]: string } = {
    Chemistry: chemistry,
    English: english,
    Language: Language,
    Geography: geography,
    Physics: physics,
    Geometry: Maths,
  };
  // console.log(module);
  // Getting the correct GIF or using a default one
  const gifSrc = gifMap[module];
  return (
    <>
      {gifSrc ? (
        <div style={{ textAlign: 'center' }} role='loading-animation'>
          <img
            src={gifSrc}
            alt={`${module} loading`}
            style={{ width: '200px', height: 'auto' }}
          />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          gap={4}
          role='loading-animation'
        >
          <ProgressCircle.Root value={null} size='xl'>
            <ProgressCircle.Circle>
              <ProgressCircle.Track />
              <ProgressCircle.Range />
            </ProgressCircle.Circle>
          </ProgressCircle.Root>
          <div>Loading lessons for {module}</div>
        </Box>
      )}
    </>
  );
};

export default ImageWithLoader;
