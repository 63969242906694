import React, { useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { IoIosSend } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';
import { ChatMessage } from './types';
import { useAppSelector } from 'hooks/useAppSelector';
import { CreditExhaustedDialog } from './index';

interface ChatInputProps {
  askTeacher: (student_message: ChatMessage) => void;
  userQuestion: string;
  setUserQuestion: React.Dispatch<string>;
}

const ChatInput = ({
  askTeacher,
  userQuestion,
  setUserQuestion,
}: ChatInputProps) => {
  const { maxChatCredits, chatCreditsUsed } = useAppSelector(
    (state) => state.ai,
  );
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  // const maxChatCredits = 10;
  // const chatCreditsUsed = 10;

  // const userMessageCount = 5;
  // const [isSending] = useState<boolean>(false);

  const handleSendMessage = () => {
    if (chatCreditsUsed === maxChatCredits) {
      setOpen(true);
      return;
    }
    if (!userQuestion) return;
    const student_message_id = uuidv4();
    const student_message = {
      type: 'student' as const,
      message: userQuestion,
      id: student_message_id,
      date: new Date().toISOString(),
    };
    setUserQuestion('');
    askTeacher(student_message);
  };

  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const textarea = e.currentTarget;
    (textarea.parentNode as HTMLElement).dataset.replicatedValue =
      textarea.value;
  };

  return (
    <Box
      display={'flex'}
      alignItems={'flex-end'}
      flexDir={'column'}
      width={['90%', '90%', '90%', '90%', '70%']}
      className='chat-input-box'
    >
      <CreditExhaustedDialog open={open} setOpen={setOpen} />
      <Box bgColor={'#243D9D'} padding={2} width={'100%'} borderRadius={'10px'}>
        <HStack>
          <Box className='grow-wrap' px={'8px'}>
            <label
              className='textarea-placeholder'
              htmlFor='text'
              style={{
                position: 'absolute',
                zIndex: 1,
                paddingLeft: '10px',
                alignItems: 'center',
                justifyContent: 'center',
                pointerEvents: 'none', // Ensure the label doesn't interfere with textarea interaction
                color: focused
                  ? 'rgba(255, 255, 255, 0.2)'
                  : 'rgba(255, 255, 255, 0.7)',
                fontSize: '0.9rem',
                display: userQuestion ? 'none' : 'flex',
              }}
            >
              Please type your message here.
            </label>
            <textarea
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              name='user-text-input'
              id='text'
              onInput={handleInput}
              onChange={(e) => {
                setUserQuestion(e.target.value ?? '');
              }}
              value={userQuestion}
              style={{ backgroundColor: '#243D9D', zIndex: 0 }}
            ></textarea>
          </Box>

          <Box px={'8px'}>
            <Box
              cursor='pointer'
              fontSize='2xl'
              color={chatCreditsUsed === maxChatCredits ? '#001211' : 'white'}
              onClick={handleSendMessage}
              // isDisabled={userMessageCount >= MAX_USER_MESSAGE_COUNT}
              _hover={{
                transform:
                  chatCreditsUsed === maxChatCredits ? 'none' : 'scale(1.2)',
                transition: 'transform 0.3s ease-in-out',
              }}
              transition='transform 0.3s ease-in-out'
            >
              <IoIosSend />
            </Box>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default ChatInput;
