import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  SidebarHeader,
  NewChatButton,
  MemoizedSidebarContent,
  SidebarFooter,
} from './index';
import { useAppSelector } from 'hooks/useAppSelector';
const MotionBox = motion.create(Box);

const ConversationSidebar = () => {
  const { sideBarCollapsed } = useAppSelector((state) => state.ai);
  return (
    <MotionBox
      initial={{ width: '0px', opacity: 0, x: -300 }}
      animate={{
        width: sideBarCollapsed ? '0px' : '300px',
        opacity: sideBarCollapsed ? 0 : 1,
        x: sideBarCollapsed ? -300 : 0,
      }}
      exit={{ width: '0px', opacity: 0, x: -300 }}
      transition={{
        duration: 0.5,
        ease: [0.6, -0.05, 0.01, 0.99],
      }}
      position={{ base: 'absolute', lg: 'relative' }}
      zIndex={{ base: 10, lg: 'auto' }}
      height='100%'
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        className='sidebar'
        bg='brand.500'
        py={'16px'}
        pr={'4px'}
        borderRadius={10}
        height='100%'
        overflow={'hidden'}
        style={{ width: '300px' }}
        borderRight={['1px solid', null, null, 'none']}
        shadow={['1px 0px 10px 4px #5cb5d7', null, null, 'none']}
      >
        <SidebarHeader />
        <NewChatButton />
        <MemoizedSidebarContent />
        <SidebarFooter />
      </Box>
    </MotionBox>
  );
};

export default ConversationSidebar;
