import React from 'react';
import {
  Field,
  Input,
  InputProps,
  Text,
  Box,
  defineStyle,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
const MotionBox = motion.create(Box);

export interface Props extends InputProps {
  name: string;
  label?: string;
  helperText?: string;
  required?: boolean;
  password_visibility?: boolean;
}

interface PasswordVisibilityIconProps {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordVisibilityIcon: React.FC<PasswordVisibilityIconProps> = ({
  showPassword,
  setShowPassword,
}) => {
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  return (
    <>
      <Box
        position='absolute'
        right='8px'
        top='8px'
        cursor='pointer'
        userSelect={'none'}
        onClick={togglePasswordVisibility}
        tabIndex={1}
        color={showPassword ? '#fff' : 'brand.700'}
        transition='transform 0.2s'
        _hover={{
          transform: 'scale(1.1)',
          transition: 'transform 0.2s',
        }}
      >
        {showPassword ? <IoMdEye size={24} /> : <IoMdEyeOff size={24} />}
      </Box>
    </>
  );
};

const FormInput: React.FC<Props> = ({
  name,
  type = 'text',
  label,
  helperText,
  required,
  password_visibility = false,
  ...props
}) => {
  const [field] = useField(name);

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Field.Root invalid={!!helperText} required={required}>
      <Box
        pos='relative'
        w='full'
        display={'flex'}
        flexDir={'column'}
        alignItems={'flex-start'}
      >
        <Box w={'100%'} display={'flex'} pos='relative'>
          <Input
            autoComplete='current-password'
            className='peer'
            placeholder=''
            id={props.id}
            type={password_visibility ? (showPassword ? 'text' : type) : type}
            w={'100%'}
            p={'1rem'}
            border={'none'}
            borderRadius={'5px'}
            bgColor={'rgba(255, 255, 255, 0.65)'}
            color={'#243D9D'}
            letterSpacing={'.5px'}
            _focus={{
              border: '1px solid #63b3ed',
              outlineStyle: 'none',
            }}
            role={props.role}
            {...field}
          />
          <>
            {password_visibility && (
              <PasswordVisibilityIcon
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            )}
          </>
          <Field.Label css={floatingStyles} textTransform={'uppercase'}>
            {label}
            <Field.RequiredIndicator />
          </Field.Label>
        </Box>
        <AnimatePresence>
          {!!helperText && (
            <MotionBox
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{
                type: 'spring',
                mass: 0.3,
                bounce: 0.25,
                duration: 0.15,
              }}
              // pos='absolute'
              top='100%'
              insetStart='0'
            >
              <Text fontSize={'13px'} color={'#b52727'} my={0}>
                {helperText}
              </Text>
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>
    </Field.Root>
  );
};

const floatingStyles = defineStyle({
  pos: 'absolute',
  bg: 'bg',
  px: '0.5',
  top: '-20px',
  backgroundColor: 'transparent',
  borderRadius: '5px',
  insetStart: '0',
  fontWeight: 'normal',
  pointerEvents: 'none',
  transition: 'position',
  _peerPlaceholderShown: {
    color: 'fg.subtle',
    top: '10px',
    insetStart: '3',
  },
  _peerFocusVisible: {
    color: 'whiteAlpha.800',
    top: '-20px',
    insetStart: '0',
  },
});

export default FormInput;
