import { Box } from '@chakra-ui/react';
import { RootState } from 'app/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PinShowGroup from './PinShowGroup';
import ProfileDelete from './ProfileFormButtons';
import ProfileForm from './ProfileForm';
import {
  useUpdatePinMutation,
  useDeleteStudentMutation,
} from 'app/services/api';
import { resetStudentStates } from 'features/userProfile/userSlice';
import { toaster } from 'components/ui/toaster';

type ProfileEditFormProps = {
  isStudent: boolean;
  studentId: string | undefined;
};

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({
  isStudent,
  studentId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const user = useSelector((state: RootState) => state.user);
  const [profileName, setProfileName] = useState(
    isStudent
      ? user.studentName
        ? user.studentName
        : ''
      : auth.user.name
        ? auth.user.name
        : '',
  );
  const [pin, setPin] = useState(
    user.profilePin !== '' ? user.profilePin.split('') : ['', '', '', ''],
  );
  // const toast = useToast();

  const [updatePin] = useUpdatePinMutation();
  const [deleteStudent] = useDeleteStudentMutation();

  const handleSubmit = async () => {
    // submit profile
    // console.log('submitting profile', pin, profileName);
    const pinUpdateResponse = await updatePin({
      pin: pin.join(''),
      student_id: studentId,
      name: profileName,
    }).unwrap();
    if (pinUpdateResponse.status && pinUpdateResponse.status === 200) {
      toaster.create({
        title: profileName,
        description: "We've updated your PIN for you.",
        type: 'success',
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetStudentStates());
      navigate('/account/choose-user', { replace: true });
    } else {
      toaster.create({
        title: profileName,
        description: 'Error updating pin',
        type: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteProfile = async () => {
    if (!studentId) {
      toaster.create({
        title: profileName,
        description: 'Error deleting profile',
        type: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      const deleteStudentResponse = await deleteStudent(studentId).unwrap();
      if (
        deleteStudentResponse.status &&
        deleteStudentResponse.status === 200
      ) {
        toaster.create({
          title: isStudent ? user.studentName : user.userName,
          description: "We've deleted profile for you.",
          type: 'success',
          duration: 5000,
          isClosable: true,
        });
        dispatch(resetStudentStates());
        navigate('/account/choose-user', { replace: true });
      } else {
        toaster.create({
          title: isStudent ? user.studentName : user.userName,
          description: 'Error deleting profile',
          type: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box
      width='300px'
      margin='auto'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      style={{ gap: '2rem' }}
      marginTop='2rem'
    >
      <ProfileForm
        mode={'edit'}
        isStudent={isStudent}
        name={profileName ? profileName : ''}
        setName={setProfileName}
      />
      <PinShowGroup pin={pin} setPin={setPin} />
      <ProfileDelete
        mode={'edit'}
        isStudent={isStudent}
        onSubmit={handleSubmit}
        onDelete={handleDeleteProfile}
      />
    </Box>
  );
};

export default ProfileEditForm;
