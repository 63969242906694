import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { useActions } from 'hooks/useActions';

interface Props {
  imgPath: string;
}

const ZoomButton: React.FC<Props> = ({ imgPath }) => {
  const { toggleLargeImage, setImage } = useActions();

  const handleClick = () => {
    setImage(imgPath);
    toggleLargeImage();
  };

  return (
    <Flex
      justify={'center'}
      role={'zoomButton'}
      onClick={handleClick}
      borderRadius={'10px 0'}
      bgColor={'brand.600'}
      position={'absolute'}
      top={'0'}
      id={'zoomButton'}
      w={'35px'}
      h={'35px'}
    >
      <Image
        objectFit={'contain'}
        w={'15px'}
        src={'/assets/icons/magnifier.svg'}
      />
    </Flex>
  );
};

export default ZoomButton;
