import { Button } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { useTagLessonReadyMutation } from 'app/services/api';
import React, { useState } from 'react';
import { Checkbox } from 'components/ui/checkbox';

interface Props {
  id: string;
  studentId: string;
}

const ApproveLessonButton: React.FC<Props> = ({ id, studentId }) => {
  const [checked, setChecked] = useState(false);
  const [approve] = useTagLessonReadyMutation();
  const handleClick = () => {
    approve({ id, studentId });
  };

  return (
    <Flex position={'absolute'} right={'30px'}>
      <Button disabled={!checked} onClick={handleClick}>
        Approve
      </Button>
      <Checkbox
        ml={'15px'}
        isChecked={checked}
        onChange={() => setChecked(!checked)}
      />
    </Flex>
  );
};

export default ApproveLessonButton;
