import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { useActions } from 'hooks/useActions';
// import { renderMathsSingle } from 'utils/katexUtils';
import { useKatex } from 'hooks/useKatex';

interface Props {
  // imageLink: string | null;
  // imageAltText: string | null;
  textBody: string;
}

// Only allow iframe elements from Vimeo
DOMPurify.addHook('uponSanitizeElement', (node, data) => {
  if (data.tagName === 'iframe') {
    const src = (node as Element).getAttribute('src') || '';
    if (!src.startsWith('https://player.vimeo.com')) {
      return node.parentNode?.removeChild(node);
    }
  }
});

const TheoryTemplate: React.FC<Props> = ({ textBody }) => {
  const { setSection } = useActions();
  const ele = React.createRef<HTMLDivElement>();
  const domPurifyConfig = {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  };
  useKatex(ele);
  useEffect(() => {
    // renderMathsSingle(ele);
    setSection();
  }, []);

  return (
    <Box
      ref={ele}
      overflowY={'auto'}
      pr={'16px'}
      textAlign={'justify'}
      fontWeight={'600'}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(textBody, domPurifyConfig),
      }}
    />
  );
};

export default TheoryTemplate;
