import React from 'react';
import { Box, Button, Flex, Text, Heading, Stack } from '@chakra-ui/react';
import { Radio, RadioGroup } from 'components/ui/radio';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import {
  useChangePasswordMutation,
  useSendOtpMutation,
  IResponseBase,
} from 'app/services/api';
import { useNavigate } from 'react-router-dom';
import FormInput from 'components/forms/FormInput';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import { useActions } from 'hooks/useActions';

/** Interface for login form */
const SendOTPSchema = Yup.object().shape({
  email: Yup.string().required('This field is required'),
});

const ChangePasswordSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
  password: Yup.string().required('Password is required'),
  passwordAgain: Yup.string().required('Confirm password is required'),
});

const ChangePassword: React.FC = () => {
  const { addNotification } = useActions();
  const [sendOtp] = useSendOtpMutation();
  const navigate = useNavigate();
  const accountTypes = ['Student', 'Parent/Guardian'];
  const [changePassword] = useChangePasswordMutation();
  const [isStudent, setIsStudent] = React.useState(true);
  const [userEmail, setUserEmail] = React.useState('');
  const [showSendOTP, setShowSendOTP] = React.useState(true);
  // const renderMessage = () => {
  //   if (location.state && location.state.message) {
  //     return <p>{location.state.message}</p>;
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Valearnis</title>
      </Helmet>
      <Box p={'20px 30.5px'} maxW={'30rem'} m={'10 auto'} textAlign={'center'}>
        <Heading as='h1' fontSize={['3xl', '4xl']} lineHeight={'3rem'}>
          Change Password
        </Heading>
        {isStudent ? (
          <Text>
            Please enter your username. Your parent/guardian will receive an
            email message with instructions on how to reset your password
          </Text>
        ) : (
          <Text>
            Please enter your email address. You will receive an email message
            with instructions on how to reset your password
          </Text>
        )}
      </Box>
      {showSendOTP ? (
        <Formik
          initialValues={{
            email: '',
          }}
          validateOnBlur={false}
          validationSchema={SendOTPSchema}
          onSubmit={async (values, actions) => {
            try {
              const result: IResponseBase = await sendOtp({
                username: values.email,
                type: isStudent ? 'Student' : 'Parent',
              }).unwrap();
              setShowSendOTP(!showSendOTP);
              setUserEmail(values.email);
              if (!result || result.status !== 200) {
                throw new Error(result.message);
              }
            } catch (err) {
              if (isStudent === false) {
                addNotification('Email is incorrect');
              } else {
                addNotification(
                  'Username is incorrect - Ask your guardian to reset your password for you',
                );
              }
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {(props) => (
            <Flex
              as={Form}
              alignItems={'flex-start'}
              justifyContent={'left'}
              flexFlow={'row wrap'}
              w={'100%'}
              maxW={'20.25rem'}
              m={'0 auto'}
              p={'0 2rem'}
              textAlign={'center'}
            >
              {/* {renderMessage()} */}
              <FormFieldContainer>
                <FormInput
                  label={isStudent ? 'Enter your username' : 'Enter your email'}
                  id='email'
                  name='email'
                  value={props.values.email.toLowerCase()}
                  helperText={props.errors.email}
                />
              </FormFieldContainer>
              <Text fontSize='12px'>ENTER YOUR ACCOUNT TYPE</Text>
              <RadioGroup defaultValue='Student'>
                <Stack alignItems={'left'} m={'0.5rem 3.2rem 1.5rem 0'}>
                  {accountTypes.map((item: string, index: number) => (
                    <Radio
                      key={index}
                      display={'flex'}
                      maxW={'20.25rem'}
                      role={'difficultySelect'}
                      w={'100%'}
                      h={'1.8em'}
                      p={'0'}
                      m={'0.5rem 3.2rem 0.5rem 0'}
                      value={item}
                      variant='outline'
                      size={'sm'}
                      css={{
                        '& > span:first-of-type': {
                          width: '12px',
                          height: '12px',
                          background: 'white',
                          borderWidth: '0px',
                        },
                        '& > span:first-of-type > .dot': {
                          background: 'button.mustard',
                        },
                      }}
                      _checked={{
                        '& > span:first-of-type': {
                          background: 'button.mustard',
                          borderWidth: '0px',
                        },
                      }}
                      onChange={() => {
                        setIsStudent(!isStudent);
                      }}
                    >
                      {item}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
              <Box>
                <Button
                  id='send-otp-button'
                  type='submit'
                  disabled={props.isSubmitting}
                >
                  Send OTP
                </Button>
              </Box>
            </Flex>
          )}
        </Formik>
      ) : null}
      {!showSendOTP ? (
        <Formik
          initialValues={{
            otp: '',
            password: '',
            passwordAgain: '',
          }}
          validationSchema={ChangePasswordSchema}
          onSubmit={async (values, actions) => {
            try {
              if (values.password !== values.passwordAgain) {
                addNotification('passwords do not match');
                return;
              }

              const result: IResponseBase = await changePassword({
                otp: values.otp,
                password: values.password,
                type: isStudent ? 'Student' : 'Parent',
                username: userEmail,
              }).unwrap();
              addNotification('Password successfully changed');
              navigate('/login');
              if (!result || result.status !== 200) {
                throw new Error(result.message);
              }
            } catch (err) {
              addNotification('Otp is incorrect');
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {(props) => (
            <Flex
              as={Form}
              alignItems={'flex-start'}
              justifyContent={'center'}
              flexFlow={'row wrap'}
              w={'100%'}
              maxW={'20.25rem'}
              m={'0 auto'}
              p={'0 2rem'}
              textAlign={'center'}
            >
              {/* {renderMessage()} */}
              <FormFieldContainer gap={7}>
                <FormInput
                  label='Enter Otp'
                  id='otp'
                  name='otp'
                  value={props.values.otp.toLowerCase()}
                />
                <FormInput
                  label='Enter new password'
                  id='password'
                  name='password'
                  value={props.values.password}
                  type='password'
                  data-private
                  password_visibility={true}
                />
                <FormInput
                  label='Enter new password again'
                  id='passwordAgain'
                  name='passwordAgain'
                  value={props.values.passwordAgain}
                  type='password'
                  data-private
                  password_visibility={true}
                />
              </FormFieldContainer>
              <Box>
                <Button
                  id='reset-password-button'
                  type='submit'
                  disabled={props.isSubmitting}
                >
                  Reset Password
                </Button>
              </Box>
            </Flex>
          )}
        </Formik>
      ) : null}
    </>
  );
};

export default ChangePassword;
