import React from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  Progress,
  // ProgressTrack,
  VStack,
} from '@chakra-ui/react';
// import { VictoryPie } from 'victory';
import HouseFactions from './HouseFactions';
import { useAppSelector } from 'hooks/useAppSelector';

interface Faction {
  name: string;
  color: string;
  pie_color: string;
}

const factions: Faction[] = [
  {
    name: 'Moonspark',
    color: 'blue',
    pie_color: '#5CA7FF',
  }, //#5CA7FF
  {
    name: 'Heartleaf',
    color: 'green',
    pie_color: '#88EF99',
  }, //#88EF99
  {
    name: 'Flamespire',
    color: 'red',
    pie_color: '#FF6163',
  }, //#FF6163
];
const HouseFactionInfo: React.FC = () => {
  const {
    house,
    sumLevels,
    levelMediansData,
    populationsData,
    // sumPopulations,
  } = useAppSelector((state) => {
    return state.faction;
  });

  const emblemImg = HouseFactions.find(
    (faction) => faction.name === house,
  )?.emblemImg;
  const description = HouseFactions.find(
    (faction) => faction.name === house,
  )?.description;
  const color = factions.find((faction) => faction.name === house)?.pie_color;

  return (
    <>
      <Box
        display={'flex'}
        flexDir={'column'}
        gap={4}
        justifyContent={'center'}
        py={4}
      >
        <Box
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={4}
        >
          <Image
            width={'220px'}
            height={'220px'}
            src={emblemImg}
            alt='card-emblem'
            border={'4px'}
            borderColor={'#022FA1'}
            borderRadius={'50%'}
          />

          <Heading
            py={4}
            fontSize={['1.8rem']}
            fontWeight={'bolder'}
          >{`${house} Faction`}</Heading>
        </Box>

        <Box
          display={'grid'}
          gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr', '1fr 2fr']}
          gap={4}
          bg={'rgba(36, 97, 115, 0.9)'}
          p={4}
          borderRadius={'2rem'}
        >
          <Box borderRadius={'2rem'} px={4}>
            <Text
              px={4}
              fontSize={['1.2rem', '1.3rem']}
              fontWeight={'bold'}
              style={{ lineHeight: '1.2' }}
              textAlign={['center', 'start']}
            >
              {description !== undefined && (
                <>
                  <span style={{ color: color }}>
                    {description.split('. ')[0]}.
                  </span>
                  <br />
                  <br />
                </>
              )}
            </Text>
            <Text
              px={4}
              my={0}
              fontSize={['1.1rem', '1.2rem']}
              fontWeight={'bold'}
              style={{ lineHeight: '1.2' }}
              textAlign={['center', 'start']}
            >
              {description !== undefined &&
                description.split('. ').slice(1).join('. ')}
            </Text>
          </Box>
          <Box display={'flex'} flexDir={'column'} px={4} pb={4} gap={4}>
            <Box>
              <Heading
                fontSize={['1.4rem', '2rem']}
                textAlign={['center', 'start']}
                p={4}
              >
                House Experience Levels
              </Heading>
              <VStack gap={6} align='flex-start' px={4}>
                {factions.map((faction) => (
                  <Box key={faction.name} w='100%'>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Box>
                        <Text fontWeight='bold' fontSize={'1.2rem'}>
                          {faction.name}
                        </Text>
                      </Box>
                      <Box>
                        {populationsData[faction.name] === undefined ? (
                          <Text fontSize={'1.2rem'}>0 members</Text>
                        ) : populationsData[faction.name] === 1 ? (
                          <Text fontSize={'1.2rem'}>1 member</Text>
                        ) : (
                          <Text fontSize={'1.2rem'}>
                            {populationsData[faction.name]} members
                          </Text>
                        )}
                      </Box>
                    </Box>
                    <Progress.Root
                      colorPalette={faction.color}
                      value={
                        (levelMediansData[faction.name] / sumLevels) * 100 || 0
                      }
                      size='md'
                      borderRadius='md'
                    >
                      <Progress.Track>
                        <Progress.Range />
                      </Progress.Track>
                    </Progress.Root>
                  </Box>
                ))}
              </VStack>
            </Box>

            {/* <Box p={4}>
              <Heading
                fontSize={'2rem'}
                textAlign={'left'}
                p={4}
                style={{ overflowWrap: 'break-word' }}
              >
                Population
              </Heading>
              <Box w={'150px'} h={'150px'}>
                <VictoryPie
                  data={factions.map((faction) => ({
                    x: faction.name,
                    y: (populationsData[faction.name] / sumPopulations) * 100,
                  }))}
                  colorScale={factions.map((faction) => faction.pie_color)}
                  labels={() => ''}
                  radius={140}
                  innerRadius={70}
                />
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HouseFactionInfo;
