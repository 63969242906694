import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, Flex } from '@chakra-ui/react';

import { addNotification } from 'app/notificationSlice';
import ButtonCurriculum from 'features/curriculum/ButtonCurriculum';
import { RootState } from 'app/store';
import CopyToolTip from 'features/instructions/CopyToolTip';

const ParentDashboard: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [referralCode, setReferralCode] = useState('');
  const [showReferralCode, setShowReferralCode] = useState(false);
  const token = useSelector((state: RootState) => state.auth.user.token);

  useEffect(() => {
    const paymentSuccess = new URLSearchParams(location.search).get(
      'payment_success',
    );

    if (paymentSuccess) {
      dispatch(addNotification('Payment successful, welcome to Valearnis!'));
    }
  }, []);

  const handleShowReferralCode = () => {
    if (referralCode !== '') {
      setShowReferralCode(true);
      return;
    }
    fetch('http://localhost:8000/api/v2/account/referral-code/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setReferralCode(data.referral_code);
        setShowReferralCode(true);
      })
      .catch((error) => {
        console.error(error);
        dispatch(addNotification('Error getting referral code'));
      });
  };

  const handleMouseLeave = () => {
    setShowReferralCode(false);
  };

  return (
    <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
      <Flex
        display={'grid'}
        gridTemplateColumns={['1fr', null, '1fr 1fr']}
        w={['100%', '22rem', '720px']}
        m={'0 auto'}
        px={['2rem', '0']}
        pt={['5vh', null, '10vh']}
        gap={[2, 2, 4]}
      >
        <ButtonCurriculum
          icon={'manage-student'}
          to={'manage-students'}
          label={'Manage Students'}
          width={['100%']}
        />
        <ButtonCurriculum
          icon={'student-reports'}
          to={'update-billing'}
          label={'Billing'}
          width={['100%']}
        />
      </Flex>

      <Box style={{ position: 'relative' }} pt={5}>
        <Box
          onMouseEnter={handleShowReferralCode}
          onMouseLeave={handleMouseLeave}
          onClick={handleShowReferralCode}
          aria-label='Copy referral code'
          width={'10rem'}
          height={'3rem'}
          backgroundColor={'brand.500'}
          borderRadius={'10px'}
          color={'#FFF'}
          fontWeight={700}
          letterSpacing={'0.0625rem'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {showReferralCode ? (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-around'}
              width={'100%'}
            >
              <span>{referralCode}</span>
              <Box height={'22px'} width={'22px'}>
                <CopyToolTip
                  copy={referralCode}
                  copied={
                    'Code copied to clipboard!\n\nBe sure to share it with your friends before they sign up to enjoy exclusive benefits.'
                  }
                  content={
                    'Click to copy!\n\nUnlock two extra months of free service by sharing your referral code with new users!'
                  }
                  placement='bottom'
                  defaultIsOpen={false}
                />
              </Box>
            </Box>
          ) : (
            <span>Referral Code</span>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default ParentDashboard;
