import { HStack, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import PinEnterInput from './PinEnterInput';
import { usePinLoginMutation } from 'app/services/api';
import { useDispatch } from 'react-redux';
import { setProfilePin } from 'features/userProfile/userSlice';

type PinEnterInputGroupProps = {
  onSuccess: () => void;
  student_id: string;
};

const PinEnterInputGroup: React.FC<PinEnterInputGroupProps> = ({
  onSuccess,
  student_id,
}) => {
  const [pin, setPin] = useState(['', '', '', '']);
  const inputRefs = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
  ];
  const [error, setError] = useState(false);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pinLogin] = usePinLoginMutation();

  const handleChange = async (value: string, index: number) => {
    if (/^\d$/.test(value)) {
      setError(false);
      const correctInputIndex = () => {
        for (let i = 0; i < index; i++) {
          if (pin[i] === '') {
            inputRefs[i]?.current?.focus();
            return i;
          }
        }
        return index;
      };

      const correctIndex = correctInputIndex();

      const newPin = [...pin];
      newPin[correctIndex] = value;
      setPin(newPin);
      if (correctIndex < 3) {
        inputRefs[correctIndex + 1]?.current?.focus();
      }

      // console.log('newPin:', newPin);

      // Check if all four digits are entered
      if (newPin.every((digit) => digit !== '')) {
        try {
          const enteredPin = newPin.join('');
          const pinLoginResponse = await pinLogin({
            pin: enteredPin,
            student_id: student_id === 'guardian' ? undefined : student_id,
          }).unwrap();
          if (pinLoginResponse.msg === 'Guardian') {
            dispatch(setProfilePin(enteredPin));
            onSuccess();
            // navigate('/account', { replace: true });
          } else if (pinLoginResponse.msg === 'Student') {
            dispatch(setProfilePin(enteredPin));
            onSuccess();
            // navigate('/dashboard', { replace: true });
            // dispatch(
            //   api.util.prefetch('getMenuItems', { studentId: student_id }, {}),
            // );
            // dispatch(
            //   api.util.prefetch(
            //     'getUserProfile',
            //     { studentId: student_id },
            //     {},
            //   ),
            // );
            // dispatch(api.util.prefetch('getPopulations', undefined, {}));
            // dispatch(api.util.prefetch('getLevelMedians', undefined, {}));
          } else {
            setError(true);
            const emptyPin = ['', '', '', ''];
            setPin(emptyPin);
            dispatch(setProfilePin(''));
            inputRefs[0]?.current?.focus();
          }
        } catch (error) {
          setError(true);
          const emptyPin = ['', '', '', ''];
          setPin(emptyPin);
          inputRefs[0]?.current?.focus();
        }
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace') {
      const correctInputIndex = () => {
        for (let i = 3; i >= 0; i--) {
          if (pin[i] !== '') {
            inputRefs[i]?.current?.focus();
            return i;
          }
        }
        return index;
      };

      const correctIndex = correctInputIndex();

      const newPin = [...pin];
      if (newPin[correctIndex]) {
        newPin[correctIndex] = '';
        setPin(newPin);
      }
      if (correctIndex > 0) {
        inputRefs[correctIndex]?.current?.focus();
      }
    }
  };

  useEffect(() => {
    inputRefs[0]?.current?.focus();
  }, []);

  return (
    <>
      <HStack
        style={{
          gap: '0.5rem',
        }}
      >
        {pin.map((value, index) => (
          <PinEnterInput
            key={index}
            index={index}
            value={value}
            onError={error}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={inputRefs[index]}
          />
        ))}
      </HStack>

      <Text fontSize='xs' color='yellow.400' as='b' opacity={error ? '1' : '0'}>
        Wrong PIN
      </Text>
    </>
  );
};

export default PinEnterInputGroup;
