import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import LessonDifficulty from 'features/lessons/LessonDifficulty';
import LessonButtons from 'features/lessons/LessonButtons';
import { RadioGroup } from 'components/ui/radio';

interface QuizNumber {
  quizName: string;
  quizNumber: number;
  mobile: boolean;
}

const LessonInfoBox: React.FC<QuizNumber> = ({
  quizName,
  quizNumber,
  mobile,
}) => {
  return (
    <Box
      role={'infoBox'}
      w={['80%', '350px', null, '300px']}
      m={['20px 0px', null, null, '0']}
      bgColor={'brand.600'}
      borderRadius={'0.6rem'}
      textAlign={'left'}
    >
      {mobile && (
        <Box
          zIndex={-1}
          pos={'relative'}
          left={'45%'}
          bottom={'18px'}
          w={'0'}
          h={'0'}
          borderLeft={'16px solid transparent'}
          borderRight={'16px solid transparent'}
          borderBottom={'20px solid #1d317e'}
        ></Box>
      )}
      <Box
        p={['1rem 2rem', null, null, '0']}
        bgColor={'#253D9D'}
        borderRadius={'0.6rem'}
      >
        <Heading
          as={'h3'}
          m={['0.5rem 0', null, null, '0']}
          fontSize={'1.1rem'}
        >
          Difficulty
        </Heading>
        <RadioGroup defaultValue='Regular' variant={'subtle'}>
          <LessonDifficulty stars={1} level={'Regular'} isDisabled={false} />
          <LessonDifficulty stars={2} level={'Challenging'} isDisabled={true} />
        </RadioGroup>
        {mobile && (
          <LessonButtons quizName={quizName} quizNumber={quizNumber} />
        )}
      </Box>
    </Box>
  );
};

export default LessonInfoBox;
