import React, { useState } from 'react';
import { Box, Flex, Heading, Button } from '@chakra-ui/react';
import PinShowGroup from 'components/auth/PinShowGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileAvatar from 'components/auth/ProfileAvatar';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';
import Main from 'layout/Main';
import { useAppSelector } from 'hooks/useAppSelector';

const ProfilePinShow: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pin, studentId } =
    (location.state as { pin: string; studentId: string }) || {};
  const [currentPin, setCurrentPin] = useState(pin?.split('') || []);
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);

  const redirectToChooseUser = () => {
    navigate('/account/enter-pin/' + studentId, {
      state: {
        userName: studentId !== 'guardian' ? user.studentName : auth.user.name,
        mode: 'select',
      },
      replace: true,
    });
  };

  return (
    <>
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
      ></Box>
      <Button
        position='fixed'
        top='0'
        right='0'
        m='16px'
        onClick={() => redirectToChooseUser()}
      >
        BACK TO ENTER PIN
      </Button>

      <Main textAlign={'center'}>
        <Box w={'100%'} mb={'0'} mt={'2rem'}>
          <Heading fontSize={'2rem'} fontWeight='semibold'>
            Here is your profile PIN
          </Heading>
          <Flex
            display='flex'
            flexDirection='column'
            justifyContent='center'
            marginTop='2rem'
          >
            <Box>
              <ProfileAvatar
                src={
                  user.urls.thumbnail +
                  getThumbnailFilename(
                    user.skinChoice.filename.split('/').pop() as string,
                  )
                }
              />
            </Box>
            <Box width='300px' margin='auto'>
              <PinShowGroup
                pin={currentPin}
                setPin={setCurrentPin}
                disable={true}
              />
            </Box>
          </Flex>
        </Box>
      </Main>
    </>
  );
};

export default ProfilePinShow;
