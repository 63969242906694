import React from 'react';
import { Outlet } from 'react-router';
import { Box, Heading } from '@chakra-ui/react';

import PaddedContainer from 'components/containers/PaddedContainer';
import CloseButton from 'components/nav/CloseButton';

interface Props {
  height: string;
  children?: React.ReactNode;
}

const ManageStudentsLayout: React.FC<Props> = ({ children, height }) => {
  return (
    <PaddedContainer
      text-align={'center'}
      padding={'30px 20px'}
      height={height}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        my={'25px'}
        mr={'18px'}
      >
        <Box flex={'1'} textAlign={'center'}>
          <Heading fontSize={'18px'} pl={['0', '34px']}>
            Manage Students
          </Heading>
        </Box>
        <CloseButton />
      </Box>
      <Box h={'100%'} pt={'4rem'}>
        {children}
        <Outlet />
      </Box>
    </PaddedContainer>
  );
};

export default ManageStudentsLayout;
