import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Image, LinkProps, Flex } from '@chakra-ui/react';
import { lowerCase } from 'utils/stringUtils';

interface Props extends LinkProps {
  icon: string;
  to: string;
  label: string;
  width?: string[];
}

const ButtonCurriculum: React.FC<Props> = ({ icon, to, label, width }) => {
  return (
    <RouterLink to={to}>
      <Box
        position={'relative'}
        w={width}
        minW={['100%', '14rem', '18rem']}
        minH={['8rem', '9rem']}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        // my={2}
        fontSize={'1rem'}
        fontWeight={700}
        color={'#FFF'}
        borderRadius={'10px'}
        letterSpacing={'0.0625rem'}
        textTransform={'uppercase'}
        flexFlow={[null, null, 'row wrap']}
        transition='background-color 0.3s ease'
        css={{
          position: 'relative',
          _before: {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: 'inherit',
            backgroundColor: 'button.dark-blue',
            zIndex: -1,
            transition:
              'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
          },
          _hover: {
            _before: {
              backgroundColor: 'brand.600',
              transform: 'scale(1.01)',
              boxShadow:
                '5px 5px 8px color-mix(in srgb, var(--chakra-colors-brand-400) 64%, transparent)',
            },
          },
        }}
      >
        <Flex
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          alignContent={'center'}
        >
          <Image
            src={`https://cdn.valearnis.com/icons/${lowerCase(icon)}.svg`}
            width='40px'
            height='40px'
            maxH={'40px'}
            objectFit={'fill'}
          />
          <Box as={'span'} display={'block'} mt={['0.5rem', null, '1rem']}>
            {label}
          </Box>
        </Flex>
      </Box>
    </RouterLink>
  );
};

export default ButtonCurriculum;
