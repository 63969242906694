import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

// import { useAppSelector } from 'hooks/useAppSelector';

import Star from 'features/lessons/Star';

const ProgressBar: React.FC = () => {
  // const quizList: QuizScores = useAppSelector(
  //   (state) => state.progress.quizList,
  // );
  // const regularScore = quizList[quizNumber].regular;
  // const challengingScore = quizList[quizNumber].challenging;

  // const score = (regularScore + challengingScore) / 2;

  return (
    <Flex w={'100%'} className={'prog'} flexDir={'row'} align={'center'}>
      <Box
        w={['100%', '80%', null, '75%']}
        mr={'10px'}
        bgColor={'#bec5e2'}
        borderRadius={'10px'}
      >
        <Box
          role={'innerBar'}
          w={'0%'}
          color={'gold'}
          borderTop={'5px solid'}
          m={'0'}
          borderRadius={'10px'}
        />
      </Box>
      {/* <Star active={score > 0 && true} /> */}
      {/* <Star active={score > 0 && true} /> */}
      <Star active={false} />
      <Star active={false} />
    </Flex>
  );
};
export default ProgressBar;
