import React, { useState, useEffect } from 'react';
import { Heading, Box, Text, Grid } from '@chakra-ui/react';
import FactionCard from './FactionCard';
import HouseFactions from './HouseFactions';
import { useAppSelector } from 'hooks/useAppSelector';
import Notification from 'app/Notification';

const ChooseFaction: React.FC = () => {
  const [error, setError] = useState(false);
  const { recommendedFaction, selectedFaction } = useAppSelector(
    (state) => state.faction,
  );

  const updateHouseHandler = (result: boolean) => {
    if (result) {
      setError(false);
    } else setError(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 5000);
  }, [error]);

  const selected_colTemplate = {
    base: 'repeat(1, 1fr)',
    md: 'repeat(1, 1fr)',
    lg: 'repeat(1, 1fr)',
    xl: 'repeat(1, 1fr)',
  };

  const colTemplate = {
    base: 'repeat(1, 1fr)',
    md: 'repeat(1, 1fr)',
    lg: 'repeat(3, 1fr)',
    xl: 'repeat(3, 1fr)',
  };

  return (
    <>
      {error && (
        <Notification
          message={'Unable to join faction. Come back and try again later.'}
        />
      )}

      <Box
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        alignContent={'center'}
        m={'2rem auto'}
        p={'1rem'}
        borderRadius={'10px'}
      >
        {selectedFaction === '' && (
          <React.Fragment>
            <Heading pb={'0.5rem'}>Choose your faction</Heading>
            <Text
              fontSize={['0.9rem', '1rem', '1.1rem', '1.2rem']}
              fontWeight={'bold'}
              p={'0 2rem'}
              mb={['1rem', '1rem', '2rem', '4rem']}
            >
              {`You are recommended to join ${recommendedFaction}! You may choose another if you feel you belong there.`}
            </Text>
          </React.Fragment>
        )}
      </Box>

      <Grid
        templateColumns={
          selectedFaction !== '' ? selected_colTemplate : colTemplate
        }
        gap={[16, 16, '80px', 20]}
      >
        {HouseFactions.map((faction) => {
          return (
            <FactionCard
              key={faction.name}
              faction={faction}
              updateHouseHandler={updateHouseHandler}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default ChooseFaction;
