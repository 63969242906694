import React, { useEffect, createContext, useContext, ReactNode } from 'react';
import { useScreenSize } from 'hooks/useScreenSize';
import { Avatar } from 'components/ui/avatar';
import { Image } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';

interface SidebarContextProps {
  TutorIcon: React.FC;
  UserIcon: React.FC;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined,
);

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { sideBarCollapsed } = useAppSelector((state) => state.ai);
  const { toggleSidebarState } = useActions();

  const ismd = useScreenSize('md');

  // Closing the sidebar on medium screens
  useEffect(() => {
    if (ismd && !sideBarCollapsed) {
      toggleSidebarState();
    }
  }, [ismd]);

  const { urls, skinChoice } = useAppSelector((state) => state.user);
  const aiTeacher = 'https://cdn.valearnis.com/backgrounds/ai/robot-ai.png';
  const TutorIcon = () => {
    return (
      <Image objectFit={'fill'} src={aiTeacher} width='28px' height='28px' />
    );
  };

  const UserIcon = () => {
    return (
      <Avatar
        // objectFit={'fill'}
        src={
          urls.thumbnail +
          getThumbnailFilename(skinChoice.filename.split('/').pop() as string)
        }
        width='28px'
        height='28px'
      />
    );
  };

  return (
    <SidebarContext.Provider value={{ TutorIcon, UserIcon }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = (): SidebarContextProps => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
