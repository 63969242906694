import React from 'react';
import { Button, Image } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';
import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';

const LargeImage: React.FC = () => {
  const image = useAppSelector((state) => state.largeImage.imgPath);
  const largeImageVisible = useAppSelector((state) => state.largeImage.visible);
  const { toggleLargeImage } = useActions();
  return (
    <DialogRoot
      motionPreset='slide-in-bottom'
      lazyMount
      open={largeImageVisible}
      placement={'center'}
    >
      <DialogContent
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
      >
        <DialogHeader>
          <DialogTitle></DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Image
            objectFit={'contain'}
            src={image}
            w={'100%'}
            h={'70%'}
            mt={'1rem'}
          />
        </DialogBody>
        <DialogFooter pt={0}>
          <DialogActionTrigger asChild>
            <Button
              role='button'
              data-testid='large-image-close-button'
              variant='outline'
              onClick={() => {
                toggleLargeImage();
              }}
            >
              Close
            </Button>
          </DialogActionTrigger>
        </DialogFooter>
        <DialogCloseTrigger
          padding={'10px'}
          backgroundColor='brand.300'
          minWidth={6}
          height={6}
          display={'flex'}
          alignItems={'center'}
          margin={'0.2rem auto'}
          _hover={{ backgroundColor: 'brand.400' }}
          onClick={() => {
            toggleLargeImage();
          }}
        />
      </DialogContent>
    </DialogRoot>
  );
};

export default LargeImage;
