import { Input, Box } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

type PinEnterInputProps = {
  index: number;
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const PinShow = forwardRef<HTMLInputElement, PinEnterInputProps>(
  (props, ref) => {
    const { index, value, disabled, onChange, onKeyDown } = props;
    return (
      <Box
        width='54px'
        height='76px'
        backgroundColor='rgba(0,0,0,0.3)'
        border='1px solid transparent'
        borderRadius='4px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        transition='all 0.1s ease'
      >
        <Input
          className='pin-input'
          id={`pin-${index}`}
          name={`pin-${index}`}
          type='number'
          role='textbox'
          disabled={disabled}
          inputMode='numeric'
          pattern='[0-9]*'
          maxLength={1}
          autoComplete='off'
          placeholder='•'
          width='44px'
          height='48px'
          fontSize='32px'
          color='#f9f9f9'
          padding='0'
          textAlign='center'
          outline='none'
          backdropFilter='none'
          backgroundColor='transparent'
          border='none'
          borderBottom='2px solid transparent'
          borderRadius='none'
          style={{
            caretColor: 'transparent',
          }}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          ref={ref}
          _focus={{
            borderBottom: '2px solid #02e7f5',
          }}
          _active={{
            borderBottom: '2px solid #02e7f5',
          }}
          _hover={{
            borderBottom: '2px solid #ffffff3d',
          }}
        />
      </Box>
    );
  },
);

PinShow.displayName = 'PinShow';

export default PinShow;
