import { Box, Text } from '@chakra-ui/react';
import React, { useRef } from 'react';
import PinShow from './PinShow';

type PinShowGroupProps = {
  pin: string[];
  setPin: React.Dispatch<React.SetStateAction<string[]>>;
  disable?: boolean;
};

const PinShowGroup: React.FC<PinShowGroupProps> = ({
  pin,
  setPin,
  disable,
}) => {
  const inputRefs = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
  ];
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const handleChange = async (value: string, index: number) => {
    if (/^\d$/.test(value)) {
      const correctInputIndex = () => {
        for (let i = 0; i < index; i++) {
          if (pin[i] === '') {
            inputRefs[i]?.current?.focus();
            return i;
          }
        }
        return index;
      };

      const correctIndex = correctInputIndex();

      const newPin = [...pin];
      newPin[correctIndex] = value;
      setPin(newPin);
      if (correctIndex < 3) {
        inputRefs[correctIndex + 1]?.current?.focus();
      }

      // Check if all four digits are entered
      if (newPin.every((digit) => digit !== '')) {
        try {
          //   const enteredPin = newPin.join('');
          //   const pinLoginResponse = await pinLogin({
          //     pin: enteredPin,
          //     student_id: student_id === 'guardian' ? undefined : student_id,
          //   }).unwrap();
        } catch (error) {
          // console.error('Error logging in with pin', error);
          const emptyPin = ['', '', '', ''];
          setPin(emptyPin);
          inputRefs[0]?.current?.focus();
        }
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace') {
      const correctInputIndex = () => {
        for (let i = 3; i >= 0; i--) {
          if (pin[i] !== '') {
            inputRefs[i + 1]?.current?.focus();
            return i;
          }
        }
        return index;
      };

      const correctIndex = correctInputIndex();

      const newPin = [...pin];
      if (newPin[correctIndex]) {
        newPin[correctIndex] = '';
        setPin(newPin);
      }
      if (correctIndex > 0) {
        inputRefs[correctIndex]?.current?.focus();
      }
    }
  };

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Text fontSize='lg'>Profile PIN:</Text>
      <Box
        width='100%'
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        style={{
          gap: '0.5rem',
        }}
      >
        {pin.map((value, index) => (
          <PinShow
            key={index}
            index={index}
            value={value}
            disabled={disable}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={inputRefs[index]}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PinShowGroup;
