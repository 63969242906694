import React, { useEffect, useState } from 'react';
import PaddedContainer from 'components/containers/PaddedContainer';
import { Button, Input, Text, Flex } from '@chakra-ui/react';
import {
  useLazyResendOtpQuery,
  useVerifyGuardianMutation,
} from 'app/services/api';
import { useNavigate } from 'react-router-dom';
import { useActions } from 'hooks/useActions';
import InstructionsTooltip from 'features/instructions/InstructionsTooltip';

const OtpInputPage: React.FC = () => {
  const [otp, setOtp] = useState(0);
  const [verifyGuardian] = useVerifyGuardianMutation();
  const navigate = useNavigate();
  const { verifyEmail, addNotification } = useActions();
  const [resendOtp, { isSuccess }] = useLazyResendOtpQuery();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (isSuccess) {
      addNotification({
        message: 'Code resent to your registered email address.',
        position: 'top',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isButtonDisabled]);

  return (
    <PaddedContainer
      maxW={'35rem'}
      minH={'auto'}
      padding={['1.75rem 1.875rem', '1.75rem 3.625rem']}
      text-align={'center'}
    >
      <Flex
        justifyContent={'right'}
        w={'100%'}
        maxW={'auto'}
        textAlign={'right'}
      >
        <InstructionsTooltip
          defaultIsOpen={true}
          content={
            'You should receive an email with a code at the email address you signed up with.'
          }
          footer={
            'It can sometimes take a few minutes for the email to appear in your mailbox.'
          }
          align={'bottom'}
        />
      </Flex>
      <Text>Please enter the 6-digit number you received via email</Text>
      <Input
        w={'80%'}
        m={'10px'}
        bg={'rgba(255, 255, 255, 0.65)'}
        color={'#243D9D'}
        border={'none'}
        borderRadius={'5px'}
        letterSpacing={'.5px'}
        onChange={(e) => setOtp(Number(e.currentTarget.value))}
        _focus={{
          border: '1px solid #63b3ed',
          outlineStyle: 'none',
        }}
      />
      <Button
        onClick={async (e) => {
          e.preventDefault();
          if (otp.toString().length !== 6) {
            addNotification({
              message: 'Please enter a 6-digit number.',
              position: 'top',
            });
          } else {
            await verifyGuardian({
              otp,
            })
              .unwrap()
              .then(() => {
                verifyEmail();
                // navigate('/account/plan');
                navigate('/account/choose-user');
              })
              .catch(() => {
                addNotification({
                  message:
                    'Code incorrect. Please try again or contact support if the problem persists.',
                  position: 'top',
                });
              });
          }
        }}
      >
        Verify
      </Button>
      <Text>
        Didn&apos;t get the email? Make sure to check your spam folder, or click
        here to receive it again.
      </Text>
      <Button
        onClick={() => {
          resendOtp();
          setIsButtonDisabled(true);
        }}
        disabled={isButtonDisabled}
      >
        {isButtonDisabled ? `Wait ${timer}s` : 'Send email again'}
      </Button>
    </PaddedContainer>
  );
};

export default OtpInputPage;
