import { Input } from '@chakra-ui/react';
import { FibAnswer } from 'app/services/api';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import React from 'react';

interface Props {
  optionOrder: number;
}

const InputSection: React.FC<Props> = ({ optionOrder }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();

  const thisAnswer = userAnswer?.find((answer: FibAnswer) => {
    return answer.fill_index === optionOrder;
  }) as FibAnswer;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newEntry = event.target.value;
    const answers = userAnswer?.map((answer: FibAnswer) => {
      if (answer.fill_index === optionOrder) {
        return { fill_index: answer.fill_index, response: newEntry };
      } else {
        return answer;
      }
    });
    selectAnswer(answers);
  };
  return (
    <Input
      value={(thisAnswer !== undefined && thisAnswer.response) || ''}
      role={'inputField'}
      w={'100px'}
      m={'0 6px 0px 2px'}
      p={'0'}
      onChange={handleChange}
      borderWidth={'0'}
      borderRadius={'0'}
      borderBottomWidth={'2px'}
      height={'18px'}
      position={'relative'}
      top={'2px'}
      paddingBottom={'2px'}
      fontSize={'16px'}
      borderColor={'#243D9D'}
      _focus={{
        borderColor: ' #63b3ed',
        outlineStyle: 'none',
      }}
    />
  );
};

export default InputSection;
