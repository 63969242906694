/**
 * This file is generated for providing a custom theme to Chakra UI
 *
 * To learn more about custom themes
 * please visit https://www.chakra-ui.com/docs/theming/overview
 *
 * To create a custom component use the Chakra UI Recipe Concept.
 *
 * Define the component styles in the components folder and import
 * them into the main components.ts file where the recipes are defined and created.
 *
 * The old 'styles' file has been deleted as it is not working when added to the defineConfig.
 * Certain styles are not being applied to the components.
 *
 * The styles are now defined in the app.css file.
 */

import { createSystem, defineConfig, defaultConfig } from '@chakra-ui/react';

import colors from './foundations/colors';
import fonts from './foundations/fonts';
import fontSizes from './foundations/fontSizes';
import breakpoints from './foundations/breakpoints';
import { componentRecipes } from './components';

const config = defineConfig({
  theme: {
    tokens: {
      colors,
      fonts,
      fontSizes,
    },
    semanticTokens: {
      colors: {
        brand: {
          solid: { value: '{colors.brand.500}' },
          contrast: { value: '{colors.brand.100}' },
          fg: { value: '{colors.brand.700}' },
          muted: { value: '{colors.brand.100}' },
          subtle: { value: '{colors.brand.200}' },
          emphasized: { value: '{colors.brand.300}' },
          focusRing: { value: '{colors.brand.500}' },
        },
      },
    },
    breakpoints,
    recipes: {
      ...componentRecipes,
    },
  },
});

const theme = createSystem(defaultConfig, config);
export default theme;
