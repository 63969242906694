import React from 'react';
import { Box } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';

import FillBlankQuestion from 'features/lessons/templates/fillInBlanks/FillBlankQuestion';
import MultiChoiceQuestion from 'features/lessons/templates/MultiChoiceQuestion';
// import PictureAreaQuestion from 'features/lessons/templates/pictureSelectArea/PictureAreaQuestion';
import SelectWordQuestion from 'features/lessons/templates/selectWord/SelectWordQuestion';
import TheoryTemplate from 'features/lessons/templates/TheoryTemplate';
import {
  TransformedQuestion,
  TransformedTheory,
} from 'features/questions/questionsSlice';

const Question: React.FC = () => {
  const { currentQuestion, questions } = useAppSelector(
    (state) => state.questions,
  );

  if (Object.keys(questions[currentQuestion]).includes('description')) {
    const { description } = questions[currentQuestion] as TransformedTheory;
    return <TheoryTemplate textBody={description} />;
  }

  const { content, questionType, options, instruction } = questions[
    currentQuestion
  ] as TransformedQuestion;

  const getTemplateByQuestionType = () => {
    switch (questionType) {
      case 'SA_MCQ':
      case 'SA_MCQI':
      case 'MCP':
      case 'MCQ':
      case 'MCQI':
      case 'DROP':
      case 'TF':
        if (typeof options !== 'undefined') {
          return (
            <MultiChoiceQuestion
              questionType={questionType}
              question={content}
              options={options}
            />
          );
        }

        break;
      // case 'SPA':
      //   if (
      //     typeof image === 'string' &&
      //     typeof options !== 'undefined' &&
      //     typeof picDimensions !== 'undefined'
      //   ) {
      //     return (
      //       <PictureAreaQuestion
      //         picture={image}
      //         coords={options}
      //         question={content}
      //         picDimensions={picDimensions}
      //       />
      //     );
      //   }

      //   break;

      case 'SW':
        if (typeof instruction !== 'undefined') {
          return (
            <SelectWordQuestion
              instruction={instruction}
              question={content}
              answers={options}
            />
          );
        }

        break;

      case 'FIB':
        if (
          typeof options !== 'undefined' &&
          typeof instruction !== 'undefined'
        ) {
          return (
            <FillBlankQuestion
              instruction={instruction}
              question={content}
              answers={options}
            />
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <Box h={'100%'} overflowY={'auto'} pr={2}>
      {getTemplateByQuestionType()}
    </Box>
  );
};

export default Question;
