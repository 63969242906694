import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import Question from './answerInfoChildren/Question';
import CorrectnessIndicator from './answerInfoChildren/CorrectnessIndicator';
import DOMPurify from 'dompurify';
import { useKatex } from 'hooks/useKatex';

interface Props {
  question: string;
  userAnswers: string[];
  isCorrect: boolean;
  index: string;
}

const Answerinfo: React.FC<Props> = ({
  question,
  userAnswers,
  isCorrect,
  index,
}) => {
  const ele = React.createRef<HTMLDivElement>();

  useKatex(ele);

  const formattedAnswers = () => {
    // If the answer(s) contain an image
    if (userAnswers.some((answer) => answer.includes('//cdn.valearnis.com'))) {
      return (
        <Box display={'flex'} justifyContent={'space-between'}>
          {userAnswers.map((answer) => {
            return (
              <Box
                key={answer}
                display={'inline-block'}
                maxWidth={'250px'}
                paddingRight={'8px'}
                css={{
                  '& img': { borderRadius: '1rem' },
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(answer),
                }}
              />
            );
          })}
        </Box>
      );
    } else {
      return userAnswers.join(', ').replace(/(<([^>]+)>)/gi, '');
    }
  };

  return (
    <Flex
      flexDir={'column'}
      mb={['25px', null, null, '50px']}
      pb={['15px', null, null, '30px']}
      borderBottom={'1px solid #fff'}
    >
      <Flex
        // justify={'space-between'}
        alignItems={['left', null, null, 'center']}
        flexDir={['column', null, null, 'row']}
        justify={[null, null, null, 'space-between']}
      >
        <Flex ref={ele} textAlign={'left'} flexDir={'column'} wrap={'wrap'}>
          <Question question={question} questionNum={index} />
          <Text fontSize={'14px'} letterSpacing={'1.12px'} w={'100%'}>
            Your answer:
          </Text>
          {formattedAnswers()}
        </Flex>
        <CorrectnessIndicator correct={isCorrect} />
      </Flex>
    </Flex>
  );
};

export default Answerinfo;
