import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Main from 'layout/Main';
import Header from 'components/nav/Header';
import { useAppSelector } from 'hooks/useAppSelector';

const MotionBox = motion.create(Box);

interface Props {
  children?: React.ReactNode;
}

const FactionLayout: React.FC<Props> = ({ children }) => {
  const { house } = useAppSelector((state) => state.faction);
  const [backgroundStyle, setBackgroundStyle] = useState<React.CSSProperties>(
    {},
  );

  // Prevents a stutter when the page initially loads and the background image is not yet loaded
  useEffect(() => {
    if (house === '' || house === null) {
      setBackgroundStyle({});
      return;
    }
    const background = `https://cdn.valearnis.com/house-factions/${house.toLowerCase()}/${house}%20Background.png`;
    const img = new Image();
    img.src = background; // Replace with your image path
    img.onload = () => {
      setTimeout(() => {
        setBackgroundStyle(
          background
            ? { backgroundImage: `url(${background})` }
            : { background: 'linear-gradient(#00a896, #1565c0)' },
        );
      }, 400); // Delay of 0.5 seconds
    };
  }, [house]);

  return (
    <>
      <Header backgroundStyle={backgroundStyle} />
      <Box
        id='faction-dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'#00a896'}
        gradientTo={'#1565c0'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
        style={backgroundStyle}
      ></Box>
      <Main textAlign={'center'}>
        <Box
          h={'100%'}
          w={'100%'}
          // mt={['1rem', '1rem', '2rem']}
          // mb={'2rem'}
          transition={'margin-top 0.5s ease-in-out'}
        >
          <MotionBox
            initial={{ opacity: 0, y: 60 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: 'linear' }}
          >
            {children}
            <Outlet />
          </MotionBox>
        </Box>
      </Main>
    </>
  );
};

export default FactionLayout;
