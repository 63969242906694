import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import BigExperienceBar from 'features/userProfile/BigExperienceBar';
import { getThumbnailFilename } from './characterSelection/utils';
import { Avatar } from 'components/ui/avatar';
const ClassInfoBox: React.FC = () => {
  const {
    level,
    expToLvl,
    totalLevelExp,
    urls,
    skinChoice,
    userName,
    studentName,
  } = useAppSelector((state) => state.user);
  return (
    <Flex
      direction={'column'}
      wrap={'wrap'}
      m={['25px auto', null, null, '0']}
      borderRadius={'10px'}
      bgColor={'brand.600'}
      height={['100%', null, null, '200px']}
      pt={['20px', null, null, '0']}
      alignItems={'center'}
      justify={'center'}
      w={'100%'}
    >
      <Flex width={'80%'} alignItems={'center'} textAlign={'left'} gap={'8px'}>
        <Avatar
          width={'50px'}
          height={'50px'}
          src={
            urls.thumbnail +
            getThumbnailFilename(skinChoice.filename.split('/').pop() as string)
          }
        />
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={'20px'}
          alignContent={'start'}
        >
          <Box
            gap={'4px'}
            // width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            justifyContent={'start'}
          >
            <Text
              m={0}
              fontSize={'20px'}
              fontWeight={'bold'}
              textAlign={'left'}
            >
              {studentName === '' ? userName : studentName}
            </Text>
            <Text m={0} fontSize={'16px'} textAlign={'left'}>
              {`Level ${level}`}
            </Text>
          </Box>
          <Box display={['block', null, null, 'none']}>
            <Text
              m={0}
            >{`${totalLevelExp - expToLvl}/${totalLevelExp} XP`}</Text>
          </Box>
        </Box>
      </Flex>
      <Box textAlign={'right'} width={'80%'}>
        <BigExperienceBar expToLvl={expToLvl} totalLevelExp={totalLevelExp} />
        <Box display={['none', null, null, 'block']}>
          <Text>{`${totalLevelExp - expToLvl}/${totalLevelExp} XP`}</Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default ClassInfoBox;
