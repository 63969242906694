import { Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import React, { useState } from 'react';
import CurriculumBox from './CurriculumBox';
import SubjectSummary from './SubjectSummary';
const CurriculumSummary: React.FC = () => {
  const [openSubject, setOpenSubject] = useState<string | null>(null);

  const subjects = ['Science', 'Maths', 'HASS', 'English'];

  const subjectDescriptions: { [key: string]: string } = {
    Science:
      'Within the science curriculum, students will learn about the key areas of biology, earth science, physics, and chemistry. More specifically they will cover geological processes, renewable energy, climate, different energy types, chemical reactions, taxonomy, cells, atoms, mass, matter, gravity, astronomy, planetary science, and beyond!',
    Maths:
      'A collection of geometry, number, algebra, trigonometry, shapes, space, measurement, fractions, decimals, graphs, chance, data, and financial mathematics lessons. Your students will learn key mathematical concepts, as well as how, when, and why to apply them.',
    HASS: 'Our Humanities and Social Sciences curriculum comprises four subjects: history, geography, economics and business, and civics and citizenship. Students are able to explore human behaviour and interactions in geographical, environmental, historical, cultural, economic and legal contexts. The scope of HASS ranges from personal to global and from ancient to future.',
    English:
      'English within Valearnis is centred around literature, literacy, and language. Students are encouraged to analyse texts for meaning and comprehension, improve their reading, spelling and grammatical abilities, understand literary concepts such as similes, personification, homophones, and other essential areas. We aim to aid young Australians in becoming confident, effective and informed users of English.',
  };
  const topics: { [key: string]: string[] } = {
    Science: ['Physics', 'Chemistry', 'Earth Science', 'Biology'],
    Maths: ['Number & Algebra', 'Statistics', 'Geometry'],
    HASS: [
      'History',
      'Geography',
      'Business and Economy',
      'Civics and Citizenship',
    ],
    English: ['Language', 'Literacy', 'Literature'],
  };

  const topicDescriptions: { [key: string]: { [key: string]: string } } = {
    Science: {
      Physics:
        'The study of the physical universe and physical phenomena, this sub-topic focuses on energy, equations, force, gravity, electricity, magnetism, sound, light, radiation, and scientific modelling.',
      Chemistry:
        'Topics include the study of chemical formulas and reactions, valency, ionisation, isotopes, mixtures, atoms, molecules, bonding, and periodic elements.',
      'Earth Science':
        'Students will attain an understanding of geological processes, weather, climate, volcanology, planetary science, astronomy, seismology, palaeontology, renewable energy, and climate change. ',
      Biology:
        'Within the field of biological sciences, your students will encounter taxonomy, classification of life, speciation, evolution, cell analysis & structure, organs, reproduction, and the basis of human biology.',
    },
    Maths: {
      'Number & Algebra':
        'Topics include the laws of arithmetic, understanding negative numbers, converting between fractions, decimals, and percentages, terminating and repeating decimals, rational and irrational numbers, equations of lines, index laws, and working with algebraic expressions. Your students will build algebraic intuition and number sense, and learn how to model real-life situations mathematically.',
      Statistics:
        'Topics include data sets, different types of data, interpreting data with graphs, understanding mean, median, mode, and range, as well as an introduction to probability using sets and independent and dependent events. Your students will learn about the many types of data that exist in the world around them, and how to interpret that data in useful ways.',
      Geometry:
        'Topics include classifying triangles and quadrilaterals, congruence and similarity, area and perimeter, surface area and volume, distance, identifying and measuring angles, the Cartesian plane, transversals and parallel lines, the Pythagorean theorem, and an intro to trigonometry. Your students will learn how to understand how to model and solve problems geometrically, and will build an understanding of the different kinds of geometric relationships that exist.',
    },
    HASS: {
      History:
        'History is the study of the past - past societies, past ideologies, past cultures. Our History curriculum guides students through pivotal events in human history, explains how modern societies function, and ignites their curiosity and imagination. By learning history, students understand themselves and others.',
      Geography:
        'Geography is a social science subject that explores elements, features, inhabitants and changes of the Earth. Our Geography curriculum allows students to explore the relationship between people and the environment. With a rapid rise in globalisation and international mobility, geographical literacy is as crucial as ever.',
      'Business and Economy':
        "Businesses are the backbone of Australia's economic prosperity and high living standards. The study of Economics and Business explores the production, allocation and consumption of resources and goods, with some references to finance, politics and law. Our Economics and Business curriculum encourages students to become enterprising and informed global citizens.",
      'Civics and Citizenship':
        'Laws are the rules and standards of acceptable behaviours in societies - they are the fundamentals of human civilisation. Our Civics and Citizenship curriculum seeks to inform students of the Australian legal system, values and beliefs and enable them to become active and democratic global citizens.',
    },
    English: {
      Language:
        'English is the primary language used in Australia. Valearnis Language curriculum is concerned with the linguistic structure and elements of Australian English. We seek to develop student confidence and accuracy in creating English communicative pieces.',
      Literacy:
        'Literacy is the ability to use and understand a language. Our literacy curriculum focuses on developing student ability to listen to, read, write and speak oral, print, visual and digital English texts. We seek to develop student ability to interpret and create English communicative pieces.',
      Literature:
        'Literature is the in-depth study of literary texts. Valearnis Literature curriculum dives into literary works by renowned authors to dissect the use of language, explore literary aesthetics, evaluate perspectives and challenge interpretations. We seek to develop students as independent, informed, creative users of English.',
    },
  };

  const icons = {
    Science: 'science',
    Physics: 'physics',
    Chemistry: 'chemistry',
    'Earth Science': 'e.science',
    Biology: 'biology',
    Maths: 'maths',
    'Number & Algebra': 'numbers',
    Statistics: 'statistics',
    Geometry: 'geography',
    HASS: 'hass',
    History: 'history',
    Geography: 'geography',
    'Business and Economy': 'economics',
    'Civics and Citizenship': 'civics',
    English: 'english',
    Language: 'language',
    Literacy: 'literacy',
    Literature: 'literature',
  };

  return (
    <Flex
      w={'100%'}
      justifyContent='center'
      alignItems={'center'}
      mt={['20px', null, 'initial']}
    >
      <Flex
        w={'90%'}
        bg={['none', 'brand.800', 'brand.800']}
        justifyContent={'center'}
        alignItems={'center'}
        flexDir={'column'}
        borderRadius={'15px'}
        py={'20px'}
        mb={'40px'}
      >
        {openSubject ? (
          <SubjectSummary
            setSubject={setOpenSubject}
            subject={openSubject}
            description={subjectDescriptions[openSubject]}
            topics={topics[openSubject]}
            topicDescriptions={topicDescriptions[openSubject]}
            icons={icons}
          />
        ) : (
          <>
            <Heading letterSpacing={'0.14rem'} fontWeight={'600'} pb={'20px'}>
              PREVIEW
            </Heading>

            <SimpleGrid columns={[1, 2, 2, 4]} gap='6' px='16px' pb={'16px'}>
              {subjects.map((subject) => (
                <CurriculumBox
                  key={subject}
                  setSubject={setOpenSubject}
                  subject={subject}
                  description={subjectDescriptions[subject]}
                  topics={topics[subject]}
                  topicDescriptions={topicDescriptions[subject]}
                />
              ))}
            </SimpleGrid>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default CurriculumSummary;
