import ButtonLink from 'components/buttons/ButtonLink';
import { useActions } from 'hooks/useActions';
import React from 'react';

const btnStyle = {
  width: ['100%', '80%', '60%', '40%'],
  height: '40px',
  borderRadius: '12px',
  backgroundColor: 'button.100',
  lineHeight: '12px',
};

const BackToSubJectSelectButton: React.FC = () => {
  const { resetSelections, setModule, setSubject } = useActions();
  return (
    <ButtonLink
      link_variant='button'
      to={'/curriculum'}
      {...btnStyle}
      onClick={() => {
        setModule('');
        setSubject('');
        resetSelections();
      }}
      _hover={{
        backgroundColor: 'button.300',
      }}
      textAlign={'center'}
    >
      Continue
    </ButtonLink>
  );
};

export default BackToSubJectSelectButton;
