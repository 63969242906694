import { Box, Image } from '@chakra-ui/react';
import { Character } from 'app/services/api';
import { useActions } from 'hooks/useActions';
// import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import { getDefaultThumbnail } from 'utils/objectUtils';
// import { getThumbnailFilename } from './utils';

interface Props {
  character: Character;
}

const CharacterChoice: React.FC<Props> = ({ character }) => {
  const { urls, tempSkinChoice, tempCharacterChoice } = useAppSelector(
    (state) => state.user,
  );
  const { setTempCharacterChoice } = useActions();
  const selected = tempCharacterChoice?.id === character.id;
  return (
    <Box
      m={'10px 10px 0 0'}
      role={'selectableAvatar'}
      onClick={() => {
        setTempCharacterChoice(character);
      }}
      w={'60px'}
      overflow={'hidden'}
      borderRadius={'5px'}
      borderColor={''}
      borderWidth={'0'}
      objectPosition={'0 10% 0 0'}
      transition='all 0.3s ease'
      border={selected ? '2px solid white' : '2px solid transparent'}
      _hover={{
        cursor: 'pointer',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        scale: 1.1,
        transition: 'all 0.3s ease',
      }}
    >
      <Box w={'125%'} transform={'translateX(-12.5%)'}>
        <Image
          filter={!character.is_unlocked ? 'grayscale(1)' : ''}
          src={urls.thumbnail + getDefaultThumbnail(character, tempSkinChoice)}
        />
      </Box>
    </Box>
  );
};

export default CharacterChoice;
