import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';

interface StarProps {
  active: boolean;
}

const Star: React.FC<StarProps> = ({ active }) => {
  return (
    <Icon
      data-testid='star'
      mb={'8px'}
      w={'24.55px'}
      h={'23.68px'}
      as={FaStar}
      color={active ? 'gold' : ''}
    />
  );
};

export default Star;
