import { Box, Text, Input } from '@chakra-ui/react';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from 'components/ui/select';
import { setStudentName } from 'features/userProfile/userSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createListCollection } from '@chakra-ui/react';

const YEAR_LEVELS = [
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
];

type ProfileFormProps = {
  mode: string;
  isStudent?: boolean;
  name?: string;
  setName?: React.Dispatch<React.SetStateAction<string>>;
  setYear?: React.Dispatch<React.SetStateAction<number>>;
};

const ProfileForm: React.FC<ProfileFormProps> = ({
  mode,
  isStudent,
  name,
  setName,
  setYear,
}) => {
  // const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  // const [profileName, setProfileName] = useState(
  //   mode === 'create' ? '' : isStudent ? user.studentName : user.userName,
  // );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setName) setName(e.target.value);
    dispatch(setStudentName(e.target.value));
  };

  const handleYearChange = (e: { value: any }) => {
    if (setYear) setYear(parseInt(e.value[0]));
  };

  const year_options = createListCollection({
    items: [...YEAR_LEVELS],
  });

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='start'
      alignItems='start'
      // as='form'
    >
      <Text fontSize='lg'>Profile name:</Text>
      <Input
        variant={'subtle'}
        height='50px'
        width='320px'
        size='xl'
        css={{ '--focus-color': 'white' }}
        backgroundColor='rgba(0,0,0,0.3)'
        placeholder='Profile name'
        value={name}
        onChange={(e) => handleChange(e)}
        disabled={
          (mode === 'edit' && !isStudent) || (mode === 'create' && isStudent)
        }
      />
      {mode === 'create' && (
        <>
          <Text fontSize='lg'>Profile year level:</Text>
          <SelectRoot
            collection={year_options}
            width='320px'
            size='lg'
            variant={'outline'}
            backgroundColor='#ffffffa6'
            color='brand.400'
            onValueChange={(e: any) => handleYearChange(e)}
            borderRadius='5px'
            defaultValue={['7']}
            positioning={{ placement: 'bottom', flip: false }}
            css={{
              '& button[data-state="open"]': {
                borderColor: '#63b3ed',
              },
              '& button[data-state="closed"]': {
                borderColor: 'transparent',
              },
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder='Select year level' />
            </SelectTrigger>
            <SelectContent backgroundColor={'#2d4dac'}>
              {year_options.items.map((movie: any) => (
                <SelectItem item={movie} key={movie.value}>
                  {movie.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </>
      )}
    </Box>
  );
};

export default ProfileForm;
