import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { capitalise } from 'utils/stringUtils';
import Star from 'features/lessons/Star';
import { useActions } from 'hooks/useActions';
import { Radio } from 'components/ui/radio';

interface DifficultyProps {
  stars: number;
  level: string;
  isDisabled: boolean;
}

// interface QuizScores {
//   [key: number]: { regular: number; challenging: number };
// }

const LessonDifficulty: React.FC<DifficultyProps> = ({
  stars,
  level,
  isDisabled,
}) => {
  const { setDifficulty } = useActions();
  // const quizList: QuizScores = useAppSelector(
  //   (state) => state.progress.quizList,
  // );

  const chooseDifficulty = () => {
    if (isDisabled === false) {
      setDifficulty(capitalise(level));
    }
  };

  const setOpacity = (isDisabled: boolean) => {
    if (isDisabled === true) {
      return 0.4;
    } else {
      return 1.0;
    }
  };
  return (
    <Flex
      opacity={setOpacity(isDisabled)}
      alignItems={'center'}
      fontSize={'0.5rem'}
      w={[null, null, null, '270px']}
    >
      <Radio
        role={'difficultySelect'}
        w={'1.8em'}
        h={'1.8em'}
        p={'0'}
        m={'0.5rem 1.2rem 0.5rem 0'}
        value={level}
        size={'lg'}
        css={{
          '& > span:first-of-type': {
            width: '12px',
            height: '12px',
            background: 'white',
            borderWidth: '0px',
          },
          '& > span:first-of-type > .dot': {
            background: 'button.mustard',
          },
        }}
        _checked={{
          '& span': {
            background: 'button.mustard',
            borderWidth: '0px',
            cursor: 'pointer',
          },
        }}
        disabled={isDisabled}
        onChange={chooseDifficulty}
        cursor={'pointer'}
      />

      <Text flex={1} fontSize={'14px'} m={'0'}>
        {level}
      </Text>
      <Box role={'star-box'} as={'span'} fontSize={'1.5rem'}>
        <Star active={false} />
        {stars === 2 && <Star active={false} />}
      </Box>
    </Flex>
  );
};

export default LessonDifficulty;
