import React from 'react';
import { Text } from '@chakra-ui/react';
import { useField } from 'formik';
import { createListCollection } from '@chakra-ui/react';

import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from 'components/ui/select';
import { Field } from 'components/ui/field';

interface FormSelectProps {
  name: string;
  value: string | number;
  label?: string;
  helperText?: string;
  options?: string[] | number[];
  width?: string;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  value,
  helperText,
  options,
  ...rest
}) => {
  const [field] = useField(name);
  const collection = createListCollection({
    items: options?.map((item) => {
      return {
        label: item,
        value: item,
      };
    }),
  });

  const handleSelectionChange = (e: any) => {
    field.onChange({
      target: {
        name: name,
        value: e.value[0],
      },
    });
  };
  return (
    <Field invalid={!!helperText} errorText={helperText} gap={1}>
      <Text
        position={'absolute'}
        top={'-24px'}
        fontSize='sm'
        textTransform={'uppercase'}
        m={0}
      >
        {label}
      </Text>
      <SelectRoot
        colorPalette='brand'
        collection={collection}
        width='320px'
        size='md'
        variant={'outline'}
        backgroundColor='#ffffffa6'
        color='button.200'
        onValueChange={(e: any) => handleSelectionChange(e)}
        borderRadius='5px'
        defaultValue={[value]}
        positioning={{ placement: 'bottom', flip: false }}
        css={{
          '& button[data-state="open"]': {
            borderColor: '#63b3ed',
          },
          '& button[data-state="closed"]': {
            borderColor: 'transparent',
          },
        }}
        {...rest}
      >
        <SelectTrigger>
          <SelectValueText placeholder={label} />
        </SelectTrigger>
        <SelectContent backgroundColor={'#2d4dac'}>
          {collection.items.map((movie: any) => (
            <SelectItem item={movie} key={movie.value}>
              {movie.label}
            </SelectItem>
          ))}
        </SelectContent>
      </SelectRoot>
    </Field>
  );
};

export default FormSelect;
