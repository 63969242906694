import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist';
import LogRocket from 'logrocket';
import { persistStore } from 'reduxjs-toolkit-persist';
import { rootReducer } from 'app/rootReducer';
import { api, ai_api } from './services/api';

// import tokenExpirationMiddleware from 'app/tokenExpirationMiddleware';

const store = configureStore({
  reducer: rootReducer,
  middleware: (gDM) =>
    gDM({
      serializableCheck: {
        ignoredPaths: ['ai_api.queries'],
        // ignore persistance actions
        // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }).concat(api.middleware, ai_api.middleware, LogRocket.reduxMiddleware()),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

// hot-reloading for the root reducer in dev mode
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export { persistor, store };
