import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Link } from '@chakra-ui/react';
import { Avatar } from 'components/ui/avatar';
import { useNavLogic } from 'hooks/useNavLogic';
import { UserProfile } from 'features/userProfile/userSlice';
import { INavItem } from 'components/nav/Header';
// import {
//   useLazyGetStudentQuestItemsQuery,
//   useTagStudentQuestItemMutation,
// } from 'app/services/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { FaExclamation } from 'react-icons/fa';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';
import { useActions } from 'hooks/useActions';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  navItems: INavItem[];
  userDetails: UserProfile;
  isOpen: boolean;
  onToggle: () => void;
}
const MotionBox = motion.create(Box);

const MobileNav: React.FC<Props> = ({ navItems, isOpen, onToggle }) => {
  const { urls, skinChoice } = useAppSelector((state) => state.user);
  const { checkIsComplete, handleClick } = useNavLogic();
  const { setSkinChoice } = useActions();

  return (
    <>
      <Box width={'64px'} height={'64px'} borderRadius={'50%'} zIndex={30}>
        {!checkIsComplete('profile_pic') && <FaExclamation color='gold' />}
        <Avatar
          size={'lg'}
          cursor={'pointer'}
          width={'64px'}
          height={'64px'}
          aria-label={'Toggle Menu'}
          zIndex={30}
          onClick={() => {
            onToggle();
            handleClick('profile_pic');
          }}
          src={
            urls.thumbnail +
            getThumbnailFilename(skinChoice.filename.split('/').pop() as string)
          }
          border={'2px solid #fff'}
        />
      </Box>

      <AnimatePresence>
        {isOpen && (
          <MotionBox
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '100%', opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            style={{
              position: 'fixed',
              inset: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            }}
          >
            <Flex
              justify='start'
              align='top'
              height='100vh'
              color='white'
              fontSize='2xl'
              fontWeight='bold'
              bgColor={'background.800'}
              bgGradient='to-b'
              gradientFrom={'background.100'}
              gradientTo={'background.800'}
              px='2rem'
            >
              <Box mt={'8rem'} width={'100%'}>
                {navItems.map((navItem, index) => {
                  const isExternalUrl =
                    navItem.href.toString().indexOf('http') !== -1;

                  return (
                    <React.Fragment key={navItem.label}>
                      {index !== 0 && navItem.topDivider && (
                        <Box
                          my={2}
                          height={'0.5px'}
                          width={'100%'}
                          bg={'whiteAlpha.300'}
                        />
                      )}
                      <Flex
                        onClick={() => handleClick(navItem.label)}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        {isExternalUrl ? (
                          <Link
                            color={'button.200'}
                            href={navItem.href.toString()}
                            target='_blank'
                            rel='noreferrer'
                            style={{ textDecoration: 'none' }}
                            css={{
                              _focusVisible: {
                                outline: 'none',
                              },
                            }}
                          >
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              p={'0.5rem 0'}
                              color={'white'}
                              _hover={{
                                textDecoration: 'underline',
                              }}
                              fontSize={'18px'}
                              fontWeight={'300'}
                            >
                              {navItem.label}
                            </Box>
                          </Link>
                        ) : (
                          <RouterLink
                            to={navItem.href}
                            onClick={() => {
                              if (
                                navItem.label === 'Choose User' ||
                                navItem.label === 'Log out'
                              ) {
                                setSkinChoice(null);
                              }
                            }}
                          >
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              p={'0.5rem 0'}
                              color={'white'}
                              _hover={{
                                textDecoration: 'underline',
                              }}
                              fontSize={'18px'}
                              fontWeight={'300'}
                            >
                              {navItem.label}
                            </Box>
                          </RouterLink>
                        )}
                        {!checkIsComplete(navItem.label) && (
                          <FaExclamation size={'18px'} color='gold' />
                        )}
                      </Flex>
                    </React.Fragment>
                  );
                })}
              </Box>
            </Flex>
          </MotionBox>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileNav;
