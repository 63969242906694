import React from 'react';
import { Outlet } from 'react-router';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Main from 'layout/Main';
import Header from 'components/nav/Header';
import { useBackground } from 'hooks/useBackground';

const MotionBox = motion.create(Box);

interface Props {
  showHeader?: boolean;
  gradientBackground?: boolean;
  children?: React.ReactNode;
}

/**
 * Dashboard Layout containing the header and main content
 * @param {boolean} [showHeader] - Show the header (optional)
 * @param {boolean} [gradientBackground] - Use gradient background (optional)
 * @returns {React.FC}
 */

const DashboardLayout: React.FC<Props> = ({
  showHeader = true,
  gradientBackground = false,
  children,
}) => {
  const background = useBackground();
  const backgroundStyle =
    background && !gradientBackground
      ? { backgroundImage: `url(${background})` }
      : {};

  return (
    <>
      {showHeader && <Header backgroundStyle={backgroundStyle} />}
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
        style={backgroundStyle}
      ></Box>
      <Main textAlign={'center'}>
        <Box
          h={'100%'}
          w={'100%'}
          mt={['1rem', '1rem', '2rem']}
          mb={'2rem'}
          transition={'margin-top 0.4s ease-in-out'}
        >
          <MotionBox
            initial={{ opacity: 0, y: 300 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: 'linear' }}
          >
            {children}
            <Outlet />
          </MotionBox>
        </Box>
      </Main>
    </>
  );
};

export default DashboardLayout;
