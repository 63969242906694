import { Text, Stack, Box, Image } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';
import { useNavigate } from 'react-router';
import profileEdit from 'assets/profile-edit.svg';
import ProfileAvatar from 'components/auth/ProfileAvatar';
import { Skin } from 'app/services/api';
import { useActions } from 'hooks/useActions';

interface Props {
  userName?: string;
  isStudent: boolean;
  studentId?: string;
  year?: number;
  skinChoice: Skin;
  mode: string;
}

export default function ChooseUserAvatar(props: Props): JSX.Element {
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { setSkinChoice } = useActions();

  const handleSelection = () => {
    let url = '/account/enter-pin/';
    if (props.isStudent) {
      url += props.studentId;
      setSkinChoice(props.skinChoice);
    } else {
      setSkinChoice(null);
      url += 'guardian';
    }
    navigate(url, {
      state: {
        userName: props.isStudent ? props.userName : auth.user.name,
        mode: props.mode,
        year: props.year,
      },
    });
  };

  return (
    <>
      <Stack gap={5} alignItems={'center'}>
        <Box position={'relative'} role='group'>
          <ProfileAvatar
            src={
              user.urls.thumbnail +
              getThumbnailFilename(
                props.skinChoice === null
                  ? user.skinChoice?.filename?.split('/').pop() || ''
                  : props.skinChoice?.filename?.split('/').pop() || '',
              )
            }
            onClick={() => handleSelection()}
          />
          {props.mode === 'edit' && (
            <Box
              boxSize='8rem'
              position='absolute'
              top='0'
              left='0'
              backgroundColor='rgba(0,0,0,0.2)'
              borderRadius='full'
              cursor='pointer'
              display='flex'
              alignItems='center'
              justifyContent='center'
              onClick={() => handleSelection()}
              _hover={{
                '& img': {
                  transform: 'scale(1.3)',
                },
              }}
            >
              <Image
                boxSize='2rem'
                objectFit='cover'
                src={profileEdit}
                alt='edit'
                transition='transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955)'
              />
            </Box>
          )}
        </Box>

        <Stack gap={2}>
          <Text fontSize='xl' fontWeight='bold' m={0}>
            {props.isStudent ? props.userName : auth.user.name}
          </Text>
          <Text
            fontSize='md'
            fontWeight='bold'
            color='rgba(249, 249, 249, 0.6)'
          >
            {props.isStudent ? 'STUDENT' : 'GUARDIAN'}
          </Text>
        </Stack>
      </Stack>
    </>
  );
}
