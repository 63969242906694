import { ChatSession } from './components/types';
export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export interface SeperatorPositions {
  index: number;
  title: string;
}

/**
 * Given an array of chat sessions sorted in descending order (most recent first),
 * returns an array of objects indicating at which index to insert a header separator,
 * and what the header title should be.
 *
 * @param {Array} sessions - Array of chat session objects with a 'date' property.
 * @returns {Array} An array of { index, title } objects.
 */
export const getSeparatorPositions = (
  sessions: ChatSession[],
): SeperatorPositions[] => {
  const positions: SeperatorPositions[] = [];
  const now = new Date();

  // Get today's date as a string "YYYY-MM-DD"
  const todayStr = now.toISOString().slice(0, 10);

  let lastGroup: string | null = null; // will hold "today", "last7", or a month-year string

  sessions.forEach((session: ChatSession, index: number) => {
    // Parse the session's date (the date string may include timezone info)
    const sessionDate = new Date(session.date);
    // Convert session date to "YYYY-MM-DD"
    const sessionDateStr = sessionDate.toISOString().slice(0, 10);

    // Calculate the difference in days between now and sessionDate
    const diffInMs = now.getTime() - sessionDate.getTime();
    const diffInDays = diffInMs / (1000 * 3600 * 24);

    let currentGroup;

    if (sessionDateStr === todayStr) {
      // Session happened today.
      currentGroup = 'today';
    } else if (diffInDays < 7) {
      // Session happened within the last 7 days (but not today).
      currentGroup = 'last7';
    } else {
      // For older sessions, group by month and year.
      // For example: 'January 2025'
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        year: 'numeric',
      };
      currentGroup = sessionDate.toLocaleDateString('default', options);
    }

    // When the current session belongs to a new group, record its index and header title.
    if (currentGroup !== lastGroup) {
      let title;
      if (currentGroup === 'today') {
        title = 'Today';
      } else if (currentGroup === 'last7') {
        title = 'Last 7 days';
      } else {
        title = currentGroup; // e.g., "January 2025"
      }

      positions.push({ index, title });
      lastGroup = currentGroup;
    }
  });

  return positions;
};

interface colorType {
  r: number;
  g: number;
  b: number;
}

// Helper function to linearly interpolate between two colors.
// Each color is an object with r, g, b properties.
// 't' is a number between 0 and 1.
function interpolateColor(color1: colorType, color2: colorType, t: number) {
  const r = Math.round(color1.r + (color2.r - color1.r) * t);
  const g = Math.round(color1.g + (color2.g - color1.g) * t);
  const b = Math.round(color1.b + (color2.b - color1.b) * t);
  return `rgb(${r}, ${g}, ${b})`;
}

/**
 * Returns a color that interpolates between green (min), amber (midpoint) and red (max)
 * based on a given current value and maximum.
 *
 * @param {number} value - The current value.
 * @param {number} max - The maximum value.
 * @returns {string} A CSS rgb() string representing the color.
 */
export const getColorForValue = (value: number, max: number) => {
  // Define the colors in RGB format.
  const green = { r: 0, g: 255, b: 0 }; // At min (0)
  const amber = { r: 255, g: 191, b: 0 }; // At midpoint
  const red = { r: 255, g: 0, b: 0 }; // At max

  // Clamp the value between 0 and max.
  if (value <= 0) return `rgb(${green.r}, ${green.g}, ${green.b})`;
  if (value >= max) return `rgb(${red.r}, ${red.g}, ${red.b})`;

  const midpoint = max / 2;

  // If the value is between 0 and midpoint, interpolate from green to amber.
  if (value <= midpoint) {
    // t is 0 at value=0 (green) and 1 at value=midpoint (amber)
    const t = value / midpoint;
    return interpolateColor(green, amber, t);
  }
  // If the value is between midpoint and max, interpolate from amber to red.
  else {
    // t is 0 at value=midpoint (amber) and 1 at value=max (red)
    const t = (value - midpoint) / (max - midpoint);
    return interpolateColor(amber, red, t);
  }
};
