import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  PopoverCloseTrigger,
} from 'components/ui/popover';
import { FaCopy } from 'react-icons/fa';

interface Props {
  copy: string;
  placement: 'bottom';
  content: string;
  copied: string;
  defaultIsOpen: boolean;
}

const CopyToolTip: React.FC<Props> = ({
  defaultIsOpen,
  copy,
  content,
  copied,
  placement,
}) => {
  const [showContent, setShowContent] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copy).then(() => {
      setShowContent(false);
    });
  };

  const handleMouseEnter = () => {
    setShowContent(true);
  };

  const handleMouseLeave = () => {
    setShowContent(false);
  };

  return (
    <PopoverRoot
      defaultOpen={defaultIsOpen}
      positioning={{ placement: placement }}
    >
      <PopoverTrigger asChild>
        <Box
          position='relative'
          cursor={'pointer'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <FaCopy size={22} fontSize={'6xl'} onClick={copyToClipboard} />
        </Box>
      </PopoverTrigger>

      <PopoverContent
        pr={'30px'}
        css={{ '--popover-bg': 'white' }}
        marginBlockEnd={3}
        marginBlockStart={4}
      >
        <PopoverArrow
          css={{
            '--arrow-color': 'white',
            '--arrow-background': 'white',
            '& > div': { borderTopWidth: '0px', borderStartWidth: '0px' },
          }}
        />

        <PopoverBody p={'5px 10px'} color={'brand.500'}>
          <Box>
            {showContent && (
              <Text
                mb={0}
                mt={0}
                textAlign={'left'}
                color='#1C50B4'
                fontWeight='normal'
                fontSize={15}
                whiteSpace='pre-line'
                wordWrap='break-word'
              >
                {content}
              </Text>
            )}
            {!showContent && (
              <Text
                mb={0}
                mt={0}
                textAlign={'left'}
                color='#1C50B4'
                fontWeight='normal'
                fontSize={15}
                whiteSpace='pre-line'
                wordWrap='break-word'
              >
                {copied}
              </Text>
            )}
            {/* <Text textAlign={'center'}>{CopyToolTip}</Text> */}
          </Box>
        </PopoverBody>
        <PopoverCloseTrigger
          backgroundColor='brand.300'
          minWidth={6}
          height={6}
          display={'flex'}
          alignItems={'center'}
          margin={'0.2rem auto'}
          _hover={{ backgroundColor: 'brand.400' }}
        />
      </PopoverContent>
    </PopoverRoot>
  );
};

export default CopyToolTip;
