import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { getRandomInt } from '../features/AiTeacher/utils';
import Main from 'layout/Main';
import Header from 'components/nav/Header';

const one = 'https://cdn.valearnis.com/backgrounds/ai/acient-ruins.svg';
const two = 'https://cdn.valearnis.com/backgrounds/ai/mount-fuji.png';
const three = 'https://cdn.valearnis.com/backgrounds/ai/natural.svg';
const four = 'https://cdn.valearnis.com/backgrounds/ai/tokyotower.png';

const backgrounds = [one, two, three, four];

const AITeacherLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [backgroundStyle, setBackgroundStyle] = useState<React.CSSProperties>(
    {},
  );

  // useEffect(() => {
  //   const background = backgrounds[getRandomInt(0, 3)];
  //   setBackgroundStyle(
  //     background ? { backgroundImage: `url(${background})` } : {},
  //   );
  // }, []);

  // const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const background = backgrounds[getRandomInt(0, 3)];
    const img = new Image();
    img.src = background; // Replace with your image path
    img.onload = () => {
      setTimeout(() => {
        setBackgroundStyle(
          background ? { backgroundImage: `url(${background})` } : {},
        );
      }, 300); // Delay of 0.5 seconds
    };
  }, []);
  // console.log('Image loading status', isLoading);
  return (
    <>
      <Header backgroundStyle={backgroundStyle} />
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
        style={backgroundStyle}
      ></Box>
      <Main textAlign={'center'}>
        <Box
          h={'inherit'}
          w={'100%'}
          mt={[0]}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          transition={'margin-top 0.5s ease-in-out'}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'linear' }}
          >
            {children}
            <Outlet />
          </motion.div>
        </Box>
      </Main>
    </>
  );
};

export default AITeacherLayout;
