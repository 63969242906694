import React from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';
import ButtonLink from 'components/buttons/ButtonLink';

const txtStyle = {
  fontSize: ['1rem', '1.3rem'],
  m: '20px',
};

function getRandomGreeting(): string {
  const greetings = [
    'How would you like to learn today?',
    'Welcome to Valearnis!',
    "Let's learn together!",
    'Hope you learn something new!',
    'What interests you today?',
  ];

  return greetings[Math.floor(Math.random() * greetings.length)];
}

const Dashboard: React.FC = () => {
  const randomGreeting = getRandomGreeting();
  return (
    <Box mt={['2rem', '6rem', '8rem']} px={['1rem', '2rem']}>
      <Heading
        as={'h1'}
        fontSize={['2rem', '3rem']}
        mb={'5rem'}
        mt={'1rem'}
        lineHeight={1.2}
      >
        {randomGreeting}
      </Heading>
      <Box
        display={'grid'}
        gridTemplateColumns={['1fr', null, '1fr 1fr']}
        maxW={[null, '22rem', '720px']}
        m={'0 auto'}
        px={['2rem', '0']}
        gap={[2, 2, 4]}
      >
        <ButtonLink to='/ai-teacher' link_variant='tab'>
          <Image
            src={'/assets/icons/VirtualTeacher.svg'}
            alt='Virtual Teacher'
            w={'50px'}
          />
          <Text {...txtStyle}>Virtual Teacher</Text>
        </ButtonLink>
        <ButtonLink to='/curriculum' link_variant='tab'>
          <Image
            src={'/assets/icons/Lessons.svg'}
            alt='Lessons'
            maxH={'60px'}
          />
          <Text {...txtStyle}>Lessons</Text>
        </ButtonLink>
      </Box>
    </Box>
  );
};

export default Dashboard;
