import React from 'react';
import { Outlet, useOutlet } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import Main from 'layout/Main';
import Header from 'components/nav/Header';
import ButtonLink from 'components/buttons/ButtonLink';

const Homepage: React.FC = () => {
  const outletComponent = useOutlet();

  return (
    <>
      {process.env.REACT_APP_API_URL &&
        process.env.REACT_APP_API_URL.indexOf('//api.valearnis.com') === -1 && (
          <Helmet>
            <meta name='robots' content='noindex' />
          </Helmet>
        )}
      <Helmet>
        <title>Home - Valearnis</title>
      </Helmet>
      <Header />
      <Main
        color={'#FFF'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
      >
        <Box m={'0 auto'} pt={'8vh'}>
          {outletComponent ? (
            <Outlet />
          ) : (
            <Box maxW={'40rem'} m={'0 auto'} textAlign={'center'}>
              <Heading as='h1' fontSize={['3xl', '4xl']} pb={4}>
                Welcome to Valearnis
              </Heading>
              <Text>
                Welcome to the world of Valearnis! We&apos;re so excited for you
                to embark on your learning journey with us!
              </Text>
              <Text mt={12} fontWeight={600}>
                Please log in or sign up to continue
              </Text>
              <Flex flexFlow={'column'}>
                <ButtonLink to='/login' link_variant='button'>
                  Login
                </ButtonLink>
                <ButtonLink to='/register' link_variant='button'>
                  Sign Up
                </ButtonLink>
              </Flex>
            </Box>
          )}
        </Box>
      </Main>
    </>
  );
};

export default Homepage;
