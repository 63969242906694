import { Flex, Image, Text, Box } from '@chakra-ui/react';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa6';
import { useScreenSize } from 'hooks/useScreenSize';

const Portrait: React.FC = () => {
  const [characterIndex, setCharacterIndex] = useState(0);
  const { urls, tempSkinChoice, tempCharacterChoice, characters } =
    useAppSelector((state) => state.user);
  const { setTempCharacterChoice } = useActions();
  const isNarrowScreen = useScreenSize('xs');

  const getCharacterImage = () => {
    if (tempSkinChoice.character !== tempCharacterChoice.id) {
      return tempCharacterChoice.skins[0].filename;
    } else {
      return tempSkinChoice.filename;
    }
  };

  const getUnlockMessage = () => {
    if (tempCharacterChoice.unlock_condition.length === 1) {
      return tempCharacterChoice.unlock_condition[0];
    }
    let message = '';
    tempCharacterChoice.unlock_condition.forEach(
      (condition: string, index: number) => {
        if (index === 0) {
          message += condition.split('to')[0] + 'and ';
        } else {
          message += condition.toLowerCase();
        }
      },
    );
    return message;
  };

  return (
    <Flex
      position={'relative'}
      justifyContent={['center', 'initial']}
      alignItems={['center', 'initial']}
      h={'100%'}
      w={['100%', 'initial']}
      textAlign={'center'}
    >
      {isNarrowScreen && characterIndex > 0 && (
        <Box
          cursor={'pointer'}
          width={'28px'}
          height={'28px'}
          position={'absolute'}
          zIndex={9}
          right={'0'}
          borderRadius={'50%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => {
            if (characterIndex > 0) {
              setCharacterIndex(characterIndex - 1);
              setTempCharacterChoice(characters[characterIndex - 1]);
            }
          }}
          colorPalette={'brand'}
          transition='all 0.2s ease-in-out'
          _hover={{
            background: 'brand.600',
            scale: 1.2,
            transition: 'all 0.2s ease-in-out',
          }}
        >
          <FaChevronRight />
        </Box>
      )}
      <Image
        height={'100%'}
        opacity={!tempCharacterChoice.is_unlocked ? '0.5' : '1'}
        src={urls.characters + getCharacterImage()}
        m={'0 auto'}
      />
      {!tempCharacterChoice.is_unlocked && (
        <Text
          position={'absolute'}
          w={['80%', '100%']}
          bottom={['initial', '50%']}
        >
          {getUnlockMessage()}
        </Text>
      )}
      {isNarrowScreen && characterIndex < characters.length - 1 && (
        <Box
          cursor={'pointer'}
          width={'28px'}
          height={'28px'}
          position={'absolute'}
          zIndex={9}
          left={'0'}
          borderRadius={'50%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => {
            if (characterIndex < characters.length - 1) {
              setCharacterIndex(characterIndex + 1);
              setTempCharacterChoice(characters[characterIndex + 1]);
            }
          }}
          colorPalette={'brand'}
          transition='all 0.2s ease-in-out'
          _hover={{
            background: 'brand.600',
            scale: 1.2,
            transition: 'all 0.2s ease-in-out',
          }}
        >
          <FaChevronLeft />
        </Box>
      )}
    </Flex>
  );
};

export default Portrait;
