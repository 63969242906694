import React from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Image,
  useMediaQuery,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { setQuizProgress } from './FactionQuizSlice';
import { useDispatch } from 'react-redux';
import HouseFactions from './HouseFactions';

type factionPlacementOrder = {
  [key: string]: {
    order: { base: number; lg: number };
    colSpan?: { base?: number; lg?: number };
  };
};

const StartQuiz: React.FC = () => {
  const [isShortScreen] = useMediaQuery(['(max-height: 903px)'], {
    ssr: false,
  });
  const dispatch = useDispatch();
  const commenceQuizHandler = () => {
    dispatch(setQuizProgress(true));
  };
  const factionPlacementOrder: factionPlacementOrder = {
    Heartleaf: { order: { base: 1, lg: 1 }, colSpan: {} },
    Flamespire: { order: { base: 3, lg: 2 }, colSpan: { base: 2, lg: 1 } },
    Moonspark: { order: { base: 2, lg: 3 }, colSpan: {} },
  };
  return (
    <Box
      position={'relative'}
      maxW={['100%', '80%', '80%', '80%']}
      minH={['60vh', null, '50vh']}
      display={'flex'}
      flexDir='column'
      alignItems={'center'}
      justifyContent={'center'}
      textAlign={'center'}
      padding={isShortScreen ? '10px' : '40px 10px'}
      paddingTop={isShortScreen ? '24vh' : '40px'}
      mx='auto'
      w='100%'
    >
      <Heading fontSize={['1.3rem', '1.6rem', '1.8rem', '2.2rem']} m='0.5rem'>
        Welcome to Valearnis House Factions!
      </Heading>
      <Box w='80%'>
        <Text fontSize={['0.9rem', '1rem', '1.2rem']} mb={'1rem'}>
          To get sorted into your house faction you must complete the
          personality quiz!
        </Text>
      </Box>
      <Button
        onClick={commenceQuizHandler}
        mb={'2rem'}
        width='100px'
        fontWeight={'bold'}
      >
        Start
      </Button>
      <Grid
        templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap={4}
      >
        {Object.entries(factionPlacementOrder).map(([name, POrder]) => {
          const faction = HouseFactions.find(
            (faction) => faction.name === name,
          );
          const emblemImg = faction ? faction.emblemImg : '';
          return (
            <GridItem
              key={name}
              order={POrder.order}
              id={name}
              colSpan={POrder.colSpan}
            >
              <Flex justify={'center'}>
                <Image
                  display={['none', 'block', 'block']}
                  width={['170px', '200px', '200px', '270px']}
                  height={['170px', '200px', '200px', '270px']}
                  loading={'lazy'}
                  src={emblemImg}
                  objectFit='contain'
                />
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default StartQuiz;
