import { Box, Flex, Image, Heading, Button } from '@chakra-ui/react';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import ScrollBox from './ScrollBox';
import { IoMdClose } from 'react-icons/io';
import { useUpdateStudentSettingsMutation } from 'app/services/api';

const ThemeMenu: React.FC = () => {
  const [updateStudentSettings] = useUpdateStudentSettingsMutation();
  const {
    urls,
    tempCharacterChoice,
    tempSkinChoice,
    backgrounds,
    tempBg,
    studentId,
  } = useAppSelector((state) => state.user);
  const {
    setTempSkin,
    setTempBg,
    setAvatar,
    toggleSettingTheme,
    toggleSelectingAvatar,
  } = useActions();

  const getCharacterImage = () => {
    if (tempSkinChoice.character !== tempCharacterChoice.id) {
      return tempCharacterChoice.skins[0].filename;
    } else {
      return tempSkinChoice.filename;
    }
  };

  return (
    <Flex
      position={'absolute'}
      flexDir={['column', 'column', 'column', 'row']}
      top={'0'}
      left={'0'}
      w={['100%', '100%']}
      h={['100vh']}
    >
      <Box
        position={'absolute'}
        top={'112px'}
        right={['44px', '46px', '78px', '108px', '140px']}
        width={'34px'}
        height={'34px'}
        borderRadius={'50%'}
        zIndex={11}
        onClick={() => toggleSettingTheme()}
        _hover={{ cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.3)' }}
      >
        <IoMdClose size={'md'} />
      </Box>

      <Box
        h={['45vh', '50vh', '60vh', '100%']}
        position={'relative'}
        borderBottomRightRadius={['15px', '15px', '15px', 'initial']}
        zIndex={10}
      >
        <Image
          src={urls.backgrounds + tempBg}
          h={['45vh', '100%', '100%', '100%']}
          width={'100%'}
          borderBottomRightRadius={['15px', '15px', '15px', 'initial']}
          borderBottomLeftRadius={['15px', '15px', '15px', 'initial']}
        />
        <Image
          src={urls.characters + getCharacterImage()}
          position={'absolute'}
          left={'50%'}
          top={['62%', '62%', '62%', '50%']}
          h={'60%'}
          transform='translate(-50%, -50%)'
          m={'0 auto'}
        />
      </Box>

      <Flex
        flexDir={['column']}
        justifyContent={'center'}
        alignItems={'center'}
        w={['100%', '100%', '100%', '70%', '45%']}
        h={'100vh'}
      >
        <Flex
          flexDir={['column', 'column', 'row', 'column']}
          justifyContent={'space-around'}
          alignItems={'center'}
          width={['100%', '100%', '100%', 'initial']}
        >
          <Box mb={['20px', '30px']}>
            <Heading fontSize={'1.1rem'} mb={['10px', '20px']}>
              Select Skin
            </Heading>
            <Flex alignItems={'center'} gap={'8px'}>
              <ScrollBox total_skins={tempCharacterChoice.skins.length}>
                {tempCharacterChoice.skins.map((skin: any) => {
                  const selected = tempSkinChoice.id === skin.id;
                  return (
                    <Image
                      cursor={'pointer'}
                      borderRadius={'5px'}
                      w={['100px', '100px', '95px', '100px']}
                      height={'130px'}
                      key={skin.id}
                      src={urls.thumbnail + skin.thumbnail}
                      onClick={() => setTempSkin(skin)}
                      transition='all 0.2s'
                      border={
                        selected ? '2px solid white' : '2px solid transparent'
                      }
                    />
                  );
                })}
              </ScrollBox>
            </Flex>
          </Box>
          <Box mb={['20px', '30px']}>
            <Heading fontSize={'1.1rem'} mb={['10px', '20px']}>
              Select Background
            </Heading>
            <Flex alignItems={'center'} gap={'8px'}>
              <ScrollBox total_skins={backgrounds.length}>
                {backgrounds.map((background: any) => {
                  const selected = tempBg === background.filename;
                  return (
                    <Image
                      cursor={'pointer'}
                      borderRadius={'5px'}
                      w={['100px', '100px', '95px', '100px']}
                      height={'130px'}
                      key={background.id}
                      src={urls.backgrounds + background.filename}
                      onClick={() => setTempBg(background.filename)}
                      transition='all 0.2s'
                      border={
                        selected ? '2px solid white' : '2px solid transparent'
                      }
                    />
                  );
                })}
              </ScrollBox>
            </Flex>
          </Box>
        </Flex>
        <Button
          mt={['20px', '30px', '40px']}
          onClick={() => {
            setAvatar();
            const bg = backgrounds.find(
              (bg: any) => tempBg.split('/').pop() === bg.filename,
            );
            updateStudentSettings({
              student_id: studentId,
              app_user: {},
              character_skin: tempSkinChoice,
              background: bg,
              character: tempCharacterChoice,
            });
            toggleSelectingAvatar();
          }}
        >
          Confirm
        </Button>
      </Flex>
    </Flex>
  );
};

export default ThemeMenu;
