import React, { useEffect, useRef } from 'react';
import { Box, Heading, Flex, Text } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import ChooseUserAvatar from './ChooseUserAvatar';
import Main from 'layout/Main';
import ChooseUserAddProfile from './ChooseUserAddProfile';
import { IStudentListItem, useGetStudentsQuery } from 'app/services/api';

type ProfileSelectProps = {
  mode: string;
};

export default function ProfileSelect({
  mode,
}: ProfileSelectProps): JSX.Element {
  const { skinChoice } = useAppSelector((state) => state.user);
  const { data: studentsData, isSuccess, refetch } = useGetStudentsQuery();
  const flexRef = useRef(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (flexRef.current) {
      const children = (flexRef.current as HTMLDivElement).children;
      Array.from(children).forEach((child, index) => {
        (child as HTMLElement).style.animationDelay = `${index * 0.2}s`;
      });
    }
  }, []);

  return (
    <Main textAlign={'center'}>
      <Box w={'100%'} mb={'0'} mt={['6rem', '6rem', '4rem']}>
        <Heading fontSize={'2rem'} fontWeight='semibold'>
          {mode === 'select' ? "Who's using Valearnis?" : 'Edit Profiles'}
        </Heading>
        {mode === 'edit' && (
          <Text color='rgba(249, 249, 249, 0.9)'>Select a profile to edit</Text>
        )}
        <Flex
          ref={flexRef}
          justifyContent={'center'}
          alignItems={'flex-start'}
          flexWrap={'wrap'}
          style={{ gap: '2rem' }}
          width='100%'
          mt='4rem'
          className='fade-in-from-top'
        >
          <ChooseUserAvatar
            key={0}
            isStudent={false}
            skinChoice={skinChoice}
            mode={mode}
          />
          {isSuccess && studentsData && studentsData.error && (
            <Text>{studentsData.error}</Text>
          )}
          {isSuccess &&
            studentsData &&
            studentsData.payload &&
            Array.isArray(studentsData.payload) &&
            studentsData.payload.map((account: IStudentListItem) => (
              <ChooseUserAvatar
                key={account.id}
                userName={account.username}
                isStudent={true}
                year={account.year}
                studentId={account.id}
                skinChoice={account.skinChoice}
                mode={mode}
              />
            ))}
          <ChooseUserAddProfile />
        </Flex>
      </Box>
    </Main>
  );
}
