import React from 'react';
import { Box } from '@chakra-ui/react';

import { ConversationSidebar, ChatWindow } from './components';
import { SidebarProvider } from './components/SidebarContext';

const NewAITeacher: React.FC = () => {
  return (
    <>
      <Box
        maxW={['90%', null, '80%']}
        m={'0 auto'}
        bgColor={'brand.600'}
        bgGradient={'linear(#5A99F7, #4560C3, #243D9D)'}
        borderRadius={'10px'}
        maxH='75vh'
        height='75vh'
        width='100dvw'
        className='test'
      >
        <Box
          className='main-ai-teacher-box'
          width='100%'
          height='100%'
          margin='auto'
          display='flex'
          flexDir='row'
          overflow={'hidden'}
          borderRadius={'10px'}
          position='relative'
        >
          <SidebarProvider>
            <ConversationSidebar />
            <ChatWindow />
          </SidebarProvider>
        </Box>
      </Box>
    </>
  );
};

export default NewAITeacher;
