import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { IStudentListItem, useGetStudentsQuery } from 'app/services/api';
import { useAppSelector } from 'hooks/useAppSelector';
import LoadingCircle from 'components/icons/LoadingCircle';

const ManageStudents: React.FC = () => {
  const { accounts } = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {
    data: studentsData,
    isSuccess,
    isLoading,
    refetch,
  } = useGetStudentsQuery();
  useEffect(() => {
    refetch();
  }, []);

  const manageStudent = (account: IStudentListItem) => {
    navigate(account.username, {
      state: {
        id: account.id,
        is_active: account.is_active,
        name: account.name,
        username: account.username,
        year: account.year,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  const renderStudents = () => {
    if (isLoading) {
      return <LoadingCircle text='Loading your students' />;
    } else if (isSuccess && studentsData) {
      if (studentsData.error) {
        return <Text>{studentsData.error}</Text>;
      }

      if (studentsData.payload) {
        return studentsData &&
          studentsData.payload &&
          Array.isArray(studentsData.payload)
          ? studentsData.payload.map(
              (account: IStudentListItem, index: number) => {
                return (
                  <Box key={`${account.id}-${account.name}`} px={'10px'}>
                    <Flex align={'center'} justifyContent={'space-between'}>
                      <Text fontSize={'14px'} wordBreak={'break-all'}>
                        {account.name} ({account.username})
                      </Text>
                      <Button
                        onClick={() => manageStudent(account)}
                        h={'40px'}
                        minW={'100px'}
                        w={['100px', '120px']}
                        p={'10px'}
                        m={'0 10px'}
                        fontSize={['10px', '14px']}
                      >
                        Manage
                      </Button>
                    </Flex>
                    {index !== accounts.length - 1 && (
                      <Box
                        width={'100%'}
                        height={'0'}
                        borderTop={'1px'}
                        borderRadius={'10px'}
                        bgColor={'#fff'}
                        m={'30px auto'}
                      ></Box>
                    )}
                  </Box>
                );
              },
            )
          : null;
      }
    } else {
      throw new Error('Something went wrong fetching student data');
    }
  };

  return (
    <Box height={'90%'}>
      <Box height={'100%'} overflowX={'auto'}>
        {renderStudents()}
      </Box>
    </Box>
  );
};

export default ManageStudents;
