import { Box, Button } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import ForgotPinForm from 'components/auth/ForgotPinForm';

export default function ForgotPin(): JSX.Element {
  const { studentId } = useParams<{ studentId: string }>();
  const navigate = useNavigate();

  function redirectToChooseUser() {
    navigate(-1);
  }

  return (
    <>
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
      ></Box>
      <Button
        position='fixed'
        top='0'
        right='0'
        m='16px'
        onClick={() => redirectToChooseUser()}
      >
        CANCEL
      </Button>
      <ForgotPinForm studentId={studentId} />
    </>
  );
}
