import { AnimatePresence, motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';
const MotionBox = motion.create(Box);

const SidebarCollapseButton = () => {
  const { sideBarCollapsed } = useAppSelector((state) => state.ai);
  const { toggleSidebarState } = useActions();
  return (
    <AnimatePresence>
      {sideBarCollapsed && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            ease: [0.6, -0.05, 0.01, 0.99], // Custom easing function for a smoother transition
          }}
        >
          <MotionBox
            position={'absolute'}
            left={'16px'}
            top={'24px'}
            className='sidebar-hamburger'
            onClick={() => toggleSidebarState()}
            cursor='pointer'
            fontSize='2xl'
            color='white'
            initial={{ rotate: -90 }}
            animate={{ rotate: sideBarCollapsed ? 0 : 180 }}
            transition={{ type: 'spring' }}
            _hover={{
              transform: 'scale(1.1)',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            <FaArrowRight />
          </MotionBox>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default SidebarCollapseButton;
