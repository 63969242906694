import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { useDivHeight } from 'hooks/useNavHeight';

const Main: React.FC<FlexProps> = ({ children, ...rest }) => {
  const navHeight = useDivHeight('header');
  return (
    <Flex
      as={'main'}
      id={'main'}
      flexFlow={'row'}
      minH={'100vh'}
      pt={navHeight === 0 ? ['5rem', null, '8rem'] : `${navHeight}px`}
      color={'#FFF'}
      transition={'padding-top 0.4s ease-in-out'}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default Main;
