import React from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
  choice: string;
  isSelected: boolean;
  children: React.ReactNode;
}

const PictureChoice: React.FC<Props> = (props) => {
  return (
    <Box
      role={'choice'}
      as={'label'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box
        id={'choice' + props.choice}
        textAlign={'center'}
        position={'relative'}
        shadow={props.isSelected ? '1px 1px 12px' : ''}
        transition={'all 0.3s'}
        borderRadius={'10px'}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default PictureChoice;
