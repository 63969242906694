import React, { useEffect } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { Radio, RadioGroup } from 'components/ui/radio';
import DOMPurify from 'dompurify';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { renderMaths } from 'utils/katexUtils';
import { TransformedOption } from 'features/questions/questionsSlice';

interface Props {
  options: TransformedOption[];
}

const SingleChoiceOptions: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();

  useEffect(() => {
    renderMaths(document.getElementsByTagName('div'));
  }, [options]);

  return (
    <RadioGroup
      value={userAnswer && userAnswer[0]}
      onValueChange={(e: any) => selectAnswer([e.value])}
    >
      <VStack gap='3' align={'start'}>
        {options.map((option: TransformedOption, index: number) => {
          return (
            <Radio
              key={index}
              value={option.id}
              id={option.id}
              css={{
                '& > span:first-of-type': {
                  width: '12px',
                  height: '12px',
                  background: 'white',
                  borderWidth: '0px',
                },
                '& > span:first-of-type > .dot': {
                  background: 'button.mustard',
                },
              }}
              _checked={{
                '& > span:first-of-type': {
                  background: 'button.mustard',
                  borderWidth: '0px',
                },
              }}
            >
              <Box
                cursor={'pointer'}
                // Set 0 margins on `p` elements to prevent overflow
                css={{ '& p': { margin: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(option.content),
                }}
              />
            </Radio>
          );
        })}
      </VStack>
    </RadioGroup>
  );
};

export default SingleChoiceOptions;
