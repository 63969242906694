import React from 'react';
import { IconButton, PopoverFooter } from '@chakra-ui/react';
import { GoQuestion } from 'react-icons/go';
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  PopoverCloseTrigger,
} from 'components/ui/popover';

interface Props {
  content: string;
  align: string;
  defaultIsOpen: boolean;
  footer?: string;
}
const InstructionsTooltip: React.FC<Props> = ({
  defaultIsOpen,
  content,
  align,
  footer,
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  return (
    <PopoverRoot
      onOpenChange={(e: any) => setPopoverOpen(e.open)}
      open={popoverOpen}
      defaultOpen={defaultIsOpen}
      positioning={{ placement: align }}
      trigger='hover'
    >
      <PopoverTrigger asChild>
        <IconButton
          aria-label='Instructions'
          size='xs'
          variant='subtle'
          backgroundColor={'transparent'}
          borderRadius={'25px'}
          margin={'0px'}
          display={'flex'}
          alignItems={'center'}
          onMouseEnter={() => setPopoverOpen(true)}
        >
          <GoQuestion style={{ width: '1.4rem', height: '1.4rem' }} />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent pr={'30px'} backgroundColor='white'>
        <PopoverArrow
          css={{
            '--arrow-color': 'white',
            '--arrow-background': 'white',
            '& > div': { borderTopWidth: '0px', borderStartWidth: '0px' },
          }}
        />
        <PopoverBody p={'5px 10px'} color={'brand.500'}>
          {content}
        </PopoverBody>
        {footer && (
          <PopoverFooter p={'5px 10px'} color={'brand.500'}>
            {footer}
          </PopoverFooter>
        )}
        <PopoverCloseTrigger
          backgroundColor='brand.300'
          minWidth={6}
          height={6}
          display={'flex'}
          alignItems={'center'}
          margin={'0.2rem auto'}
          _hover={{ backgroundColor: 'brand.400' }}
        />
      </PopoverContent>
    </PopoverRoot>
  );
};

export default InstructionsTooltip;
