import { Box, useMediaQuery } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { PiNotePencilLight } from 'react-icons/pi';
import { useActions } from 'hooks/useActions';

const NewChatButton = () => {
  const { setNewChatSession, toggleSidebarState } = useActions();
  const [isLg] = useMediaQuery(['(max-width: 1023px)'], {
    ssr: false,
  });
  const handleNewChat = () => {
    console.log('New Chat');
    setNewChatSession();
    if (isLg) {
      toggleSidebarState();
    }
  };
  return (
    <Box
      justifyContent={'flex-end'}
      display={'flex'}
      pt={'8px'}
      ml={'16px'}
      mr={'14px'}
      pb={'8px'}
      borderBottom={'1px solid'}
      borderColor={'brand.600'}
    >
      <Box onClick={() => handleNewChat()} cursor='pointer'>
        <Tooltip
          content='New Chat'
          openDelay={50}
          closeDelay={50}
          placement='top'
          positioning={{ placement: 'right' }}
        >
          <PiNotePencilLight size={24} />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default NewChatButton;
