import React, { useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { Box, Text, Image, Button, GridItem } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setChosenFaction, joinHouseFaction } from './FactionQuizSlice';
import { useUpdateStudentHouseMutation } from 'app/services/api';
import { useActions } from 'hooks/useActions';
import { useNavigate } from 'react-router';
import useStudentId from 'hooks/useStudentId';
import { Tooltip } from 'components/ui/tooltip';
import { FaArrowLeft } from 'react-icons/fa';

type FactionCardProps = {
  [key: string]: {
    [key: string]: object;
  };
};

const factionCardProps: FactionCardProps = {
  Flamespire: {
    left: {
      width: '53px',
      height: '255px',
      left: '-28px',
      top: '-44px',
    },
    right: {
      width: '53px',
      height: '255px',
      right: '-28px',
      top: '-44px',
      transform: 'scaleX(-1)',
    },
  },
  Heartleaf: {
    left: {
      width: '46px',
      height: '290px',
      left: '-38px',
      top: '-62px',
    },
    right: {
      width: '46px',
      height: '290px',
      right: '-38px',
      top: '-62px',
      transform: 'scaleX(-1)',
    },
  },
  Moonspark: {
    left: {
      width: '60px',
      height: '290px',
      left: '-46px',
      top: '-64px',
    },
    right: {
      width: '60px',
      height: '290px',
      right: '-46px',
      top: '-64px',
      transform: 'scaleX(-1)',
    },
  },
};

type factionDetail = {
  name: string;
  description: string;
  borderImg: string;
  emblemImg: string;
  color: string;
};

interface FactionProps {
  faction: factionDetail;
  updateHouseHandler: (result: boolean) => void;
}
const FactionCard: React.FC<FactionProps> = ({
  faction,
  updateHouseHandler,
}) => {
  const [openedState, setOpenedState] = useState<string | null>(null);
  const { recommendedFaction, selectedFaction } = useAppSelector(
    (state) => state.faction,
  );
  const studentId = useStudentId();
  const { addNotification } = useActions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateStudentHouse] = useUpdateStudentHouseMutation();
  const { name, description, borderImg, emblemImg, color } = faction;
  const { left, right } = factionCardProps[name];

  const selectFactionHandler = (faction: string) => {
    dispatch(setChosenFaction(faction));
  };

  const joinFaction = async (faction: string) => {
    const updatedStudent = {
      app_user: {},
      house: faction,
      student_id: studentId,
    };

    const result = await updateStudentHouse(updatedStudent).unwrap();
    if ('error' in result) {
      updateHouseHandler(false);
      setOpenedState(null);
      selectFactionHandler('');
    }
    selectFactionHandler(faction);
    dispatch(joinHouseFaction(faction));
    addNotification('Student House updated');
    navigate('/house-faction', {
      replace: true,
      state: {
        message: 'User updated',
      },
    });
  };

  return (
    <GridItem
      id={name}
      justifyContent={'center'}
      position={'relative'}
      display={
        selectedFaction === '' || selectedFaction === name ? 'flex' : 'none'
      }
      flexDir={'column'}
      alignItems={'center'}
    >
      <Box
        key={name}
        height={selectedFaction === name ? ['420px'] : ['370px']}
        width={
          selectedFaction === name
            ? ['240px', '300px', '350px']
            : ['270px', '270px', '300px', '240px', '240px']
        }
        transition={' 0.3s '}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'end'}
        alignContent={'center'}
        bg='#1a5b92'
        borderRadius={'20px'}
        borderTop={`8px solid ${color}`}
        boxShadow={`${recommendedFaction === name ? '0 0 20px yellow;' : null} `}
      >
        <Box
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          p={2}
        >
          <Text textStyle='xl'>{name}</Text>
          <Text
            lineHeight={'1.4'}
            lineClamp={selectedFaction === name ? 10 : 5}
            px={'1rem'}
            transition={' 0.3s '}
            fontSize={selectedFaction === name ? '14px' : '12px'}
          >
            {description}
          </Text>
        </Box>
        {openedState === name && (
          <Button
            onClick={() => joinFaction(name)}
            fontSize='1rem'
            fontWeight='bolder'
            borderTopLeftRadius='0px'
            borderTopRightRadius='0px'
            borderBottomLeftRadius='20px'
            borderBottomRightRadius='20px'
            backgroundColor='#0096c7'
            width='100%'
            height='50px'
            transition='0.3s'
            margin='0'
            css={{
              '&:hover': {
                backgroundColor: '#10b4e9',
              },
            }}
          >
            Join Faction
          </Button>
        )}
        {openedState !== name && (
          <Button
            onClick={() => {
              setOpenedState(name);
              selectFactionHandler(name);
            }}
            fontSize='1rem'
            fontWeight='bolder'
            borderTopLeftRadius='0px'
            borderTopRightRadius='0px'
            borderBottomLeftRadius='20px'
            borderBottomRightRadius='20px'
            backgroundColor='#0096c7'
            width='100%'
            height='50px'
            transition='0.3s'
            margin='0'
            css={{
              '&:hover': {
                backgroundColor: '#10b4e9',
              },
            }}
          >
            {`See ${name} faction`}
          </Button>
        )}
      </Box>
      <Image
        position={'absolute'}
        objectFit='contain'
        src={borderImg}
        {...left}
      />
      <Image
        position={'absolute'}
        objectFit='contain'
        src={borderImg}
        {...right}
      />
      <Image
        position='absolute'
        src={emblemImg}
        objectFit={'cover'}
        width={'120px'}
        height={'150px'}
        top={'-50px'}
        borderRadius={'50px'}
      />
      {openedState === name && (
        <>
          <Tooltip content='Go Back' openDelay={100} closeDelay={0}>
            <Button
              fontSize={'12px'}
              size={'sm'}
              backgroundColor={'#0096c7'}
              onClick={() => {
                setOpenedState(null);
                selectFactionHandler('');
              }}
              transition={' 0.3s '}
              _hover={{
                backgroundColor: '#10b4e9',
              }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100px'}
            >
              <FaArrowLeft width={'50px'} height={'50px'} />
            </Button>
          </Tooltip>
        </>
      )}
    </GridItem>
  );
};

export default FactionCard;
