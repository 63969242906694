import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';
import { useKatex } from 'hooks/useKatex';

import Star from 'features/lessons/Star';

const QuizTitle: React.FC = () => {
  const { difficulty, lessonName } = useAppSelector((state) => state.questions);
  const ele = React.createRef<HTMLDivElement>();

  useKatex(ele);

  return (
    <Heading
      ref={ele}
      fontWeight={'bold'}
      lineHeight={'27px'}
      fontSize={'20px'}
      letterSpacing={'1.6px'}
    >
      {lessonName}
      <Box role={'star-box'} as={'span'} ml={'15px'} fontSize={'1.2rem'}>
        <Star active={true} />
        {difficulty === 'Challenging' && <Star active={true} />}
      </Box>
    </Heading>
  );
};

export default QuizTitle;
