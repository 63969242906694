import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useLazyGetPreviewLessonsQuery } from 'app/services/api';
import { useActions } from 'hooks/useActions';
import useStudentId from 'hooks/useStudentId';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  subject: string;
  description: string;
  topics: string[];
  topicDescriptions: { [key: string]: string };
  setSubject: React.Dispatch<React.SetStateAction<string | null>>;
  icons: { [key: string]: string };
}

const SubjectSummary: React.FC<Props> = ({
  description,
  topics,
  topicDescriptions,
  subject,
  icons,
  setSubject,
}) => {
  const navigate = useNavigate();
  const { setQuizInProgress } = useActions();
  const [getLesson] = useLazyGetPreviewLessonsQuery();
  const studentId = useStudentId();
  // console.log(description, topics, topicDescriptions);
  return (
    <Flex
      flexDir={'column'}
      justifyContent={['center', 'left']}
      alignItems={'center'}
      w={['100%', '90%']}
      h={['100%', '90%']}
      overflow={'auto'}
      mt={'15px'}
      mb={['20px', 'none']}
      p={'0 20px'}
      bg={['brand.800', 'none']}
      borderRadius={['15px', 'none']}
    >
      <Image
        w={'100%'}
        mt={['20px', 'none']}
        src={'https://manezco.sfo3.digitaloceanspaces.com/preview/banner.png'}
      />
      <Heading mt={'50px'} fontWeight={'600'}>
        {subject}
      </Heading>
      <Text fontSize={'0.9rem'} mb={'25px'}>
        {description}
      </Text>
      {topics.map((topic) => (
        <Flex
          key={topic}
          mb={'20px'}
          mt={['20px', 'none']}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'95%'}
          flexDirection={['column', 'row']}
        >
          <Flex
            alignItems={'center'}
            w={['90%', 'none']}
            justifyContent={['space-between', 'initial']}
          >
            <Flex width={'45px'} justifyContent={['center']}>
              <Image
                src={`https://manezco.sfo3.digitaloceanspaces.com/icons/${icons[topic]}.svg`}
              />
            </Flex>
            <Heading
              m={['none', '0 30px']}
              fontWeight={'700'}
              fontSize={'1.1rem'}
            >
              {topic}
            </Heading>
          </Flex>
          <Text
            ml={'30px'}
            fontSize={'0.9rem'}
            textAlign={'left'}
            w={['95%', '65%']}
          >
            {topicDescriptions[topic]}
          </Text>
        </Flex>
      ))}
      <Flex w={'100%'} justifyContent={'space-evenly'} mb={'25px'}>
        <Box borderBottom={'1px white solid'} bottom={'20px'}>
          <Text
            fontWeight={'600'}
            letterSpacing={'0.06rem'}
            textDecoration={'none'}
            lineHeight={'0.3rem'}
            cursor={'pointer'}
            onClick={() => setSubject(null)}
          >
            Back To Overview
          </Text>
        </Box>
        <Box borderBottom={'1px white solid'} bottom={'20px'}>
          <Text
            fontWeight={'600'}
            letterSpacing={'0.06rem'}
            textDecoration={'none'}
            lineHeight={'0.3rem'}
            cursor={'pointer'}
            onClick={() => {
              setQuizInProgress(1);
              getLesson({ subject, studentId });
              navigate('/preview-lesson/' + subject);
            }}
          >
            Preview Lesson
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SubjectSummary;
