import { Flex } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';

interface Props {
  direction: 'left' | 'right';
  navToQuestion: number;
}

const Arrow: React.FC<Props> = ({ direction, navToQuestion }) => {
  const {
    nextQuestion,
    prevQuestion,
    setCurrentQuestionId,
    snapBallToPos,
    snapToNumber,
  } = useActions();
  const { questions } = useAppSelector((state) => state.questions);

  const snapToQuestion = (number: number) => {
    const numOfQuestions = questions.length;
    let newPos;
    if (number < 3) {
      newPos = 0;
    } else {
      newPos = (number - 2) * 70;
    }
    if (number - 1 !== numOfQuestions) {
      snapToNumber(newPos);
      snapBallToPos();
    }
  };

  const click_or_enter_handler = () => {
    if (direction === 'left') {
      prevQuestion();
    } else {
      nextQuestion();
    }
    setCurrentQuestionId();
    snapToQuestion(navToQuestion);
  };

  return (
    <Flex
      justify={direction === 'right' ? 'left' : 'right'}
      marginLeft={direction === 'right' ? '10px' : '0'}
      marginRight={direction === 'left' ? '10px' : '0'}
      align={'center'}
      w={'24px'}
      h={'24px'}
      cursor={'pointer'}
      onClick={() => click_or_enter_handler()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          click_or_enter_handler();
        }
      }}
      tabIndex={0}
    >
      {direction === 'left' ? (
        <FaChevronLeft size={'sm'} />
      ) : (
        <FaChevronRight size={'sm'} />
      )}
    </Flex>
  );
};

export default Arrow;
