// filepath: /home/goku/Desktop/Valearnis/mvp-front-end/src/hooks/useScreenSize.ts
import { useState, useEffect } from 'react';

const breakpoints = {
  xs: 480,
  sm: 600,
  md: 768,
  lg: 992,
  xl: 1200,
};

/**
 * Custom hook to check if the screen width is less than the specified size
 * @param size string the screen size ('xs', 'sm', 'md', 'lg', 'xl')
 * @returns boolean indicating if the screen width is less than the specified size
 */
export const useScreenSize = (size: keyof typeof breakpoints): boolean => {
  const [isLessThan, setIsLessThan] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLessThan(window.innerWidth < breakpoints[size]);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.documentElement);

    // Initial check
    handleResize();

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.unobserve(document.documentElement);
    };
  }, [size]);

  return isLessThan;
};
