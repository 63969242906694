import React, { useState } from 'react';
import { Button, Flex, Input, Text } from '@chakra-ui/react';
import { useMakePaymentsMutation } from 'app/services/api';
import { useAppSelector } from 'hooks/useAppSelector';
import PaddedContainer from 'components/containers/PaddedContainer';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import InstructionsTooltip from 'features/instructions/InstructionsTooltip';
import { useActions } from 'hooks/useActions';
import { createListCollection } from '@chakra-ui/react';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from 'components/ui/select';

const MAX_STUDENTS = 10;

const ChoosePlan: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const [numOfStudents, setnumOfStudents] = useState(1);
  const [referral_code, setReferralCode] = useState('');
  const [makePayments] = useMakePaymentsMutation();

  // TODO: Get prices from server
  const prices: { [key: number]: number } = {
    1: 15.99,
    2: 14.99,
    3: 13.99,
    4: 13.49,
    5: 12.99,
  };

  // TODO: Redirection for already set-up accounts

  const collection = createListCollection({
    items: Array.from({ length: MAX_STUDENTS }, (_, i) => i + 1).map((i) => ({
      label: i.toString(),
      value: i.toString(),
    })),
  });

  const renderOptions = (collection: {
    items: { label: string; value: string }[];
  }) => {
    return collection.items.map((item, index) => (
      <SelectItem key={index} item={item}>
        {item.label}
      </SelectItem>
    ));
  };
  const { addNotification } = useActions();
  const handlePaymentClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      const makePaymentsResult = await makePayments({
        number_of_subscriptions: numOfStudents,
        referral_code: referral_code,
      }).unwrap();
      if (!makePaymentsResult || !makePaymentsResult.payment_url) {
        console.log('makePaymentsResult error', makePaymentsResult);
        throw new Error('makePayments failed');
      } else {
        console.log('makePaymentsResult', makePaymentsResult);
        window.location.href = makePaymentsResult.payment_url;
      }
    } catch (err: any) {
      if (err.data.referral_code === 'Invalid referral code entered!') {
        addNotification(
          'The referral code you entered is invalid. This could be because the code has already been redeemed, or because it was entered incorrectly.',
        );
        console.log('Something went wrong', err);
      }
    }
  };

  return (
    <PaddedContainer
      display={'flex'}
      flexDir={'column'}
      padding={'28px'}
      text-align={'center'}
    >
      <Flex flexDir={'column'} justify={'center'} textAlign={'left'}>
        <Flex justify={'right'}>
          <InstructionsTooltip
            defaultIsOpen={true}
            content={
              "Choose how many students you'd like to sign up. Each student account is unique, with their own progression, report card, characters etc."
            }
            footer={
              'Signing up multiple students gives you a per-student discount.'
            }
            align={'bottom'}
          />
        </Flex>
        <Text fontSize={'1.125rem'}>
          Hello {user.name ? user.name : 'there'}, welcome to Valearnis!
        </Text>
        <Text>How many students would you like to sign up?</Text>
        <Text fontSize={'0.75rem'}>
          Please note, we currently only catering for year 7-9 secondary school
          students
        </Text>
        <FormFieldContainer mt={7}>
          <SelectRoot
            collection={collection}
            name='numOfStudents'
            color='brand.400'
            variant={'outline'}
            defaultValue={[`${numOfStudents}`]}
            bgColor={'#ffffffa6'}
            borderRadius='5px'
            onValueChange={(e: any) => {
              setnumOfStudents(Number(e.value));
            }}
            css={{
              '& button[data-state="open"]': {
                borderColor: '#63b3ed',
              },
              '& button[data-state="closed"]': {
                borderColor: 'transparent',
              },
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder={'Select no of students.'} />
            </SelectTrigger>
            <SelectContent backgroundColor={'#2d4dac'}>
              {renderOptions(collection)}
            </SelectContent>
          </SelectRoot>
        </FormFieldContainer>

        <FormFieldContainer>
          <Flex direction='column'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text fontSize={'0.9rem'} style={{ marginRight: '0.5rem' }}>
                Referral Code
              </Text>
              <Text fontSize={'0.75rem'}>(Optional)</Text>
            </div>
            <Input
              name='referral_code'
              id='referral_code'
              type='text'
              color={'button.200'}
              bgColor={'#ffffffa6'}
              borderColor={'transparent'}
              value={referral_code}
              outline={'none'}
              onChange={(e) => {
                setReferralCode(e.target.value);
              }}
              css={{
                '&:focus': {
                  border: '1px solid #63b3ed',
                  outlineStyle: 'none',
                },
              }}
              // onChange={(e) => setReferralCode(e.target.value)}
              mt={2}
            />
          </Flex>
        </FormFieldContainer>
        {/* TODO: Get and update cost formula */}
        <>
          <Text textAlign={'right'} fontWeight={600}>
            Total: $
            {numOfStudents < 5
              ? prices[numOfStudents] * numOfStudents
              : prices[5] * numOfStudents}{' '}
            AUD
          </Text>
          <Text my={0} textAlign={'right'} fontSize={'.825rem'}>
            ${numOfStudents < 5 ? prices[numOfStudents] : prices[5]} AUD /
            student
          </Text>
        </>
        <Text textAlign={'center'} fontSize={'0.75rem'}>
          Discount codes are applied at the next step
        </Text>
        <Button mt={20} bgColor={'button.100'} onClick={handlePaymentClick}>
          Next
        </Button>
      </Flex>
    </PaddedContainer>
  );
};

export default ChoosePlan;
