import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Flex } from '@chakra-ui/react';
import { KatexOptions, render as katexRender } from 'katex';

import { useAppSelector } from 'hooks/useAppSelector';

import PaddedContainer from 'components/containers/PaddedContainer';
import QuizTitle from 'features/lessons/QuizTitle';
import AnswerInfo from 'features/lessons/results/AnswerInfo';
import SummaryBottomSection from 'features/lessons/results/SummaryBottomSection';
import BackToSubJectSelectButton from 'features/lessons/results/BackToSubJectSelectButton';

import 'katex/dist/katex.min.css';
import styles from 'features/lessons/results/QuizSummary.module.scss';
import {
  TransformedOption,
  TransformedQuestion,
  TransformedTheory,
} from 'features/questions/questionsSlice';
import { DefaultAnswer, FibAnswer, Result } from './resultsSlice';
import { ISelectedAnswer } from 'app/services/api';

const summaryInside = true;

const QuizSummary: React.FC = () => {
  const results = useAppSelector((state) => state.results.answers);
  const { questions, answers } = useAppSelector((state) => state.questions);
  const { quizInProgress } = useAppSelector((state) => state.progress);
  const navigate = useNavigate();
  const justQuestions = questions.filter(
    (question: TransformedTheory | TransformedQuestion) => {
      return Object.keys(question).includes('content');
    },
  ) as TransformedQuestion[];
  useEffect(() => {
    if (quizInProgress === 0) {
      navigate('/curriculum');
    }
    const mathElements = document.getElementsByClassName('math');
    const macros: KatexOptions['macros'] = [];
    for (let i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;

      if (texText?.nodeValue && mathElements[i].tagName === 'SPAN') {
        katexRender(texText.nodeValue, mathElements[i] as HTMLElement, {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: false,
          macros: macros,
          fleqn: false,
        });
      }
    }
  });
  const userAnswers = answers.question;

  const getAnswerInfo = (
    answer: FibAnswer | DefaultAnswer,
    userAnswer: string[],
  ) => {
    if ('result' in answer) {
      return {
        isCorrect: !answer.result.some((res: Result) => !res.isCorrect),
        answers: answer.result.map((res: Result) => res.user_response),
      };
    } else {
      return { answers: userAnswer, isCorrect: answer.isCorrect };
    }
  };

  return (
    <PaddedContainer
      padding={'28px'}
      textAlign={'left'}
      height={'75vh'}
      display={'flex'}
      flexDir={'column'}
    >
      <QuizTitle />
      <Text fontSize={'14px'} letterSpacing={'1.12px'} m={'10px 0 28px'}>
        Answers Review
      </Text>
      <Box flexGrow={1} overflow={'auto'}>
        <Flex
          flexDir={'column'}
          // h={'350px'}
          pr={'30px'}
          className={styles.scroll}
        >
          {results.map((answer: DefaultAnswer | FibAnswer, index: number) => {
            const userAnswerIndexes = userAnswers.find(
              (ele: ISelectedAnswer) => ele.id === answer.question_id,
            );
            const question = justQuestions.find(
              (ele: TransformedQuestion) => ele.id === answer.question_id,
            ) as TransformedQuestion;
            const { content, options } = question as TransformedQuestion;
            const userAnswer: string[] = [];
            if (userAnswerIndexes !== undefined) {
              userAnswerIndexes.answered.forEach(
                (element: number | string | FibAnswer) => {
                  if (options !== undefined) {
                    options.forEach((option: TransformedOption) => {
                      if (option.id === element) {
                        userAnswer.push(option.content);
                      }
                    });
                  }
                },
              );
            }

            // const isCorrect = question.questionType === 'FIB' ? answer.result.isCorrect : answer.isCorrect;
            // const question = questions.find(
            //   (ele: any) =>
            //     ele.id === answer.id && ele.questionType !== 'theory',
            // );

            const { isCorrect, answers } = getAnswerInfo(answer, userAnswer);

            return (
              <AnswerInfo
                key={index}
                question={content}
                userAnswers={answers}
                isCorrect={isCorrect}
                index={String(index + 1)}
              />
            );
          })}
          {summaryInside && (
            <Box>
              <SummaryBottomSection />
            </Box>
          )}
        </Flex>
        {!summaryInside && (
          <Box>
            <SummaryBottomSection />
          </Box>
        )}
      </Box>
      <BackToSubJectSelectButton />
    </PaddedContainer>
  );
};

export default QuizSummary;
