import React from 'react';
// import { FormControlProps, FormControl } from '@chakra-ui/react';
import { Box, BoxProps } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
} & BoxProps;

const FormFieldContainer: React.FC<Props> = ({ children, ...rest }) => (
  <Box
    display={'flex'}
    flexDirection={'column'}
    alignItems={'flex-start'}
    flex={'1 1 100%'}
    mb={'1.125rem'}
    {...rest}
  >
    {children}
  </Box>
);

export default FormFieldContainer;
