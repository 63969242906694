import { Box } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';

import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import { motion } from 'framer-motion';
const MotionBox = motion.create(Box);

const SidebarHeader = () => {
  const { toggleSidebarState } = useActions();
  const { sideBarCollapsed } = useAppSelector((state) => state.ai);

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      px='14px'
      py={'8px'}
      transition='all 0.5s ease-in-out'
    >
      <p className='header-title'>Conversations</p>
      <MotionBox
        className='sidebar-hamburger'
        onClick={() => toggleSidebarState()}
        cursor='pointer'
        fontSize='2xl'
        color='white'
        initial={{ rotate: 0 }}
        animate={{ rotate: sideBarCollapsed ? 180 : 0 }}
        transition={{ type: 'spring' }}
        _hover={{
          transform: 'scale(1.1)', // Optional: Add a slight scale effect
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        <FaArrowLeft />
      </MotionBox>
    </Box>
  );
};

export default SidebarHeader;
