import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa6';
import { motion } from 'framer-motion';

const MotionFlex = motion.create(Flex);

interface Props {
  total_skins: number;
  children: React.ReactNode;
}

const ScrollBox: React.FC<Props> = ({ total_skins, children }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const maxIndex = total_skins - 2;

  const nextSlide = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  return (
    <>
      <Box
        cursor={'pointer'}
        width={'28px'}
        height={'28px'}
        borderRadius={'50%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        onClick={prevSlide}
        colorPalette={'brand'}
        transition='all 0.2s ease-in-out'
        userSelect={'none'}
        _hover={{
          background: currentIndex === 0 ? '' : 'brand.600',
          scale: 1.2,
          transition: 'all 0.2s ease-in-out',
        }}
        color={currentIndex === 0 ? 'gray.600' : ''}
      >
        <FaChevronLeft />
      </Box>

      <Box
        position='relative'
        overflow='hidden'
        width={['210px', '318px', '300px', '318px']}
      >
        <MotionFlex
          animate={{ x: `${-(currentIndex * 105)}px` }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          display='flex'
          gap={'8px'}
        >
          {children}
        </MotionFlex>
      </Box>

      <Box
        cursor={'pointer'}
        width={'28px'}
        height={'28px'}
        borderRadius={'50%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        onClick={nextSlide}
        colorPalette={'brand'}
        transition='all 0.2s ease-in-out'
        userSelect={'none'}
        _hover={{
          background: currentIndex === maxIndex ? '' : 'brand.600',
          scale: 1.2,
          transition: 'all 0.2s ease-in-out',
        }}
        color={currentIndex === maxIndex ? 'gray.600' : ''}
      >
        <FaChevronRight />
      </Box>
    </>
  );
};

export default ScrollBox;
