import { Box, Button } from '@chakra-ui/react';
import React from 'react';

type ProfileFormButtonsProps = {
  mode: string;
  isStudent: boolean;
  onSubmit: () => void;
  onDelete?: () => void;
};

const ProfileFormButtons: React.FC<ProfileFormButtonsProps> = ({
  mode,
  isStudent,
  onSubmit,
  onDelete,
}) => {
  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      style={{ gap: '5rem' }}
    >
      {mode === 'create' && (
        <Button m='16px' width='15rem' onClick={() => onSubmit()}>
          CREATE PROFILE
        </Button>
      )}

      {mode === 'edit' && (
        <>
          <Button m='16px' width='15rem' onClick={() => onSubmit()}>
            SUBMIT
          </Button>
          {isStudent && onDelete && (
            <Button
              width='10rem'
              m='16px'
              minWidth={'10rem'}
              height={'3rem'}
              borderRadius={'10px'}
              fontSize={'1rem'}
              fontWeight={700}
              color='white'
              background='red.600'
              colorPalette='white'
              onClick={() => onDelete()}
              _hover={{
                backgroundColor: 'red.700',
              }}
            >
              DELETE
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default ProfileFormButtons;
