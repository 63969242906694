import React, { useEffect } from 'react';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { useKatex } from 'hooks/useKatex';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from 'components/ui/select';
import { TransformedOption } from 'features/questions/questionsSlice';
import { createListCollection } from '@chakra-ui/react';

interface Props {
  options: TransformedOption[];
}

interface CollectionOption {
  label: string;
  value: string;
}

const DropDownQuestion: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer() as string[];
  const renderMaths = useKatex();

  useEffect(() => {
    renderMaths(document.getElementsByTagName('option'));
  }, []);
  const [value, setValue] = React.useState<string[]>([]);

  // Transform options to match the Select component collection prop
  const transformed_options = options.map((option) => {
    return {
      label: option.content.replace(/(<([^>]+)>)/gi, ''),
      value: option.content.replace(/(<([^>]+)>)/gi, ''),
    };
  });

  // Create a list collection
  const options_collection = createListCollection({
    items: [...transformed_options],
  });

  // Set the value of the dropdown to the user's answer
  useEffect(() => {
    if (userAnswer) {
      // Find the option that matches the user's answer id
      const userValue = options.find((option) => option.id === userAnswer[0]);
      if (userValue) {
        setValue([userValue.content.replace(/(<([^>]+)>)/gi, '')]);
      }
    }
  }, [userAnswer]);

  const handleSelection = (e: { value: string[] }) => {
    setValue(e.value);
    // Find the selected option and get its id
    const selected_id = options.find(
      (option) => option.content.replace(/(<([^>]+)>)/gi, '') === e.value[0],
    )?.id;
    selectAnswer([selected_id]);
  };

  return (
    <SelectRoot
      colorPalette='brand'
      collection={options_collection}
      backgroundColor='brand.400'
      borderRadius='5px'
      value={value}
      onValueChange={(e: { value: string[] }) => handleSelection(e)}
      variant={'outline'}
      css={{
        '& button[data-state="open"]': {
          borderColor: '#63b3ed',
        },
        '& button[data-state="closed"]': {
          borderColor: 'transparent',
        },
      }}
    >
      <SelectTrigger>
        <SelectValueText placeholder='Choose an Option' />
      </SelectTrigger>
      <SelectContent backgroundColor={'brand.500'}>
        {options_collection.items.map((option: CollectionOption) => (
          <SelectItem item={option} key={option.value}>
            {option.value}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};

export default DropDownQuestion;
