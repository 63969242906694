import { useState, useEffect } from 'react';
// import ResizeObserver from 'resize-observer-polyfill';

global.ResizeObserver = ResizeObserver;

/**
 *
 * @param id string the id of the div element
 * @returns the height of the div element(id) in pixels
 */
export const useDivHeight = (id: string): number => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.offsetHeight);
    });

    resizeObserver.observe(element);

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.unobserve(element);
    };
  }, [id]); // run effect when id changes

  return height;
};
