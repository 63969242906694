import React, { useEffect } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { renderMaths } from 'utils/katexUtils';
import { motion } from 'framer-motion';

const MotionBox = motion.create(Box);

interface Props {
  options: {
    id: number | string;
    content: string;
  }[];
}

const SingleChoiceImageOptions: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();
  useEffect(() => {
    renderMaths(document.getElementsByTagName('div'));
  }, [options]);

  const userSelection = userAnswer ? userAnswer[0] : undefined;

  const onSelection = (value: any) => {
    selectAnswer([value]);
  };

  return (
    <Grid
      templateColumns={[
        '1fr',
        '1fr',
        '1fr 1fr',
        '1fr 1fr',
        '1fr 1fr',
        '1fr 1fr 1fr',
      ]}
      gap='6'
    >
      {options.map((option) => {
        return (
          <CustomRadio
            key={option.id}
            optionId={option.id}
            content={option.content}
            handleSelection={onSelection}
            userSelection={userSelection}
          ></CustomRadio>
        );
      })}
    </Grid>
  );

  function CustomRadio(props: any) {
    const { content, handleSelection, optionId, userSelection } = props;
    return (
      <Box
        maxWidth={['70%', '50%', '70%', null, '70%', '100%']}
        margin={'0 auto'}
        cursor='pointer'
        onClick={() => handleSelection(optionId)}
      >
        <MotionBox
          borderWidth={'6px'}
          position={'relative'}
          zIndex={0}
          borderRadius='20px'
          borderColor={'#fff'}
          animate={{
            borderColor: userSelection === optionId ? '#1de877' : '#fff',
          }}
          transition={{ duration: 0.3, boxShadow: { type: 'spring' } }}
          _hover={{
            boxShadow: '0 0 10px 0 rgb(73, 227, 238)',
            transition: 'box-shadow 0.3s ease-in-out',
          }}
        >
          <Box
            css={{
              '& img': { borderRadius: '12px' },
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
          />
        </MotionBox>
      </Box>
    );
  }
};

export default SingleChoiceImageOptions;
