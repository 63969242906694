import { motion, AnimatePresence } from 'framer-motion';
import { Tooltip } from 'components/ui/tooltip';
import { Box } from '@chakra-ui/react';
const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.03, // Adjust the delay for each character as needed
    },
  },
};

const charVariants = {
  hidden: { opacity: 0, x: -5 },
  visible: { opacity: 1, x: 0 },
};

const AnimatedText = ({ session_name }: { session_name: string }) => {
  // Split the session_name into individual characters
  const characters = session_name.split('');

  return (
    <div
      style={{
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AnimatePresence mode='wait'>
        <motion.div
          key={session_name} // re-renders on session_name change to trigger animations
          variants={containerVariants}
          initial='hidden'
          animate='visible'
          exit='hidden'
          style={{
            color: 'gray.50',
            fontSize: '16px',
            letterSpacing: '0.8px',
            margin: '0px',
            width: '200px',
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre',
            textAlign: 'left',
          }} // preserve whitespace
        >
          <Tooltip content={session_name} openDelay={500} closeDelay={0}>
            <Box position={'absolute'} height={'24px'} width={'200px'} />
          </Tooltip>
          {characters.map((char, index) => (
            <motion.span
              key={index}
              variants={charVariants}
              style={{ display: 'inline-block' }}
            >
              {char}
            </motion.span>
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default AnimatedText;
