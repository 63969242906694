import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const PaddedContainer: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      id={'paddedContainer'}
      position={'relative'}
      maxW={['90%', null, '80%', '70%', '60%']}
      minH={'100%'}
      m={'0 auto'}
      bgColor={'brand.600'}
      bgGradient='to-b'
      gradientFrom={'#5A99F7'}
      gradientTo={'#243D9D'}
      borderRadius={'10px'}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default PaddedContainer;
