import { Box, Flex, Heading, GridItem } from '@chakra-ui/react';
// import { ArtAsset } from 'app/services/api';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import CharacterChoice from './CharacterChoice';
import ThemeChoices from './ThemeChoices';
// import { removeCharNumber } from './utils';

interface Props {
  isLoading: boolean;
}

const SelectionSide: React.FC<Props> = ({ isLoading }) => {
  const { characters, isSettingTheme } = useAppSelector((state) => state.user);

  return (
    <Flex
      flexDir={'column'}
      position={'relative'}
      mb={['75px', '0']}
      display={['none', 'initial']}
    >
      {!isSettingTheme ? (
        <GridItem colSpan={[0, 1]}>
          <Box w={'100%'} textAlign={'left'} transform={'translateX(5px)'}>
            <Heading
              as={'h4'}
              fontSize={['0.9rem', '1.1rem']}
              fontWeight={'bold'}
              m={['30px 0 10px', '20px 0']}
              letterSpacing={'0.1rem'}
            >
              Select your character
            </Heading>
          </Box>
          <Flex
            w={'100%'}
            flexDir={['row']}
            justifyContent={['center', 'center', 'center', 'left']}
            flexWrap={'wrap'}
            overflowY={'auto'}
            padding={'8px'}
          >
            {!isLoading &&
              characters &&
              characters.map((character: any) => {
                return (
                  <CharacterChoice key={character.id} character={character} />
                );
              })}
          </Flex>
        </GridItem>
      ) : (
        <>
          <Heading
            as={'h4'}
            fontSize={['1.1em', '1.6em']}
            fontWeight={'100'}
            m={'30px 0'}
          >
            Select your colour theme!
          </Heading>
          <ThemeChoices />
        </>
      )}
    </Flex>
  );
};

export default SelectionSide;
