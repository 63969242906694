import { Image, BoxProps } from '@chakra-ui/react';
import { ConvoType } from './types';

const aiTeacher = 'https://cdn.valearnis.com/backgrounds/ai/robot-ai.png';
const convoTypeIcons = {
  general: aiTeacher,
  math: '/assets/icons/Maths icon.svg',
  english: '/assets/icons/English icon.svg',
  physics: '/assets/icons/Physics icon.svg',
  humanities: '/assets/icons/HASS icon.svg',
};

interface ConversationLogoProps extends BoxProps {
  convo_type: ConvoType;
}

const ConversationLogo = ({ convo_type, ...props }: ConversationLogoProps) => {
  const src = convoTypeIcons[convo_type];
  return (
    <Image
      objectFit={'fill'}
      width={'22px'}
      height={'22px'}
      src={src}
      alt={`${convo_type}-icon`}
      {...props}
    />
  );
};

export default ConversationLogo;
