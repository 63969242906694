import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import ZoomButton from 'features/lessons/templates/zoomedImage/ZoomButton';

import React from 'react';
import { Styles } from 'utils/customTypes';

interface Props {
  option: string;
  imgPath: string;
  option_id: number | string;
  handleSelectAnswer: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option_id: string | number,
  ) => void;
}

const imgStyles: Styles = {
  width: '250px',
  height: '300px',
  borderRadius: '10px',
  zIndex: '0',
};

const innerBoxStyles: Styles = {
  borderRadius: '0 0 10px 10px',
  bgColor: 'brand.500',
  position: 'absolute',
  bottom: '0',
  zIndex: '1',
  w: '100%',
  h: '35px',
  alignItems: 'center',
  justify: 'center',
};

const PictureChoiceInner: React.FC<Props> = ({
  option,
  imgPath,
  option_id,
  handleSelectAnswer,
}) => {
  return (
    <Box cursor={'pointer'} onClick={(e) => handleSelectAnswer(e, option_id)}>
      <Image src={imgPath} {...imgStyles} />
      <ZoomButton imgPath={imgPath} />
      <Flex {...innerBoxStyles}>
        <Heading as={'h1'} fontSize={'1rem'}>
          {option}
        </Heading>
      </Flex>
    </Box>
  );
};

export default PictureChoiceInner;
