import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PinShowGroup from './PinShowGroup';
import ProfileForm from './ProfileForm';
import {
  IStudentProfile,
  ICreateStudentRequest,
  useCreateStudentMutation,
} from 'app/services/api';
import { toaster } from 'components/ui/toaster';
import ProfileFormButtons from './ProfileFormButtons';
import { resetStudentStates } from 'features/userProfile/userSlice';

const ProfileCreateForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pin, setPin] = useState(['', '', '', '']);
  const [profileName, setProfileName] = useState('');
  const [year, setYear] = useState(0);

  const [createStudent] = useCreateStudentMutation();

  const handleSubmit = async () => {
    // submit profile
    const student: IStudentProfile = {
      name: profileName,
      username: profileName,
      year: year,
      pin: pin.join(''),
    };

    const request: ICreateStudentRequest = {
      data: [student],
    };

    try {
      const createStudentResponse = await createStudent(request).unwrap();
      if (
        createStudentResponse.status &&
        createStudentResponse.status === 200
      ) {
        toaster.create({
          title: 'Create Profile',
          description: "We've created the profile for you.",
          type: 'success',
          duration: 5000,
          closable: true,
        });
        dispatch(resetStudentStates());
        navigate('/account/choose-user', { replace: true });
      } else {
        toaster.create({
          title: 'Create Profile Error',
          description: createStudentResponse.msg,
          type: 'error',
          duration: 5000,
          closable: true,
        });
      }
    } catch (error: any) {
      if (typeof error.data.msg === 'string') {
        toaster.create({
          title: 'Create Profile Error',
          description: error.data.msg,
          type: 'error',
          duration: 5000,
          closable: true,
        });
      } else {
        const err = error.data.msg[0].msg;
        const error_keys = Object.keys(err);
        const error_message =
          error_keys.length > 1 ? error_keys.join(' & ') : error_keys[0];
        toaster.create({
          title: 'Create Profile Error',
          description: `${error_message} cannot be empty`,
          type: 'error',
          duration: 5000,
          closable: true,
        });
      }
    }
  };

  return (
    <Box
      width='300px'
      margin='auto'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      style={{ gap: '2rem' }}
      marginTop='2rem'
    >
      <ProfileForm
        mode={'create'}
        name={profileName}
        setName={setProfileName}
        setYear={setYear}
      />
      <PinShowGroup pin={pin} setPin={setPin} />
      <ProfileFormButtons
        mode={'create'}
        isStudent={true}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default ProfileCreateForm;
