import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

/**
 * 404 view
 */
const NotFound: React.FC = () => {
  return (
    <Box m={'0 28px'} textAlign={'center'}>
      <Heading as={'h1'} fontSize={'4xl'}>
        404 | Not Found
      </Heading>
      <Text mt={2}>
        The page you were looking for doesn&apos;t seem to exist...
      </Text>
    </Box>
  );
};

export default NotFound;
