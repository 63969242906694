import React from 'react';
// import { useLocation } from 'react-router-dom';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import type { AppDispatch } from 'app/store';

import {
  api,
  AuthUserType,
  ILoginRequest,
  ILoginResponse,
  useLoginMutation,
} from 'app/services/api';

import FormInput from 'components/forms/FormInput';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import ButtonLink from 'components/buttons/ButtonLink';
import { useActions } from 'hooks/useActions';
import { useDispatch } from 'react-redux';
import useStudentId from 'hooks/useStudentId';

/** Interface for login form */
const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

const Studentlogin: React.FC = () => {
  // const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { loginRequest, addNotification } = useActions();
  const [login, { isLoading }] = useLoginMutation();
  const studentId = useStudentId();

  // const renderMessage = () => {
  //   if (location.state && location.state.message) {
  //     return <p>{location.state.message}</p>;
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>Login - Valearnis</title>
      </Helmet>
      <Formik
        initialValues={{
          username: '',
          password: '',
          recaptcha_token: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values: ILoginRequest, actions) => {
          try {
            loginRequest(values.username.toLowerCase());
            const result: ILoginResponse = await login(values).unwrap();
            if (!result || result.status !== 200) {
              throw new Error(result.message);
            } else if (
              result.user_type === AuthUserType.Student &&
              result.subscription_active === false
            ) {
              addNotification(
                'Account inactive, please ask your parent/guardian to resubscribe',
              );
            } else if (result.user_type !== AuthUserType.Guardian) {
              dispatch(api.util.prefetch('getMenuItems', { studentId }, {}));
              dispatch(api.util.prefetch('getUserProfile', { studentId }, {}));
            }
          } catch (err: any) {
            if (err.data.message === 'User Login Failed') {
              addNotification('Username or password is incorrect');
            } else {
              addNotification(err.data.message);
            }
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => (
          <Flex
            as={Form}
            alignItems={'flex-start'}
            justifyContent={'center'}
            flexFlow={'row wrap'}
            w={'100%'}
            maxW={'20.25rem'}
            m={'0 auto'}
            p={'0 2rem'}
            textAlign={'center'}
          >
            {/* {renderMessage()} */}
            <FormFieldContainer gap={7}>
              <FormInput
                label='Username / Email'
                id='username'
                name='username'
                value={props.values.username.toLowerCase()}
              />
              <FormInput
                label='Password'
                id='password'
                type='password'
                name='password'
                value={props.values.password}
                data-private
                password_visibility={true}
              />
            </FormFieldContainer>
            <Box>
              <Text
                m={'5px auto 5px'}
                fontSize={'1rem'}
                fontWeight={'600'}
                letterSpacing={'.5px'}
                justifyContent={'left'}
              ></Text>
            </Box>
            <Box>
              <Button
                id='login-button'
                type='submit'
                disabled={props.isSubmitting}
              >
                Login
              </Button>
              <Box>{props.status && props.status}</Box>
              <ButtonLink to='/preview' link_variant='button'>
                {isLoading ? 'Loading' : 'View Curriculum'}
              </ButtonLink>
            </Box>
          </Flex>
        )}
      </Formik>
    </>
  );
};

export default Studentlogin;
