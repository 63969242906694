import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, ai_api } from 'app/services/api';
import { setStudentName } from 'features/userProfile/userSlice';
import { v4 as uuidv4 } from 'uuid';
import { ChatSession, ChatMessage, ConvoType } from './components/types';

export const TYPE_AI_MESSAGE = 'tutor';
export const TYPE_USER_MESSAGE = 'student';
export const TYPE_GUARDIAN_MESSAGE = 'guardian';

interface AIState {
  user: string;
  maxChatCredits: number;
  chatCreditsUsed: number;
  sideBarCollapsed: boolean;
  isNewSession: boolean;
  newSessionName: string | undefined;
  sessions: ChatSession[];
  currentSessionId: string | null;
  currentSessionMessages: ChatMessage[];
  currentSessionNewMessages: ChatMessage[];
  conversation_type: ConvoType;
  latestTutorMessage: ChatMessage | null;
}

const initialState: AIState = {
  user: '',
  maxChatCredits: 10,
  chatCreditsUsed: 0,
  sideBarCollapsed: false,
  isNewSession: true,
  newSessionName: '',
  sessions: [],
  currentSessionId: null,
  currentSessionMessages: [],
  currentSessionNewMessages: [],
  conversation_type: 'general',
  latestTutorMessage: null,
};

// Helper function to update sessions with new messages
const updateSessionsWithNewMessages = (state: AIState) => {
  const updatedSessions = state.sessions.map((session) => {
    if (session.session_id === state.currentSessionId) {
      return {
        ...session,
        conversation: [
          ...session.conversation,
          ...state.currentSessionNewMessages,
        ],
      };
    }
    return session;
  });
  state.sessions = updatedSessions;
  state.currentSessionNewMessages = [];
};

// Helper function to create a new session
const createNewSession = (state: AIState) => {
  const newSession = {
    date: state.currentSessionNewMessages[0]?.date || '',
    session_id: state.currentSessionId || '',
    session_name: 'New Session',
    convo_type: state.conversation_type,
    conversation: state.currentSessionNewMessages,
  };
  state.sessions = [newSession, ...state.sessions];
  state.currentSessionNewMessages = [];
  state.newSessionName = '';
};

//Check for any new messages in the current session and update the sessions
const updateSessionState = (state: AIState) => {
  if (state.currentSessionNewMessages.length > 0) {
    if (!state.isNewSession) {
      updateSessionsWithNewMessages(state);
    } else {
      createNewSession(state);
    }
  }
  state.latestTutorMessage = null;
};

const aiSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    toggleSidebarState(state) {
      state.sideBarCollapsed = !state.sideBarCollapsed;
    },
    setSelectedSessionId(state, { payload }: PayloadAction<string>) {
      updateSessionState(state);
      state.currentSessionId = payload;
      const selected_session = state.sessions.find(
        (session) => session.session_id === payload,
      );

      state.currentSessionMessages = selected_session?.conversation || [];
      state.conversation_type = selected_session?.convo_type || 'general';
      state.newSessionName = selected_session?.session_name;
      state.isNewSession = false;
    },
    setNewChatSession(state) {
      updateSessionState(state);
      state.isNewSession = true;
      state.currentSessionId = uuidv4();
      state.newSessionName = '';
      state.currentSessionMessages = [];
      state.conversation_type = 'general';
    },
    setSelectedConvoType(state, { payload }: PayloadAction<ConvoType>) {
      if (payload === state.conversation_type) {
        state.conversation_type = 'general';
      } else {
        state.conversation_type = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setStudentName, (state, { payload }) => {
      if (payload !== '') {
        if (state.user !== payload) {
          state.user = payload;
          state.chatCreditsUsed = 0;
          state.sideBarCollapsed = false;
          state.isNewSession = true;
          state.newSessionName = '';
          state.sessions = [];
          state.currentSessionId = uuidv4();
          state.currentSessionMessages = [];
          state.currentSessionNewMessages = [];
          state.conversation_type = 'general';
          state.latestTutorMessage = null;
        }
      }
    });
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.subscription_active) {
          state.maxChatCredits = 100;
        } else {
          state.maxChatCredits = 10;
        }
      },
    );
    builder.addMatcher(
      ai_api.endpoints.getHistory.matchFulfilled,
      (state, { payload }) => {
        state.sessions = payload.sessions;
      },
    );
    builder.addMatcher(
      ai_api.endpoints.askAiTeacher.matchPending,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { student_message },
            },
          },
        },
      ) => {
        if (state.latestTutorMessage !== null) {
          state.currentSessionMessages.push(state.latestTutorMessage);
          state.latestTutorMessage = null;
        }
        state.currentSessionMessages.push(student_message);
        state.currentSessionNewMessages.push(student_message);
        if (state.isNewSession) {
          const newSession = {
            date: student_message.date || '',
            session_id: state.currentSessionId || '',
            session_name: 'New Session',
            convo_type: state.conversation_type,
            conversation: [],
          };
          state.sessions = [newSession, ...state.sessions];
          state.isNewSession = false;
        }
      },
    );
    builder.addMatcher(
      ai_api.endpoints.askAiTeacher.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { tutor_message },
            },
          },
          payload,
        },
      ) => {
        if (
          payload.session_name !== null ||
          state.newSessionName === 'New Session'
        ) {
          state.newSessionName = payload.session_name;
          const updatedSessions = state.sessions.map((session) => {
            if (session.session_id === state.currentSessionId) {
              return {
                ...session,
                session_name: payload.session_name || 'New Session',
              };
            }
            return session;
          });
          state.sessions = updatedSessions;
        }
        const updated_tutor_message = {
          ...tutor_message,
          message: payload.response,
        };
        state.latestTutorMessage = updated_tutor_message;
        state.currentSessionNewMessages.push(updated_tutor_message);
        state.chatCreditsUsed += 1;
      },
    );
  },
});

export const aiActions = aiSlice.actions;

export default aiSlice.reducer;
