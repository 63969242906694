import { Box, VStack } from '@chakra-ui/react';
import {
  ProgressCircleRing,
  ProgressCircleRoot,
  ProgressCircleValueText,
} from 'components/ui/progress-circle';
import React from 'react';

interface Props {
  text?: string;
}

const LoadingCircle: React.FC<Props> = ({ text = 'Loading...' }) => {
  return (
    <VStack gap={4}>
      <Box fontSize={'sm'}>{text}</Box>
      <ProgressCircleRoot value={null} size={'sm'} colorPalette={'brand'}>
        <ProgressCircleValueText />
        <ProgressCircleRing />
      </ProgressCircleRoot>
    </VStack>
  );
};

export default LoadingCircle;
