import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Box, Button, ButtonProps } from '@chakra-ui/react';

type Props = ButtonProps &
  LinkProps & {
    link_variant: 'button' | 'tab';
  };

const ButtonLink: React.FC<Props> = ({
  children,
  to,
  link_variant,
  minW,
  minH,
  ...rest
}) => {
  return (
    <>
      {link_variant === 'button' ? (
        <Button asChild {...rest}>
          <Link to={to}> {children} </Link>
        </Button>
      ) : (
        <Link to={to}>
          <Box
            className='button-link'
            minW={minW ? minW : ['100%', '16rem', '20rem']}
            minH={minH ? minH : '9rem'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            m={2}
            fontSize={'1rem'}
            fontWeight={700}
            color={'#FFF'}
            borderRadius={'10px'}
            letterSpacing={'0.0625rem'}
            flexFlow={[null, null, 'row wrap']}
            transition='all 0.4s'
            css={{
              position: 'relative',
              _before: {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: 'inherit',
                backgroundColor: 'brand.500',
                zIndex: -1,
                transition:
                  'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
              },
              _hover: {
                _before: {
                  backgroundColor: 'button.300',
                  transform: 'scale(1.01)',
                  boxShadow:
                    '5px 5px 8px color-mix(in srgb, var(--chakra-colors-brand-400) 64%, transparent)',
                },
              },
            }}
          >
            {children}
          </Box>
        </Link>
      )}
    </>
  );
};

export default ButtonLink;
