import { bindActionCreators } from '@reduxjs/toolkit';
import { notificationActions } from 'app/notificationSlice';
import { navActions } from 'components/pagination/navSlice';
import { authActions } from 'features/auth/authSlice';
import { menuActions } from 'features/curriculum/menuSlice';
import { progressActions } from 'features/lessons/progressSlice';
import { largeImageActions } from 'features/lessons/templates/zoomedImage/largeImageSlice';
import { questionActions } from 'features/questions/questionsSlice';
import { userActions } from 'features/userProfile/userSlice';
import { factionQuizActions } from 'features/houseFaction/FactionQuizSlice';
import { aiActions } from 'features/AiTeacher/aiSlice';
import { useAppDispatch } from './useAppDispatch';

export const useActions = () => {
  const actions = {
    ...questionActions,
    ...userActions,
    ...progressActions,
    ...largeImageActions,
    ...menuActions,
    ...authActions,
    ...navActions,
    ...notificationActions,
    ...factionQuizActions,
    ...aiActions,
  };

  const dispatch = useAppDispatch();
  return bindActionCreators(actions, dispatch);
};
