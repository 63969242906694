import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  IUpdateStudentsRequest,
  useUpdateStudentsMutation,
  useUpdateSubscriptionMutation,
} from 'app/services/api';
import { flattenObject } from 'utils/objectUtils';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import FormInput from 'components/forms/FormInput';
import FormSelect from 'components/forms/FormSelect';
import { IAccountDetails } from 'features/account/accountSlice';
import { Flex, Button } from '@chakra-ui/react';

const ManageSingleStudent: React.FC = () => {
  const location = useLocation();
  const { id, is_active, name, username, year }: IAccountDetails = Object(
    location.state,
  );
  const schoolYears = [7, 8, 9];
  const navigate = useNavigate();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(is_active);
  const [updateStudents] = useUpdateStudentsMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const toPasswordReset = () => {
    navigate('reset-password', { state: { username } });
  };
  const toggleSubscription = async () => {
    try {
      const request = {
        student_id: String(id),
        is_active: !isSubscriptionActive,
      };
      setIsSubscriptionActive(!isSubscriptionActive);
      const result = await updateSubscription(request).unwrap();
      // TODO: Check username is available first
      if (result.status !== 200) {
        throw new Error('Something went wrong');
      }
    } catch (err: any) {
      const errors = flattenObject(err.data);

      console.log('Something went wrong', errors);
    }
  };
  const initialFormValues = {
    name: name,
    username: username,
    year: year,
  };
  const UpdateStudentSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Must be at least 3 characters')
      .max(255, 'Must be at most 255 characters'),
    username: Yup.string()
      .min(2, 'Must be at least 2 characters')
      .max(150, 'Must be at most 150 characters')
      .required('Username is required'),
  });

  return (
    <>
      <Flex flexDir={'column'} textAlign={['left', null, null, 'center']}>
        <Flex
          flexDir={['column', null, null, 'row']}
          justifyContent={'center'}
          m={'auto'}
        >
          <Formik
            initialValues={initialFormValues}
            validationSchema={UpdateStudentSchema}
            onSubmit={async (values, actions) => {
              try {
                const updatedStudent: IUpdateStudentsRequest = {
                  app_user: {
                    username: values.username,
                    name: values.name,
                  },
                  year: Number(values.year),
                  student_id: String(id),
                };
                const result = await updateStudents([updatedStudent]).unwrap();
                // TODO: Check username is available first
                if (result.status !== 200) {
                  throw new Error('Something went wrong');
                }

                navigate('/account/manage-students', {
                  replace: true,
                  state: {
                    message: 'Student updated',
                  },
                });
                // TODO: Handle errors properly
                // TODO: Improve type checking
              } catch (err: any) {
                const errors = flattenObject(err.data);

                console.log('Something went wrong', errors);
              }

              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Flex as={Form} flexDir={'column'} justify={'center'}>
                <FormFieldContainer gap={8}>
                  <FormInput
                    name={'name'}
                    label={'Name'}
                    value={props.values.name}
                    helperText={props.errors.name}
                  />
                  <FormInput
                    name={'username'}
                    label={'Username'}
                    value={props.values.username}
                    helperText={props.errors.username}
                  />
                  <FormSelect
                    name={'year'}
                    label={'Year Level'}
                    value={props.values.year}
                    helperText={props.errors.year}
                    options={schoolYears}
                  />
                </FormFieldContainer>
                <Button type='submit' minW={'11rem'}>
                  Save Changes
                </Button>
              </Flex>
            )}
          </Formik>
        </Flex>
        <Flex flexDir={'column'}>
          <Button mb={'0'} onClick={toPasswordReset}>
            Reset Password
          </Button>
          {isSubscriptionActive !== undefined && (
            <Button
              onClick={toggleSubscription}
              // h={'40px'}
              // w={'190px'}
              bg={isSubscriptionActive ? '#D64815' : 'button.100'}
              _hover={{ bg: isSubscriptionActive ? '#db5424' : 'button.200' }}
              fontSize={'15px'}
            >
              {isSubscriptionActive ? 'Deactivate Student' : 'Activate Student'}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ManageSingleStudent;
