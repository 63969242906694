import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import ProfileSelect from '../../components/auth/ProfileSelect';
import { useDispatch } from 'react-redux';
import { resetStudentStates } from 'features/userProfile/userSlice';
import { useNavigate } from 'react-router-dom';
import { useActions } from 'hooks/useActions';
import { api } from 'app/services/api';
import useStudentId from 'hooks/useStudentId';
import { resetHouseState } from 'features/houseFaction/FactionQuizSlice';
import type { AppDispatch } from 'app/store';

export default function ChooseUser(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const studentId = useStudentId();
  const { setSkinChoice } = useActions();
  const navigate = useNavigate();
  const redirectToEditProfile = () => {
    navigate('/account/edit-profiles');
  };

  const handleLogout = () => {
    setSkinChoice(null);
    navigate('/logout');
  };

  useEffect(() => {
    dispatch(resetStudentStates());
    dispatch(resetHouseState());
    dispatch(api.util.prefetch('getUserProfile', { studentId }, {}));
  }, [dispatch]);

  return (
    <>
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'background.800'}
        bgGradient='to-b'
        gradientFrom={'background.100'}
        gradientTo={'background.800'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
      ></Box>
      <Button
        position='fixed'
        top='0'
        right='0'
        m='16px'
        onClick={() => redirectToEditProfile()}
      >
        EDIT PROFILE
      </Button>
      <Button
        position='fixed'
        top='60px'
        right='0'
        m='16px'
        onClick={() => handleLogout()}
      >
        LOG OUT
      </Button>
      <ProfileSelect mode={'select'} />
    </>
  );
}
