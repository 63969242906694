import { Flex, Button } from '@chakra-ui/react';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
// import { useAppSelector } from 'hooks/useAppSelector';
// import { useUpdateStudentSettingsMutation } from 'app/services/api';
import React from 'react';

const SelectionButton: React.FC = () => {
  // const [updateStudentSettings] = useUpdateStudentSettingsMutation();
  const { tempCharacterChoice } = useAppSelector((state) => state.user);
  const { toggleSettingTheme, toggleSelectingAvatar } = useActions();

  return (
    <Flex
      width={['100%', 'auto']}
      direction={['column', 'row']}
      justifyContent={{ base: 'center', md: 'space-between' }}
      pt={'2rem'}
      gap={4}
    >
      <Button
        margin={'0 auto'}
        disabled={!tempCharacterChoice.is_unlocked}
        onClick={() => toggleSettingTheme()}
        bgColor='button.300'
        _hover={{
          backgroundColor: 'brand.400',
        }}
      >
        Customise
      </Button>
      <Button
        margin={'0 auto'}
        bgColor='button.300'
        onClick={() => toggleSelectingAvatar()}
        ml={{ md: 'auto', lg: 4 }} // Margin left on md screens, none on lg screens
        _hover={{
          backgroundColor: 'brand.400',
        }}
      >
        Summary
      </Button>
    </Flex>
  );
};

export default SelectionButton;
