import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { add } from 'date-fns';
import { useAppSelector } from 'hooks/useAppSelector';
import useStudentId from 'hooks/useStudentId';
import {
  useLazyGetMenuItemsQuery,
  useRefreshTokenMutation,
} from 'app/services/api';

type PrivateRouteProps = {
  children: React.ReactElement;
  redirectPath?: string;
  verificationRedirectPath?: string;
};

const RequireAuth = ({
  children,
  redirectPath = '/login',
  verificationRedirectPath = '/account/verification',
}: PrivateRouteProps): React.ReactElement => {
  const rehydrated = useAppSelector((state) => state._persist.rehydrated);
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.auth.user);
  const studentId = useStudentId();
  const [refreshToken, { isLoading }] = useRefreshTokenMutation();
  const [getMenuItems] = useLazyGetMenuItemsQuery();

  /**
   * Check if token exists and is due to expire soon
   *
   * @returns boolean
   */
  const tokenWillExpire = () => {
    const currDate = new Date();

    return (
      rehydrated &&
      user.token &&
      user.tokenExpiry &&
      // token due to expire within 5s
      add(currDate, { seconds: 5 }) > new Date(user.tokenExpiry) &&
      !isLoading
    );
  };

  const attemptTokenRefresh = async () => {
    await refreshToken();
  };

  useEffect(() => {
    if (tokenWillExpire()) {
      attemptTokenRefresh();
    }
  }, []);

  useEffect(() => {
    const tokenRefreshInterval = setTimeout(() => {
      if (tokenWillExpire()) {
        attemptTokenRefresh();
      }
    }, 4500);

    return () => clearTimeout(tokenRefreshInterval);
  }, []);

  useEffect(() => {
    const menuItemsInterval = setTimeout(() => {
      getMenuItems({ studentId });
    }, 60000);

    return () => clearTimeout(menuItemsInterval);
  }, []);

  // Prevent navigation loops with stable verification checks
  if (!auth.isAuth && location.pathname !== redirectPath) {
    return <Navigate to={redirectPath} />;
  }
  if (
    auth.isAuth &&
    !user.isVerified &&
    location.pathname !== verificationRedirectPath
  ) {
    return <Navigate to={verificationRedirectPath} />;
  }

  return children;
};

export default RequireAuth;
