import React from 'react';
import { Link, Text } from '@chakra-ui/react';

const RegisterConsentMessage: React.FC = () => {
  return (
    <Text>
      By registering, I agree to the{' '}
      <Link
        fontWeight={'bold'}
        color={'#FFF'}
        href='https://cdn.valearnis.com/docs/terms-of-service.pdf'
      >
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link
        fontWeight={'bold'}
        color={'#FFF'}
        href='https://cdn.valearnis.com/docs/privacypolicy.pdf'
      >
        Privacy Agreement
      </Link>
      .
    </Text>
  );
};

export default RegisterConsentMessage;
