import { Flex, Heading, Text, Image, Box } from '@chakra-ui/react';
import { useLazyGetPreviewLessonsQuery } from 'app/services/api';
import { useActions } from 'hooks/useActions';
import useStudentId from 'hooks/useStudentId';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  subject: string;
  description: string;
  topics: string[];
  topicDescriptions: { [key: string]: string };
  setSubject: React.Dispatch<React.SetStateAction<string | null>>;
}

const CurriculumBox: React.FC<Props> = ({ subject, setSubject }) => {
  const navigate = useNavigate();
  const { setQuizInProgress } = useActions();
  const [getLesson] = useLazyGetPreviewLessonsQuery();
  const studentId = useStudentId();
  return (
    <>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        flexDir={'column'}
        position={'relative'}
        height={['300px', '280px', '280px', '280px']}
        _hover={{
          transform: 'scale(1.05)',
          transition: 'transform 0.3s ease-in-out',
        }}
        transition='transform 0.3s ease-in-out, height 0.3s ease-in-out'
      >
        <Image
          h={'100%'}
          src={`https://manezco.sfo3.digitaloceanspaces.com/preview/${subject.toLowerCase()}.png`}
          onClick={() => {
            setSubject(subject);
          }}
        />
        <Heading
          position={'absolute'}
          letterSpacing='0.1rem'
          fontSize={'1.2rem'}
          top={'20px'}
        >
          {subject}
        </Heading>
        <Box
          borderBottom={'1px white solid'}
          position={'absolute'}
          bottom={'20px'}
        >
          <Text
            fontWeight={'600'}
            letterSpacing={'0.06rem'}
            textDecoration={'none'}
            lineHeight={'0.3rem'}
            cursor={'pointer'}
            onClick={() => {
              setQuizInProgress(1);
              getLesson({ subject, studentId });
              navigate('/preview-lesson/' + subject);
            }}
          >
            Preview Lesson
          </Text>
        </Box>
      </Flex>
    </>
  );
};
export default CurriculumBox;
