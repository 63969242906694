import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import LessonListItem from 'features/lessons/LessonListItem';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLazyGetLessonsForTopicQuery } from 'app/services/api';
import { findTopicId } from 'utils/arrayUtils';
import { useUrlParts } from 'hooks/useUrlParts';
import ImageWithLoader from 'components/ImagewithLoader';
import { AnimatePresence, motion } from 'framer-motion';

const MotionBox = motion.create(Box);

const LessonsList: React.FC = () => {
  /* TODO: dynamically handle background images */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const { quizList } = useAppSelector((state) => state.progress);
  const { user } = useAppSelector((state) => state.auth);
  const { schoolYear } = useAppSelector((state) => state.user);
  const { menuItems } = useAppSelector((state) => state.menu);

  const urlParts = useUrlParts();
  const subject = urlParts[1];
  const module = urlParts[2];

  const [getLessons, { isLoading }] = useLazyGetLessonsForTopicQuery();

  useEffect(() => {
    const topicId = findTopicId(menuItems, subject, module);
    getLessons({ code: topicId });
  }, []);

  const checkWindowSize = () => {
    setIsMobile(window.innerWidth <= 991);
  };

  useEffect(() => {
    window.addEventListener('resize', checkWindowSize);
  });

  return (
    <Flex
      m={'auto'}
      maxW={'1000px'}
      flexDirection={['column', null, null, 'row']}
      alignItems={['center', null, null, 'flex-start']}
      justify={'center'}
      wrap={'wrap'}
    >
      <AnimatePresence>
        {isLoading ? (
          <ImageWithLoader module={module} />
        ) : Object.keys(quizList).length === 0 ? (
          <Text>No quizzes available for this topic yet!</Text>
        ) : (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {Object.entries(quizList).map((item: any, index) => {
              const [quiz, content] = item;

              if (
                content.years.includes(Number(schoolYear)) ||
                user.type === 'EMPLOYEE'
              ) {
                return (
                  <LessonListItem
                    key={index}
                    lesson={quizList[quiz]}
                    mobile={isMobile}
                  />
                );
              }
            })}
          </MotionBox>
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default LessonsList;
