import {
  createMigrate,
  persistReducer,
  persistCombineReducers,
} from 'reduxjs-toolkit-persist';
import factionQuizReducer from 'features/houseFaction/FactionQuizSlice';
import authReducer from 'features/auth/authSlice';
import questionsReducer from 'features/questions/questionsSlice';
import progressReducer from 'features/lessons/progressSlice';
import largeImageReducer from 'features/lessons/templates/zoomedImage/largeImageSlice';
import userReducer from 'features/userProfile/userSlice';
import resultsReducer from 'features/lessons/results/resultsSlice';
import menuReducer from 'features/curriculum/menuSlice';
import accountReducer from 'features/account/accountSlice';
import notificationReducer from './notificationSlice';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import { api, ai_api } from 'app/services/api';
import navReducer from 'components/pagination/navSlice';
import aiSlice from 'features/AiTeacher/aiSlice';

type MigrationManifest = {
  [key: number]: (state: any) => any;
};

const migrations: MigrationManifest = {
  0: (state: any) => {
    return {
      ...state,
    };
  },
  // 1: (state: any) => {
  //   return {
  //     ...state,
  //     auth: {
  //       ...state.auth,
  //       hasFreeSubscription: true,
  //     },
  //     user: {
  //       ...state.user,
  //       userFaction: 'Slytherin',
  //     },
  //   };
  // },
  // 2: (state: any) => {
  //   return {
  //     ...state,
  //     auth: {
  //       ...state.user,
  //       hasFreeSubscription: false,
  //     },
  //   };
  // },
};
const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['progress', api.reducerPath],
  migrate: createMigrate(migrations, {
    debug: false,
  }),
};

const progressPersistConfig = {
  key: 'progress',
  storage,
  blacklist: ['quizInProgress'],
};

// Combine all the app's reducers to easily pass to store
const reducer = persistCombineReducers(rootPersistConfig, {
  [api.reducerPath]: api.reducer,
  [ai_api.reducerPath]: ai_api.reducer,
  auth: authReducer,
  questions: questionsReducer,
  progress: persistReducer(progressPersistConfig, progressReducer),
  largeImage: largeImageReducer,
  user: userReducer,
  results: resultsReducer,
  menu: menuReducer,
  account: accountReducer,
  notification: notificationReducer,
  nav: navReducer,
  faction: factionQuizReducer,
  ai: aiSlice,
});

export const rootReducer = persistReducer(rootPersistConfig, reducer);
